import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class GeneralComponent implements OnInit, OnChanges {

  @Input() masterDropDownDetails: any
  @Input() editFormId: number = 0
  @Input() yearValueChanged: any
  @Input() defaultValue: any
  @Input() subJobberOptions: any

  @Input() contractValueChanged: any
  parentIntakeForm!: UntypedFormGroup;
  constructor(private parent: FormGroupDirective, private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private service: DataService) { }


  ngOnChanges() {
    this.addControls(this.convertToNumber(this.yearValueChanged), 'general', 'historical')

    this.addControls(this.convertToNumber(this.contractValueChanged), 'general', 'contract')
    console.log(this.contractValueChanged, this.yearValueChanged)
  }
  
 

  nonStandardGrowthValueChange() {
    this.parentIntakeForm.get('general.nonStandardGrowth')?.valueChanges.subscribe(response => {
      console.log(response, "parentIntakeForm ")
      if(this.editFormId<=0){
      if (response?.id == 1 ) {
        this.parentIntakeForm.get('general.contract')?.setValue( this.masterDropDownDetails?.contract_years_collection.filter((x: any) => x.name == '1')[0])
        this.addControls(this.convertToNumber(1), 'general', 'contract')
      }
      else{
        console.log(this.defaultValue)
        let noContractYears: any = this.defaultValue?.filter((x: any) => x.fieldName == "Number of Contract Years")[0]?.value
        this.parentIntakeForm.get('general.contract')?.setValue( this.masterDropDownDetails?.contract_years_collection.filter((x: any) => x.name == noContractYears)[0])
        this.addControls(this.convertToNumber(noContractYears), 'general', 'contract')
      }
    }    
    })
  }
 
  ngOnInit(): void {
    this.parentIntakeForm = this.parent.form;
    console.log(this.parentIntakeForm.value)
    this.nonStandardGrowthValueChange()
    console.log(this.parentIntakeForm.value)
    if (this.editFormId) {
      this.service.getDealFormData().subscribe((res: any) => {
        console.log("Edit data", res)
        this.contractValue?.clear()
        res.contract_year_list?.forEach((x: any) => {
          this.contractValue.push(this.createValueContract(x.value, x.id))
        })
        this.yearValue?.clear()
        res.mpr_year_purchase_finish?.forEach((x: any) => {
          this.yearValue.push(this.createValueHistorical(x.value, x.id))
        })
      })
    }
  }



  addControls(range: number, control: string, value: any) {
    console.log(control)
    if (value == 'contract') {
      this.removeAddDataContract(this.contractValue, range)
    }
    else if (value == 'historical') {
      this.removeAddDataHistorical(this.yearValue, range)
    }
  }

  jobberOptions() {
    if (this.parentIntakeForm.value.custInformation.country.id == undefined) {
      return []
    }
    else {
      return this.masterDropDownDetails?.jobber_name_collection.filter((x: any) => x.country_id == this.parentIntakeForm.value.custInformation.country.id)
    }
  }

  createValueContract(value?: any, id = 0): UntypedFormGroup {
    return this.fb.group({
      value: [value == '' ? 0 : value, [Validators.pattern(/^-?\d+\.?\d{0,2}$/)]],
      id: [id]
    })
  }

  createValueHistorical(value?: any, id = 0): UntypedFormGroup {
    return this.fb.group({
      value: [value, [Validators.pattern(/^-?\d+\.?\d{0,2}$/)]],
      id: [id]
    })
  }

  warningMessage(message: string) {
    if (message == 'jobber') {
      if (this.parentIntakeForm.value.custInformation.country.id == undefined) {
        this.translate.get('CHOOSE_COUNTRY').subscribe((res: any) => {
          this.toastr.warning(res)
        })
      }

    }
  }

  removeAddDataContract(data: any = [], range = 0) {
    console.log(data, range)
    let loop = range - data.length
    let increment = data.length
    if(this.parentIntakeForm?.value.general.nonStandardGrowth.id==1){
      this.contractValue?.clear();
    }
    if (data.length < range)
      for (let i = data.length; i < increment + loop; i++) {
        data.push(this.createValueContract(''));
      }
    else if (data.length > range) {
      for (let i = data.length; i >= range; i--) {
        data.removeAt(i)
      }
    }
  }

  removeAddDataHistorical(data: any = [], range = 0) {
    console.log(data, range)
    let loop = range - data.length
    let increment = data.length
    if (data.length < range)
      for (let i = data.length; i < increment + loop; i++) {
        data.push(this.createValueHistorical(''));
      }
    else if (data.length > range) {
      for (let i = data.length; i >= range; i--) {
        data.removeAt(i)
      }
    }
  }

  valueChanged(value: any, i = 0, formName = '') {
    console.log(value, i, formName)
    console.log(this.parentIntakeForm.value)

    if (value?.name == 'year') {
      this.addControls(this.convertToNumber(value.value.name), 'general', 'historical')
    }
    if (value?.name == 'contract') {
      this.addControls(this.convertToNumber(value.value.name), 'general', 'contract')
    }
    if (value?.name == 'nonStandardGrowth') {
      this.addControls(this.convertToNumber(this.parentIntakeForm.value.general.contract.name), 'general', 'contract')
    }
    if (value?.name == 'name') {
      this.parentIntakeForm.get('jobber.sub_jobber')?.reset()
      console.log(this.defaultValue)
      let distributorSub: any = 0;
      distributorSub = this.defaultValue.filter((x: any) => x.fieldName == "Distributor Sub-Jobber")[0].value
      console.log(this.subJobberOptions, distributorSub)
      console.log(this.subJobberOptions.filter((x: any) => x.distributor_subjobber_id == distributorSub &&
        distributorSub != 0))
      this.parentIntakeForm.patchValue({
        jobber:
        {
          sub_jobber: this.subJobberOptions.filter((x: any) => x.distributor_subjobber_id == distributorSub &&
            distributorSub != 0 && x.distributor_name == this.parentIntakeForm.value.general.name?.name
            && x.country_id == this.parentIntakeForm.value.custInformation.country.id)[0]
        }
      })
    }
  }

  convertToNumber(data: any) {
    if (data == '' || data == undefined || data == null || data == 0)
      return 0
    else {
      return parseFloat(data)
    }
  }

  get contractValue(): UntypedFormArray {
    return <UntypedFormArray>this.parentIntakeForm?.get('general.contractValue');
  }

  get yearValue(): UntypedFormArray {
    return <UntypedFormArray>this.parentIntakeForm?.get('general.yearValue');
  }


}
