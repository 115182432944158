import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UserRegistrationComponent implements OnChanges,OnInit {
  @Input() formData: any;
  @Input() formType: any;
  @Input() data: any;
  @Output() reLoadGrid = new EventEmitter();

  userRegistrationForm = new UntypedFormGroup({
    basfUserId: new UntypedFormControl('', Validators.required),
    country: new UntypedFormControl('', Validators.required),
    role: new UntypedFormControl('', Validators.required),
    firstName: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z,\s]*$/),
    ]),
    lastName: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z,\s]*$/),
    ]),
    emailAddress: new UntypedFormControl('', [Validators.required, Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.(com|org|net|in|COM|ORG|NET|IN)$/)]),
  });

  apiRequest = {
    id: 0,
    basf_user_id: '',
    first_name: '',
    last_name: '',
    email: '',
    created_by: '',
    is_active: true,
    country: [{ id: 0, userId: 0, countryId: 0 }],
    country_name: '',
    role: [{ id: 0, userId: 0, roleId: 0 }],
    role_name: '',
  };
  country: any = [];
  role: any = [];
  selectedCountry: any = [];
  selectedRole: any = [];

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) { }

  roleId:any=0
  ngOnInit(): void {
    this.roleId = atob(sessionStorage.getItem('roleId') + '') || 0
  }
  toastrAlert() {
    this.translate.get('ENTER_MANDATORY').subscribe((res: any) => {
      this.toastr.warning(res)
    })
  }
  ngOnChanges(): void {
    this.country = this.data.country;
    this.role = this.data.role;
    if (this.formType !== 'New') {
      this.selectedCountry = [];
      this.selectedRole = [];
      if (this.formData['country_name'] != null) {
        let tBUnits = this.formData['country_name'].split(',');
        tBUnits.forEach((t: string) => {
          let fBUnit = this.country.find((c: any) => c.value == t.trim());
          if (fBUnit) {
            this.selectedCountry.push(fBUnit);
          }
        });
        this.userRegistrationForm.controls['country'].setValue(
          this.selectedCountry
        );
      }
      if (this.formData['role_name'] != null) {
        let tBUnits = this.formData['role_name'].split(',');
        tBUnits.forEach((t: string) => {
          let fBUnit = this.role.find((c: any) => c.value == t.trim());
          if (fBUnit) {
            this.selectedRole.push(fBUnit);
          }
        });
        this.userRegistrationForm.controls['role'].setValue(this.selectedRole);
      }

      this.userRegistrationForm.controls['basfUserId'].setValue(
        this.formData.basf_user_id
      );
      this.userRegistrationForm.controls['firstName'].setValue(
        this.formData.first_name
      );
      this.userRegistrationForm.controls['lastName'].setValue(
        this.formData.last_name
      );
      this.userRegistrationForm.controls['emailAddress'].setValue(
        this.formData.email
      );
    }
  }
  submitUser() {
    if (this.userRegistrationForm.invalid) {
      this.translate.get('FILL_ALL_FIELDS').subscribe((res: any) => {
        this.toastr.warning(res)
      })
      return false;
    }

    this.spinner.show();

    if (this.formType === 'New') {
      this.apiRequest['created_by'] = sessionStorage.getItem('UserId') || '1';
    } else {
      this.apiRequest['created_by'] = sessionStorage.getItem('id') || '1';
      this.apiRequest['id'] = this.formData.id;
    }
    this.apiRequest['basf_user_id'] =
      this.userRegistrationForm.value.basfUserId;
    this.apiRequest['first_name'] = this.userRegistrationForm.value.firstName;
    this.apiRequest['last_name'] = this.userRegistrationForm.value.lastName;
    this.apiRequest['email'] = this.userRegistrationForm.value.emailAddress;
    this.apiRequest['is_active'] = true;
    this.apiRequest['country_name'] = '';
    this.apiRequest['role_name'] = '';
    this.apiRequest['country'] = this.userRegistrationForm
      .get('country')
      ?.value?.map((a: any) => {
        return {
          id: this.formData.id,
          user_id: 1,
          country_id: Number(a.id),
        };
      });
    this.apiRequest['role'] = this.userRegistrationForm
      .get('role')
      ?.value?.map((a: any) => {
        return {
          id: this.formData.id,
          user_id: 1,
          role_id: Number(a.id),
        };
      });

    this.dataService
      .insertregistrationdetails(this.apiRequest)
      .subscribe((response) => {
        this.spinner.hide();
        if (!response['isError']) {
          this.toastr.success(response.response);
          this.reLoadGrid.emit(true);
        }
      });
    return true;
  }

  cancel() {
    this.reLoadGrid.emit(false);
  }
}
