import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
  selector: 'app-default-form-registration',
  templateUrl: './default-form-registration.component.html',
  styleUrls: ['./default-form-registration.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DefaultFormRegistrationComponent {
  @Input() formData: any;
  @Input() formType: any;
  @Input() data: any;
  @Input() country: any;
  @Output() reLoadGrid = new EventEmitter();
  packageId: string = '';
  tagsForm!: UntypedFormGroup;
  fields: any = [];
  forms: any = [];

  selectedForms: any = [];
  selectedFields: any = [];
  constructor(
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService
  ) {
    this.tagsForm = this.fb.group({
      tags: this.fb.array([]),
    });
    this.tags.push(this.newTag());
  }

  selectedGridRow: any;
  ngOnChanges(): void {
    console.log(this.country)
    console.log(this.selectedForms[0],this.formData)
    this.forms = this.data.forms;
    this.selectedGridRow = this.data.selectedRow
    console.log(this.forms,this.data,this.formData)
    if (this.formType !== 'New') {
      this.selectedFields = [];
      this.selectedForms = [];
      if (this.formData['formsname'] != null) {
        let tBUnits = this.formData['formsname'].split(',');
        tBUnits.forEach((t: string) => {
          let fBUnit = this.forms.find((c: any) => c.name == t.trim());
          if (fBUnit) {
            this.selectedForms.push(fBUnit);
          }
        });
        let firstElem = this.tagsForm.get('tags');
        let formIndexElem = firstElem!.get('0');
        formIndexElem!.get('form')!.setValue(this.selectedForms[0].id);
      
      }
      if (this.formData['fieldsname'] != null) {
        this.loadFields(this.selectedForms[0].id);
      }

      let firstElem = this.tagsForm.get('tags');
      let formIndexElem = firstElem!.get('0');
      formIndexElem!.get('value')!.setValue(this.formData.defaultvalue);
      console.log(formIndexElem,formIndexElem!.get('country'))
        formIndexElem!.get('country')!.setValue(this.selectedGridRow.country_id);
        console.log(formIndexElem,formIndexElem!.get('country'))
      }
  }
  toastrAlert() {
    this.translate.get('ENTER_MANDATORY').subscribe((res: any) => {
      this.toastr.warning(res)
    })
  }
  cancel() {
    this.reLoadGrid.emit(false);
  }

  checkFormField(field: any, res: any) {
    if (field.name == 'Is BASF Variable rebate included in Jobber POS entered?' ||
      field.name == 'Banner Rebates' ||
      field.name == 'Non-Standard Growth') {
      this.fieldsDropdown = res?.response?.deal_master_data
      this.dropdownShow = true
    }
    else if (field.name == 'Location Type') {
      this.fieldsDropdown = [{ id: 2, name: 'Existing' }, { id: 1, name: 'New' }]
      this.dropdownShow = true
    }
    else if (field.name == 'Paint Line to be installed') {
      this.fieldsDropdown = res?.response?.paint_line_collection
      this.dropdownShow = true
    }
    else if (field.name == 'Banner Marketing Rebate') {
      this.fieldsDropdown = res?.response?.banner_percentage_collection
      this.dropdownShow = true
    }
    else if (field.name == 'Number of Contract Years') {
      this.fieldsDropdown = res?.response?.contract_years_collection
      this.dropdownShow = true
    }
    else if (field.name == 'MPR Structure') {
      this.fieldsDropdown = res?.response?.mpr_structure_collection
      this.dropdownShow = true
    }
    else if (field.name == 'Jobber Name') {
      this.fieldsDropdown = res?.response?.jobber_name_collection.filter((x:any)=>x.country_id==this.selectedGridRow.country_id)
      this.dropdownShow = true
    }
    else if (field.name == 'Distributor Sub-Jobber') {
      this.fieldsDropdown = res?.response?.distributor_sub_collection.filter((x:any)=>x.country_id==this.selectedGridRow.country_id)
      this.dropdownShow = true
    }
    else {
      this.dropdownShow = false
    }
  }

  fieldsDropdown: any = []
  fetchValue(_value: any, defaultValue = '') {
    let field = this.fields?.filter((x: any) => x.id == this.tagsForm.get('tags')?.get('0')?.value.field)[0]

    this.dataService.RetriveMasterDealDetils().subscribe((res: any) => {
      if (field?.name != undefined) {
        this.checkFormField(field, res)

        if (this.dropdownShow) {
          if (defaultValue != '') {
            let firstElem = this.tagsForm.get('tags');
            let formIndexElem = firstElem!.get('0');
            formIndexElem!.get('value')!.setValue(Number(defaultValue));
          }
        }


      }
    })
  }
  dropdownShow = false
  percentageInput = false

  allowPercentage() {
    let firstElem = this.tagsForm.get('tags');
    let formIndexElem = firstElem!.get('0');
    let tagValue = Number(formIndexElem!.get('value')?.value)
    if (Number.isNaN(tagValue)) {
      this.toastr.error(this.dataService.trnaslateFunction('DECIMAL_NUMBERS'));
      formIndexElem!.get('value')!.setValue('');
      return
    }
    if (formIndexElem!.get('field')?.value == 15 || formIndexElem!.get('field')?.value == 17 ||
      formIndexElem!.get('field')?.value == 18) {
      if (tagValue < 0 || tagValue > 100) {
        this.toastr.error(this.dataService.trnaslateFunction('ENTER_PERCENTAGE'));
        formIndexElem!.get('value')!.setValue('');
        return
      }
    }

  }

  onSubmit() {
    let apiParameters = [];
    let firstElem = this.tagsForm.get('tags');
    let formIndexElem = firstElem!.get('0');

    //apiParameters.push({
    //  form_id_input: Number(formIndexElem!.get('form')?.value),
    //  field_id_input: Number(formIndexElem!.get('field')?.value),
    //  value_input: Number(formIndexElem!.get('value')?.value),
    //  created_by_input: sessionStorage.getItem('id'),
    //  country_id: formIndexElem!.get('country')?.value || 0,
    //  action_id:0
    //});

    apiParameters.push({
      formIdInput: Number(formIndexElem!.get('form')?.value),
      fieldIdInput: Number(formIndexElem!.get('field')?.value),
      valueInput: Number(formIndexElem!.get('value')?.value),
      createdByInput: sessionStorage.getItem('id'),
      countryId: formIndexElem!.get('country')?.value || 0,
      actionId: 0,
      fieldValue:this.fieldsDropdown.length>0?this.fieldsDropdown.find((i:any)=>i.id ==formIndexElem!.get('value')?.value).name : Number(formIndexElem!.get('value')?.value)
    });

    this.dataService.SaveFormFieldDetails(apiParameters).subscribe({
      next: (_data: any) => {
        this.toastr.success(this.dataService.trnaslateFunction('DATA_ADDED_SUCCESSFULLY'));
        this.reLoadGrid.emit(true);
      },
    });
  }

  get tags(): UntypedFormArray {
    return this.tagsForm.get('tags') as UntypedFormArray;
  }

  newTag(): UntypedFormGroup {
    return this.fb.group({
      form: '',
      field: '',
      value: '',
      country: ''
    });
  }

  addTag() {
    let tag = this.tagsForm.get(`tags.${this.tags.length - 1}`)?.value;

    if (tag.form == '' && tag.field == '' && tag.value == '') {
      this.translate.get('FILL_EMPTY_RECORDS').subscribe((res: any) => {
        this.toastr.warning(res)
      })
    } else {
      this.tags.push(this.newTag());
    }
  }

  minusTag(i: number) {
    if (i == 0) return;
    this.tags.removeAt(i);
  }
  loadFields(formId: any) {
    this.spinner.show();
    let formIndex = this.tagsForm.get('tags')!.get('0')!.get('form')!.value;
    this.dataService
      .RetriveAllFieldDetailsByFormId(formIndex)
      .subscribe((response:any) => {
        this.spinner.hide();
        if (!response['isError']) {
          this.fields = response.response.filter((x:any)=>x.country_id==this.selectedGridRow.country_id);
          this.selectedFields = [];
          console.log(this.fields,"fuqwee")
          let tBUnits = this.formData['fieldsname']?.split(',');
          tBUnits.forEach((t: string) => {
            let fBUnit = this.fields.find((c: any) => c.name.trim() == t.trim());
            if (fBUnit) {
              this.selectedFields.push(fBUnit);
            }
          });
          let firstElem = this.tagsForm.get('tags');
          let formIndexElem = firstElem!.get('0');
          formIndexElem!.get('field')!.setValue(this.selectedFields[0]?.id);
          this.fetchValue(1, this.formData.defaultvalue)
        }
      });
  }
}
