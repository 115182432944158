import { formatDate } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
  selector: 'app-display-deal-grid',
  templateUrl: './display-deal-grid.component.html',
  styleUrls: ['./display-deal-grid.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DisplayDealGridComponent implements OnInit {
  cols: any;
  gridData: any;
  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private router: Router //router
  ) { }

  isShoworEdit = true;
  countryBasedOnRole: any = []
  ngOnInit() {
    this.countryBasedOnRole = JSON.parse(sessionStorage.getItem('country') + '')
    this.generateHeaders();
    this.loadData();
    this.selectedCols = JSON.parse(JSON.stringify(this.cols));
    this.orgCol = JSON.parse(JSON.stringify(this.cols.filter((x: any) => x.is_show)));
  }

  selectedCols: any[] = [];
  orgCol: any
  onChangeDDValue(_value: any) {
    this.cols = JSON.parse(JSON.stringify(this.dataService.sortSelectedColumns(this.selectedCols)));
  }

  generateHeaders() {
    this.cols = [
      {
        field: 'id',
        header: 'Id',
        //id
        serial_no: 1,
        is_show: false,
      },
      {
        field: 'dealref_id',
        header: 'Deal Ref Id',
        //id
        serial_no: 1,
        is_show: true,
      },
      {
        field: 'country_name',
        header: 'Country',
        //country
        serial_no: 2,
        is_show: true,
      },
      {
        field: 'account_status_name',
        header: 'Account Status',
        //account status
        serial_no: 3,
        is_show: true,
      },
      {
        field: 'customer_information',
        header: 'Customer Information',
        //customer information
        serial_no: 4,
        is_show: true,
      },
      {
        field: 'non_standard_growth',
        header: 'Non Standard Growth',
        //non standarad growth
        serial_no: 5,
        is_show: true,
      },
      {
        field: 'target_total_mpr',
        header: 'Target MPR',
        //target total mpr
        serial_no: 6,
        is_show: false,
      },
      {
        field: 'mpr_structure_name',
        header: 'MPR Structure Name',
        //mpr structure name
        serial_no: 7,
        is_show: true,
      },
      {
        field: 'banner_rebates',
        header: 'Banner Rebates',
        //banner rebates
        serial_no: 8,
        is_show: false,
      },

      {
        field: 'banner_marketing_rebate_percentage',
        header: 'Banner Marketing Rebate',
        //banner marketing rebate
        serial_no: 9,
        is_show: true,
      },

      {
        field: 'existing_deal_total_mpr',
        header: 'Existing Deal Total Mpr',
        //existing deal total mpr
        serial_no: 10,
        is_show: true,
      },
      {
        field: 'existing_deal_actual_mpr_fullfilled',
        header: 'Existing Deal Actual Mpr Fullfilled',
        //existing deal actaul mpr
        serial_no: 11,
        is_show: true,
      },
      {
        field: 'existing_deal_effectivedate',
        header: 'Existing Deal Effectivedate',
        //existing deal effectivness
        serial_no: 12,
        is_show: true,
      },
      {
        field: 'existing_deal_fullfilled_throughdate',
        header: 'Existing Deal Fullfilled Throughdate',
        //existing deal fulfilled through date
        serial_no: 13,
        is_show: true,
      },
      {
        field: 'basf_upfront_invetement',
        header: 'Basf Upfront Investement',
        //basf upfront invetsment
        serial_no: 14,
        is_show: false,
      },
      {
        field: 'paintline_to_be_installed_name',
        header: 'Paint Line',
        //paint line
        serial_no: 15,
        is_show: true,
      },
      {
        field: 'number_of_contract_years_name',
        header: 'Contract Years',
        //contract years
        serial_no: 16,
        is_show: true,
      },

      {
        field: 'equipment_provided_jobber',
        header: 'Equipment provided',
        //equipment provided
        serial_no: 17,
        is_show: true,
      },
      {
        field: 'jobber_name',
        header: 'Jobber Name',
        //jobber name
        serial_no: 18,
        is_show: true,
      },
      {
        field: 'is_basf_variable_rebate_jobber_pos_entered',
        header: 'Variable rebate jobber pos',
        //varibale rebate
        serial_no: 19,
        is_show: true,
      },
      {
        field: 'jobber_upfront_invetement',
        header: 'Jobber Upfront Investment',
        //upfrnt investment
        serial_no: 20,
        is_show: true,
      },
      {
        field: 'distributor_sub_jobber_name',
        header: 'Distributor Sub-Jobber',
        //sub jobber
        serial_no: 21,
        is_show: true,
      },
      {
        field: 'jobber_pos_discount',
        header: 'Jobber POS Discount',
        //pos discount
        serial_no: 22,
        is_show: true,
      },
      {
        field: 'fixed_rebate',
        header: 'Fixed Rebate',
        //fixed rebate
        serial_no: 23,
        is_show: true,
      },
      {
        field: 'basf_variable_rebate',
        header: 'BASF Variable Rebate',
        //basf varibale rebate
        serial_no: 24,
        is_show: true,
      },
      {
        field: 'basf_variable_rebate_to_sub_jobber',
        header: 'Variable Rebate to Sub-Jobber',
        //varibale rebate to dsub jobber
        serial_no: 25,
        is_show: true,
      },
    ];
  }

  exportExcel() {
    this.dataService.exportExcel(this.gridData,this.cols, this.dataService.trnaslateFunction('EXPORT_DEALS'))
  }

  loadData() {
    this.spinner.show();
    let userId = Number(sessionStorage.getItem("id"));
    this.dataService.RetriveDealDetils(userId).subscribe((response: any) => {
      this.spinner.hide();
      if (!response['isError']) {
        response.response.forEach((formData: any) => {
          if(formData.is_basf_variable_rebate_jobber_pos_entered==1 )
          formData.is_basf_variable_rebate_jobber_pos_entered ='No'
          else
          formData.is_basf_variable_rebate_jobber_pos_entered ='Yes'
                    if ((formData.existing_deal_effectivedate != undefined ||
                      formData.existing_deal_effectivedate != '') && formData.existing_deal_effectivedate != null 
                    )
                      formData.existing_deal_effectivedate = formatDate(
                        formData.existing_deal_effectivedate,
                        'MM/dd/yyyy',
                        'en-US'
                      );
                    if (
                      (formData.existing_deal_fullfilled_throughdate != undefined ||
                      formData.existing_deal_fullfilled_throughdate != '' ) && formData.existing_deal_fullfilled_throughdate != null
                    )
                      formData.existing_deal_fullfilled_throughdate = formatDate(
                        formData.existing_deal_fullfilled_throughdate,
                        'MM/dd/yyyy',
                        'en-US'
                      );
                  });
        let dat = response.response.filter((obj: any) => this.countryBasedOnRole.includes(obj.country_id))
        this.gridData = dat
        console.log(this.gridData);
      }
    });
  }

  onSelectRow(event: any) {
    console.log(event);
    if (event.type == 'edit') {
      //on select row
      this.router.navigate(['edit-deal', btoa(event.rowData.id)]);
      this.dataService.sendDealFormData(event.rowData);
    }
  }

  refreshUsers(eventvalue: any) {
    if (eventvalue) {
      this.loadData();
    }
  }
}
