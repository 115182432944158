import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
  selector: 'app-sub-jobber-margins',
  templateUrl: './sub-jobber-margins.component.html',
  styleUrls: ['./sub-jobber-margins.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SubJobberMarginsComponent implements OnInit, OnChanges {

  cols: any = [];
  subJobberMarginData: any = [];
  selectedRow: any;
  formType: any;
  showForm = false;
  subJobberMarginForm!: UntypedFormGroup
  @Input() refreshData = false
  @Input() country: any = [];
  @Input() subJobberList: any = [];
  @Input() distributorList: any = [];
  constructor(private fb: UntypedFormBuilder, private dataService: DataService,
    private translate: TranslateService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnChanges(): void {
    console.log("subjobberlist", this.subJobberList)
    this.subJobberMarginForm?.reset()
    this.loadGridHeader()
  }

  ngOnInit() {
    this.loadData();
    this.generateForm()
  }

  selectedCols: any[] = [];
  orgCol: any
  onChangeDDValue(_value: any) {
    this.cols = JSON.parse(JSON.stringify(this.dataService.sortSelectedColumns(this.selectedCols)));
  }

  exportExcel() {
    this.dataService.exportExcel(this.subJobberMarginData,this.cols,this.dataService.trnaslateFunction('EXPORT_SUBJOBBER'))
  }

  loadGridHeader(): void {
    this.country?.forEach((element: any, i: number) => {
      this.spinner.show();
      this.dataService.GetDistributorAssumptionsHeaders(element.id, 1).subscribe((response: any) => {
        this.spinner.hide();
        if (!response['isError']) {
          let col = response.response
            .map((item: any, i: number) => {
              return { serial_no: i + 4, is_show: true, field: item.assumptions_variable_name.toLowerCase(), header: item.assumptions_variable_name };
            });
          if (col.length > 0) {
            col.unshift({ is_show: true, field: 'distributor_subjobber_name', header: 'Subjobber', serial_no: 3 })
            col.unshift({ is_show: true, field: 'distributor_name', header: 'Distributor', serial_no: 2 })
            col.unshift({ is_show: true, field: 'country_name', header: 'Country', serial_no: 1 })
            this.cols = col
          }
        }

        this.selectedCols = JSON.parse(JSON.stringify(this.cols));
        this.orgCol = JSON.parse(JSON.stringify(this.cols.filter((x: any) => x.is_show)));
        console.log("cols", this.cols)
      });

    });
  }


  generateForm() {
    this.subJobberMarginForm = this.fb.group({
      country: new UntypedFormControl('', Validators.required),
      distributor: new UntypedFormControl('', [Validators.required,]),
      subJobber: new UntypedFormControl('', [Validators.required,]),
      margin: this.fb.array([])
    });
  }

  loadData() {
    this.spinner.show();
    this.dataService.GetDistributorAssumptionsList().subscribe((response: any) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.subJobberMarginData = response.response.subjobberlist;
        console.log("subJobberMarginData", this.subJobberMarginData)
      }
    });
  }

  loadHeader() {
    let subJobberId = this.subJobberMarginForm.value.subJobber?.distributor_subjobber_id
    if (subJobberId == undefined)
      subJobberId = 1
    this.spinner.show();
    this.dataService.GetDistributorAssumptionsHeaders(this.subJobberMarginForm.value.country.id, subJobberId).subscribe((response: any) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.displayHeaders = response.response;

        this.removeFormControl()
        this.addFormControl()
      }
    });
  }

  distributorListCountryWise: any = []
  subJobberListDistributorWise: any = []
  valueChanged(value: any) {
    console.log(value)
    if (value?.name == 'country') {
      if (value.value.id == 1) {
        this.translate.get('SELECT_DIFFERENT_COUNTRY').subscribe((res: any) => {
          this.toastr.warning(res)
        })
      }
      this.subJobberMarginForm.get('distributor')?.reset()
      this.subJobberMarginForm.get('subJobber')?.reset()
      this.removeFormControl()
      this.distributorListCountryWise = this.distributorList.filter((x: any) => x.country_id == value.value.id)
    }
    if (value?.name == 'distributor') {
      this.subJobberMarginForm.get('subJobber')?.reset()
      this.removeFormControl()
      this.subJobberListDistributorWise = this.subJobberList.filter((x: any) => x.distributor_id == this.subJobberMarginForm.value.distributor?.distributor_id)
    }
    console.log(this.subJobberListDistributorWise, this.subJobberList)

    this.checkValueExits()
    if (value?.name == 'subJobber') {
      this.loadHeader()
    }

  }

  deleteDistributororSubjobber() {
    let countryId = this.selectedRow.country_id
    let distributorId = this.selectedRow.distributor_id
    let subJobberId = this.selectedRow.distributor_subjobber_id
    this.dataService
      .DeleteDistributororSubjobberMargin(countryId, distributorId, subJobberId)
      .subscribe((response: any) => {
        this.spinner.hide();
        if (!response['isError']) {
          this.toastr.success(this.dataService.trnaslateFunction('DATA_DELETED_SUCCESSFULLY'));
          this.refreshUsers('refresh')
        }
      });
  }

  onSelectRow(rowObject: { rowData: any, type: any }) {
    let { rowData } = rowObject;
    this.selectedRow = JSON.parse(JSON.stringify(rowData));
    if (rowObject['type'] == 'edit') {

      this.subJobberMarginForm.patchValue({
        country: { id: this.selectedRow.country_id, value: this.selectedRow.country_name },
        distributor: {
          distributor_id: this.selectedRow.distributor_id, distributor_name: this.selectedRow.distributor_name,
          country_id: this.selectedRow.country_id, country_name: this.selectedRow.country_name
        },
        subJobber: {
          distributor_subjobber_id: this.selectedRow.distributor_subjobber_id, distributor_subjobber_name: this.selectedRow.distributor_subjobber_name,
          distributor_id: this.selectedRow.distributor_id, distributor_name: this.selectedRow.distributor_name,
          country_id: this.selectedRow.country_id, country_name: this.selectedRow.country_name
        },
      })
      this.distributorListCountryWise = this.distributorList.filter((x: any) => x.country_id == this.subJobberMarginForm.value.country.id)
      this.subJobberListDistributorWise = this.subJobberList.filter((x: any) => x.distributor_id == this.subJobberMarginForm.value.distributor.distributor_id)
      console.log(this.distributorListCountryWise, this.subJobberListDistributorWise)
      this.loadHeader()
      this.formType = 'Edit';
      this.showForm = true;
    }
    else if (rowObject['type'] == 'delete') {
      this.deleteDistributororSubjobber()
    }
  }

  displayHeaders: any = []
  addFormControl() {
    this.subJobberMarginForm.addControl('margin', this.fb.array([]))
    this.displayHeaders.forEach((element: any, i: number) => {

      let value = ''
      if (this.formType == 'Edit' || this.checkValueExits()) {
        let val = element.assumptions_variable_name.toLowerCase()
        console.log(this.selectedRow)
        if (this.selectedRow != undefined)
          value = this.selectedRow[val]
      }
      this.margin.push(this.createMargin(element.assumptions_variable_id, value));

    });
    console.log(this.subJobberMarginForm.value)
  }

  checkValueExits() {
    console.log(this.subJobberMarginForm.value, "checkvalueexists")
    if (this.subJobberMarginForm.value.country?.value != undefined && this.subJobberMarginForm.value.distributor?.distributor_name != undefined
      && this.subJobberMarginForm.value.subJobber?.distributor_subjobber_id != undefined) {
      this.selectedRow = []
      console.log(this.subJobberMarginForm.value, this.subJobberMarginData)
      const data = this.subJobberMarginData.filter((x: any) =>
        x.distributor_name == this.subJobberMarginForm.value.distributor?.distributor_name
        && x.country_name == this.subJobberMarginForm.value.country?.value &&
        x.distributor_subjobber_id == this.subJobberMarginForm.value.subJobber?.distributor_subjobber_id)[0]
      console.log(data)
      if (data != undefined) {
        this.selectedRow = data
        return data
      }
      else return 0
    }
    return 0
  }

  get margin(): UntypedFormArray {
    return <UntypedFormArray>this.subJobberMarginForm.get('margin');
  }

  createMargin(variableId: any, value?: any): UntypedFormGroup {
    return this.fb.group({
      id: [variableId],
      value: [value, [Validators.required, Validators.pattern(/^\d+\.?\d{0,3}$/), Validators.max(100)]]
    })
  }

  removeFormControl() {
    this.subJobberMarginForm.removeControl('margin')
  }

  addNewSubJobberMargin() {
    this.formType = 'New';
    this.showForm = true;
    this.subJobberMarginForm.reset()
    this.removeFormControl()
    console.log(this.subJobberMarginForm.value)
  }

  refreshUsers(eventvalue: any) {
    if (eventvalue) {
      this.loadData();
    }
    this.showForm = false;
  }

  submit() {
    console.log(this.subJobberMarginForm.value)
    let countryId = this.subJobberMarginForm.value.country?.id
    let distributorId = this.subJobberMarginForm.value.distributor?.distributor_id
    let subJobberId = this.subJobberMarginForm.value.subJobber?.distributor_subjobber_id
    let distributorList: any = []
    console.log(subJobberId)
    this.subJobberMarginForm.value.margin?.forEach((element: any) => {
      let data = {
        "countryId": countryId,
        "distributorId": distributorId,
        "subjobberId": subJobberId,
        "assumptionsVariableId": element.id,
        "value": element.value
      }
      distributorList.push(data)
    });

    console.log(JSON.stringify({ assumptionsValueInput: distributorList }))
    this.dataService
      .InsertOrUpdateDistributorAssumptions({ assumptionsValueInput: distributorList })
      .subscribe((response: any) => {
        this.spinner.hide();
        if (!response['isError']) {
          this.toastr.success(this.dataService.trnaslateFunction('DATA_ADDED_SUCCESSFULLY'));
          this.refreshUsers('refresh')
        }
      });
    this.subJobberMarginForm.reset()
  }
}
