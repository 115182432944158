const BaseUrl = './api';
export const environment = {
  production: true,
  getJWTToken: `${BaseUrl}/Jwt/GetToken`,
  //Digital Deal--localhost
  insertregistrationdetails: `${BaseUrl}/Users/UserDetails`,
  RetrieveAllUsers: `${BaseUrl}/Users/RetrieveAllUsers`,
  RetrieveUserDetailsByBasfUserId: `${BaseUrl}/Users/RetrieveUserDetailsByBasfUserId`,
  RetrieveRoleDetail: `${BaseUrl}/Users/RetrieveRoleDetail`,
  RetrieveCountryDetail: `${BaseUrl}/Users/RetrieveCountryDetail`,
  RetrieveUserMaster: `${BaseUrl}/Users/RetrieveUserMaster`,
  GETSCATTERPLOTDATA:`${BaseUrl}/Deal/GetScatteredPlotsData`,
  GETKPIDEALDATA:`${BaseUrl}/Deal/GetDealKpiData`,
  RetrieveAllKpis: `${BaseUrl}/Kpi/RetrieveMasterDataDetail`,
  SaveKpiDetails: `${BaseUrl}/Kpi/KpiDetails`,
  RetriveFormDataDetails: `${BaseUrl}/FormField/RetriveFormDataDetails`,
  SaveFormFieldDetails: `${BaseUrl}/FormField/FormFieldDetails`,
  RetriveAllFieldDetailsByFormId: `${BaseUrl}/FormField/RetriveAllFieldDetailsByFormId`,
  RetriveFormDetails: `${BaseUrl}/FormField/RetriveFormDetails`,
  RetrieveMasterDataDetail: `${BaseUrl}/Kpi/RetrieveMasterDataDetail`,
  RetrieveKpiDetails: `${BaseUrl}/Kpi/RetrieveKpiDetails`,

  GETDEALDETAILS: `${BaseUrl}/Deal/RetrieveDealDetails`,
  SAVEDEALDETAILS: `${BaseUrl}/Deal/DealDetails`,
  SendtoSalesforce: `${BaseUrl}/Deal/SendtoSalesforce`,
  RetriveOpportunityData: `${BaseUrl}/Salesforce/RetriveOpportunityData`,
  RETRIEVEMASTERDEALETAILS: `${BaseUrl}/Deal/RetrieveMasterDetails`,
  CALCULATEDEALDETAILS: `${BaseUrl}/Deal/CalculateDeal`,
  GETCOUNTRYDETAILS: `${BaseUrl}/Deal/RetrieveCountryDetailsByBasfUserId`,
  GETDEALDEFAULTVALUE: `${BaseUrl}/Deal/RetrieveFieldDefaultValue`,
  GETOPPORTUNITY:`${BaseUrl}/Deal/RetrieveOpportunityDetailsByUserId`,
  
  GetAdminDropdownList: `${BaseUrl}/AdminMaster/GetAdminDropdownList`,
  InsertOrUpdateDistributor: `${BaseUrl}/DistributorMaster/InsertOrUpdateDistributor`,
  GetDistributorDetails: `${BaseUrl}/DistributorMaster/GetDistributorDetails`,
  GetDistributorAssumptionsHeaders: `${BaseUrl}/DistributorMaster/GetDistributorAssumptionsHeaders`,
  GetDistributorAssumptionsList: `${BaseUrl}/DistributorMaster/GetDistributorAssumptionsList`,

  InsertOrUpdateDistributorAssumptions: `${BaseUrl}/DistributorMaster/InsertOrUpdateDistributorAssumptions`,
  DeleteDistributororSubjobber: `${BaseUrl}/DistributorMaster/DeleteDistributororSubjobber`,
  DeleteDistributororSubjobberMargin: `${BaseUrl}/DistributorMaster/DeleteDistributororSubjobberMargin`,
  RetrieveDealByDealId: `${BaseUrl}/Deal/RetrieveDealByDealId`,
  GetRebatesMaster: `${BaseUrl}/AdminMaster/GetRebatesMaster`,
  GetAllRebateValues:`${BaseUrl}/AdminMaster/GetAllRebateValues`,
  GetRebateValues: `${BaseUrl}/AdminMaster/GetRebateValues`,
  ManageBannerRebates: `${BaseUrl}/AdminMaster/ManageBannerRebates`,
  RetrieveFormDetails: `${BaseUrl}/Deal/RetrieveFormDetails`,
  GetRebateMarketingMaster: `${BaseUrl}/AdminMaster/GetRebateMarketingMaster`,
  GetMarketingRebateValues: `${BaseUrl}/AdminMaster/GetMarketingRebateValues`,
  ManageBannerMarketingRebates: `${BaseUrl}/AdminMaster/ManageBannerMarketingRebates`,
  GetAllMarketingRebateValues: `${BaseUrl}/AdminMaster/GetAllMarketingRebateValues`,
  //General Assumption-localhost
  RetrieveGeneralAssumptionMasterData: `${BaseUrl}/GeneralAssumption/RetrieveGeneralAssumptionMasterData`,
  RetriveGeneralAssumptionDetails: `${BaseUrl}/GeneralAssumption/RetriveGeneralAssumptionDetails`,

  GeneralAssumptionDetails: `${BaseUrl}/GeneralAssumption/GeneralAssumptionDetails`,
  RetriveGeneralAssumptionDetailsByCountry: `${BaseUrl}/GeneralAssumption/RetriveGeneralAssumptionDetailsByCountry`,
  //Paint Lines-localhost

  RetrievePaintLineMasterData: `${BaseUrl}/PaintLineMargin/RetrievePaintLineMasterData`,
  RetrivePaintLineMarginDetails: `${BaseUrl}/PaintLineMargin/RetrivePaintLineMarginDetails`,
  RetrivePaintLineMarginByCountry: `${BaseUrl}/PaintLineMargin/RetrivePaintLineMarginByCountry`,
  PaintLineMarginDetails: `${BaseUrl}/PaintLineMargin/PaintLineMarginDetails`,

  //login
  SAML: `${BaseUrl}/Saml/GetSamlURL`,
  GetUser: `${BaseUrl}/Users/GetUser`,
  retrieveJWTToken:`${BaseUrl}/JWT/Token`,
  getUsersforLogin:`${BaseUrl}/Users/GetUser`,
  retrieveUserbyLoginName: BaseUrl + `/Admin/Users/{loginId}/loggedInUser/{userName}`,
};
