<div formGroupName="general" id="generalComponent">
    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'UPFRONT_INVESTMENT' | translate}}
                <span *ngIf="parentIntakeForm.get('general.upfront')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="upfront" width="100%" startAddOn="$"
                    fieldType="inputTextGroup" placeholder="{{'ENTER_UPFRONT_INVESTMENT' | translate}}"
                    (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'NON_STANDARD_GROWTH' | translate}}
                <span *ngIf="parentIntakeForm.get('general.nonStandardGrowth')|hasRequiredField"
                    class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="nonStandardGrowth" width="100%" fieldType="dropdown"
                    placeholder="{{'SELECT_NON_STANDARD_GROWTH' | translate}}" optionLabel="name"
                    [options]="masterDropDownDetails?.deal_master_data" (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div >
        <div class="row paddingClass expandField" style="padding-top:0px">
            <div class="col-md-5 widthClass">{{'CONTRACT_YEARS' | translate}}
                <span *ngIf="parentIntakeForm.get('general.contract')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="contract" width="100%" fieldType="dropdown"
                    placeholder="{{'SELECT_CONTRACT_YEAR' | translate}}" optionLabel="name"
                    [options]="masterDropDownDetails?.contract_years_collection" (blur)="valueChanged($event)"
                    >
                </app-dynamic-form-fields>
            </div>
        </div>

        <div class="row paddingClass expandField" formArrayName="contractValue" [hidden]="parentIntakeForm.value.general.nonStandardGrowth.id==1" 
            *ngFor="let data of contractValue?.controls;let i=index">

            <div class="col-md-5 noShow"></div>
            <div class="row col-md-4 rightPadding width-100">
                <div class="col-md-8 widthClass" style="text-align: start;">{{'YEAR' | translate}}
                    {{i+1}}
                    <span class="hashcol">*</span>
                </div>

                
                <div class="col-md-4 widthClassRemaining rightPadding" [formGroupName]="i">
                    <app-dynamic-form-fields formControlName="value" width="100%" fieldType="inputText" value="0"
                        placeholder="{{'ENTER_VALUE' | translate}}" (blur)="valueChanged($event)">
                    </app-dynamic-form-fields>
                </div>
            </div>

        </div>

        <div class="divider"></div>
    </div>

    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'PAINT_LINE' | translate}}
                <span *ngIf="parentIntakeForm.get('general.paint')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="paint" width="100%" fieldType="dropdown"
                    placeholder="{{'SELECT_PAINT_LINE' | translate}}" optionLabel="name"
                    [options]="masterDropDownDetails?.paint_line_collection" (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>


    <div>
        <div class="row paddingClass expandField" style="padding-top:0px">
            <div class="col-md-5 widthClass">{{'MPR_YEAR0' | translate}}
                <span *ngIf="parentIntakeForm.get('general.year')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="year" width="100%" fieldType="dropdown"
                    placeholder="{{'SELECT_YEAR' | translate}}" optionLabel="name"
                    [options]="masterDropDownDetails?.deal_mpr_purchase_refinish_data" (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>

        <div class="row paddingClass expandField" formArrayName="yearValue"
            *ngFor="let data of yearValue?.controls;let i=index">

            <div class="col-md-5 noShow"></div>
            <div class="row col-md-4 rightPadding width-100">
                <div class="col-md-4 widthClass" style="text-align: start;">{{'MONTH' | translate}}
                    {{i+1}}
                    <span class="hashcol">*</span>
                </div>
                <div class="col-md-8 widthClassRemaining rightPadding" [formGroupName]="i">
                    <app-dynamic-form-fields formControlName="value" width="100%" fieldType="inputText"
                        placeholder="{{'ENTER_VALUE' | translate}}" (blur)="valueChanged($event)">
                    </app-dynamic-form-fields>
                </div>
            </div>
        </div>

        <div class="divider"></div>
    </div>    

    

    

    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'MPR_REDUCTION' | translate}}
                <span *ngIf="parentIntakeForm.get('general.mprReduction')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="mprReduction" width="100%" startAddOn="-$"
                    fieldType="inputTextGroup" placeholder="{{'ENTER_MPR_REDUCTION' | translate}}"
                    (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    

    <div *ngIf="parentIntakeForm.value.custInformation?.accountStatus?.id==1">
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'PREVIOUS_UNEARNED_MARKET_INVESTEMNT' | translate}}
                <span *ngIf="parentIntakeForm.get('general.previous_unearned_market_investment')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="previous_unearned_market_investment" width="100%" startAddOn="$"
                    fieldType="inputTextGroup" placeholder="{{'ENTER_PREVIOUS_UNEARNED_MARKET_INVESTEMNT' | translate}}"
                    (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'EQUIPMENT_COST_PAID_BY_BASF' | translate}}
                <span *ngIf="parentIntakeForm.get('general.equipment_cost_paid_by_basf')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="equipment_cost_paid_by_basf" width="100%" startAddOn="$"
                    fieldType="inputTextGroup" placeholder="{{'ENTER_EQUIPMENT_COST_PAID_BY_BASF' | translate}}"
                    (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

</div>
