import { Component,  OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { DataService } from 'src/app/services/dataServices/data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { JWTTokenService } from 'src/app/services/jwttoken.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login-test',
  templateUrl: './login-test.component.html',
})
export class LoginTestComponent implements OnInit {
  users: any;
  user: any;
  selectedUser: any;
  selectedGridRecord: any;

  constructor(
    private service: DataService,
    private router: Router,
    private spinner: NgxSpinnerService,    
    private jwtservice: JWTTokenService,
    private authService: AuthServiceService,
    private toastr:ToastrService
  ) {
    console.log('login test component');
  }

  ngOnInit(): void {
    this.getAllUsers();
  }
  
  getAllUsers() {
    this.spinner.show();
    this.service.getUsersforLogin().subscribe((response: any) => {
      console.log(response);
      if (!response['isError']) {
        this.users = response.response;
      } else {
        this.toastr.error(response);
      }
      this.spinner.hide();
    });
  }

  loginbtn() {
    if(!this.selectedUser) {
      this.toastr.error("Select an user to continue.");
      return;
    }
    
    this.users.forEach((user: any) => {
      if(user.email == this.selectedUser) {
        this.selectedGridRecord = user;
      }
    });
    this.service.sendUserData(this.selectedGridRecord);

    this.jwtservice.RetrieveJWtToken(this.selectedGridRecord).subscribe({
      next: (result: any) => {
        this.authService.updateLoginUser(this.selectedGridRecord);
        this.authService.updateToken(result);
        this.getUserDetails();
        this.router.navigate(['/home']);
      },
      error: (error: any) => {
        this.authService.updateLoginUser(this.selectedGridRecord);
        this.authService.updateToken('');
        this.router.navigate(['/login-test']);
        console.error(error);
      },
    });
  }

  getUserDetails() {
    this.service.getUser(this.selectedGridRecord).subscribe({
      next: (result: any) => {
        if (result == '') {
          this.service.sendUserData(this.selectedGridRecord);
        } else {
          this.selectedGridRecord = {
            email: result.response.email,
            id: result.response.id,
            first_name: result.response.first_name,
            last_name: result.response.last_name,
            is_admin: result.response.is_admin,
            basf_user_id: result.response.basf_user_id,
            is_subscribed: result.response.is_subscribed,
          };
          this.authService.updateLoginUser(this.selectedGridRecord);
          this.service.sendUserData(this.selectedGridRecord);
        }
       
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }
}
