
export const CHARTDATA = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'BASF Net Sales',
            data: [-65, 59, 80, 81, 56, 55, 40],
            fill: false,
            borderColor: '#ca97d4',
            tension: .4,
            display: true
        },
        {
            label: 'EBIT acc $',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            borderColor: '#75c2ee',
            tension: .4,
            display: true
        },
        {
            label: 'EBIT acc % of MPR',
            data: [95, 59, 80, 81, 56, 25, 40],
            fill: false,
            borderColor: '#4cdbc2',
            tension: .4,
            display: false
        },
        {
            label: 'Present Value of future cash flows',
            data: [28, 18, 40, 19, 86, 47, 90],
            fill: false,
            borderColor: '#debae4',
            tension: .4,
            display: false
        },
        {
            label: 'PVI',
            data: [67, 59, 80, 84, 56, 55, 40],
            fill: false,
            borderColor: '#005278',
            tension: .4,
            display: false
        },
        {
            label: 'Discounted Payback',
            data: [39, 48, 40, 39, 86, 27, 90],
            fill: false,
            borderColor: '#fdc9a1',
            tension: .4,
            display: false
        },
        {
            label: 'Investment as % of MPR',
            data: [65, 99, 80, 81, 56, 52, 40],
            fill: false,
            borderColor: '#ffc15f',
            tension: .4,
            display: false
        },
        {
            label: 'BASF vs Distributor investment split',
            data: [28, 48, 30, 19, 66, 27, 90],
            fill: false,
            borderColor: '#008f73',
            tension: .4,
            display: false
        },
        {
            label: 'Cumulative MPR @ Refinish',
            data: [28, 48, 30, 19, 66, 27, 90],
            fill: false,
            borderColor: '#008f73',
            tension: .4,
            display: false
        }
    ]
};