<div *ngIf="roleId>1" class="container-fluid removePadding side-spacing" style="margin-top: 30px; margin-bottom: 88px" id="adminModule">
  <div class="row">
    <div class="col-md-12 side-p-20">
      <p-tabView id="adminPanel" (onChange)="handleChange($event)" [scrollable]="true" #tabView
        [(activeIndex)]="activeIndex">

        <p-tabPanel header="{{'USERS' | translate}}" [disabled]="roleId<2">
          <ng-template pTemplate="content" *ngIf="roleId>1">
            <app-user-grid (refreshUser)="refreshUser($event)"></app-user-grid>
          </ng-template>

        </p-tabPanel>
        <p-tabPanel header="{{'DEFAULT_FORMS' | translate}}" [disabled]="roleId<2">
          <ng-template pTemplate="content" >
            <div>
              <app-default-form-grid [country]="country"></app-default-form-grid>
            </div>
          </ng-template>

        </p-tabPanel>
        <p-tabPanel header="{{'KPI' | translate}}" [disabled]="roleId<2">
          <ng-template pTemplate="content" >
            <div>
              <app-kpi></app-kpi>
            </div>
          </ng-template>

        </p-tabPanel>
        <p-tabPanel  header="{{'DISTRIBUTOR_REGISTRATION' | translate}}"   [disabled]="roleId<3">
          <ng-template pTemplate="content" >
            <div>
              <app-distributor-registration [subJobberList]="subJobberList" [distributorList]="distributorList"
                [country]="country" (refreshDatas)="refreshs($event)"></app-distributor-registration>
            </div>
          </ng-template>

        </p-tabPanel>
        <p-tabPanel header="{{'DISTRIBUTOR_MARGIN' | translate}}" [disabled]="roleId<3">
          <ng-template pTemplate="content" >
            <div>
              <app-distributor-margins [subJobberList]="subJobberList" [distributorList]="distributorList"
                [country]="country"></app-distributor-margins>
            </div>
          </ng-template>

        </p-tabPanel>
        <p-tabPanel header="{{'SUB_JOBBER_MARGIN' | translate}}" [disabled]="roleId<3">
          <ng-template pTemplate="content" >
            <div>
              <app-sub-jobber-margins [subJobberList]="subJobberList" [distributorList]="distributorList"
                [country]="country"></app-sub-jobber-margins>
            </div>
          </ng-template>

        </p-tabPanel>
        <p-tabPanel header="{{'GENERAL_ASSUMPTIONS' | translate}}" [disabled]="roleId<3">
          <ng-template pTemplate="content" >
            <div>
              <app-general-assumptions-grid></app-general-assumptions-grid>
            </div>
          </ng-template>

        </p-tabPanel>
        <p-tabPanel header="{{'PAINT' | translate}}" [disabled]="roleId<3">
          <ng-template pTemplate="content" >
            <div>
              <app-paint-grid></app-paint-grid>
            </div>
          </ng-template>

        </p-tabPanel>
        <p-tabPanel header="{{'BANNER_MARKETING_REBATES' | translate}}" [disabled]="roleId<3">
          <ng-template pTemplate="content" *ngIf="roleId>2">
            <div>
              <app-banner-shop-rebates [country]="country" bannerType="Banner Marketing Rebates"
                fieldName="{{'SELECT_BANNER_MARKETING_REBATE' | translate}}" heading="{{'BANNER_MARKETING_REBATES' | translate}}">
              </app-banner-shop-rebates>
            </div>
          </ng-template>

        </p-tabPanel>
        <p-tabPanel header="{{'BANNER_SHOP_REBATES' | translate}}" [disabled]="roleId<3">
          <ng-template pTemplate="content" >
            <div>
              <app-banner-shop-rebates [country]="country" bannerType="Banner Shop Rebate"
                fieldName="{{'SELECT_BANNER_SHOP_REBATES' | translate}}"
                heading="{{'BANNER_SHOP_REBATES' | translate}}"></app-banner-shop-rebates>
            </div>
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="{{'CORPORATE_BANNERS' | translate}}" [disabled]="roleId<3">
          <ng-template pTemplate="content" >
            <div>
              <app-banner-shop-rebates [country]="country" bannerType="Corporate Banner Rebates"
                fieldName="{{'SELECT_CORPORATE_BANNERS' | translate}}" heading="{{'CORPORATE_BANNERS' | translate}}">
              </app-banner-shop-rebates>
            </div>
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="{{'ONE_TIME_FEES' | translate}}" [headerStyle]="{'padding-right':'37px'}"
          [disabled]="roleId<3">
          <ng-template pTemplate="content" >
            <div>
              <app-banner-shop-rebates [country]="country" bannerType="One Time Fees"
                fieldName="{{'SELECT_ONE_TIME_FEES' | translate}}" heading="{{'ONE_TIME_FEES' | translate}}">
              </app-banner-shop-rebates>
            </div>
          </ng-template>
        </p-tabPanel>
       
        <button class="selectionIcon" (click)="display=!display" pButton icon="pi pi-ellipsis-v">
        </button>

        <p-listbox *ngIf="display" [options]="headers" [(ngModel)]="selelectedHeader" (onChange)="listBoxSelection()">
          <ng-template let-opt pTemplate="item">
            <span style="padding: 3%;" [ngClass]="opt.index===activeIndex?'orange-highlight':''">{{opt.name}}</span>
          </ng-template>
        </p-listbox>
      </p-tabView>
    </div>
  </div>
</div>