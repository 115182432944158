<div class="min-height">
  <div *ngIf="showForm" class="custom-sidenav">
    <div class="row headerStyling">
      <label class="col-md-12 header-label" *ngIf="formType=='Edit'">{{'EDIT_PAINT_LINES' | translate}}
      </label>
      <label class="col-md-12 header-label" *ngIf="formType=='New'">{{'NEW_PAINT_LINES' | translate}}
      </label>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-paint-registration [formData]="selectedRow" [formType]="formType" [data]="{country,type,line}"
          (reLoadGrid)="refreshPaint($event)">
        </app-paint-registration>
      </div>
    </div>
  </div>

  <div class="custom-padding">
    <div class="row" style="align-items: center; padding: 1rem 0rem">

      <div *ngIf="!showForm" class="col-md-6 mobileButtonClass">
        <button type="button" class="btn add-btn" (click)="addNewPaint()">{{'ADD_NEW' | translate}}</button>
        <button type="button" (click)="exportExcel()" class="btn excelButton">
          <em class="pi pi-file-excel"></em>{{'EXPORT_AS_EXCEL' | translate}} </button>
      </div>

      <div class="col-md-6" style="text-align: end;">
        <p-multiSelect  [options]="orgCol" [(ngModel)]="selectedCols" id="showGridClass" name="colsDD5" ngDefaultControl
          (onChange)="onChangeDDValue($any($event).value)" optionLabel="header"
          defaultLabel="{{'SHOW_ITEMS_IN_GRID' | translate}}" [displaySelectedLabel]="false">
        </p-multiSelect>
      </div>
    </div>
    
    <div class="row">
      <div class="col-md-12">
        <app-dynamic-grid [gridCols]="cols" [gridData]="usersData" [showFilter]="true" [showEdit]="true"
          (selectRow)="onSelectRow($event)">
        </app-dynamic-grid>
      </div>
    </div>
  </div>
</div>