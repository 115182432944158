<div class="row min-height">
  <div class="col-md-12">
    <form id="generalAssumptionForm" [formGroup]="generalAssumptionForm">
      <div class="row headerStyling" style="padding-top:0px">
        <div class="col-md-12">
          <div class="row center-align" style="align-items: inherit">
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="country">{{'COUNTRY' | translate}}<span
                    class="hashcol ng-star-inserted">*</span></label>
                <p-dropdown [options]="country" placeholder="{{'SELECT_COUNTRY' | translate}}" formControlName="country"
                  optionLabel="value" optionValue="value" class="form-control-sm" id="multiSelect_UserRole"
                  (onChange)="getGeneralAssumptionDetailsOnChange()"></p-dropdown>
              </div>
              <div *ngIf="
                  generalAssumptionForm.get('country')?.invalid &&
                  generalAssumptionForm.get('country')?.touched
                " class="text-danger">
                <div *ngIf="
                    generalAssumptionForm.get('country')?.hasError('required')
                  ">
                  {{'COUNTRY_REQUIRED' | translate}}
                </div>
              </div>
            </div>
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="type">{{'TYPE' | translate}} <span
                    class="hashcol ng-star-inserted">*</span></label>
                <p-dropdown [options]="type" placeholder="Select Type" formControlName="type" optionLabel="value"
                  optionValue="value" class="form-control-sm" id="multiSelect_UserRole"
                  (onChange)="getGeneralAssumptionDetailsOnChange()"></p-dropdown>
              </div>
              <div *ngIf="
                  generalAssumptionForm.get('type')?.invalid &&
                  generalAssumptionForm.get('type')?.touched
                " class="text-danger">
                <div *ngIf="
                    generalAssumptionForm.get('type')?.hasError('required')
                  ">
                  {{'TYPE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>
          <div class="row center-align" style="align-items: inherit">
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" style="padding-bottom: 10px" for="country">
                  {{'GENERAL_OVERHEAD' | translate}} (%) (<span class="black-color">{{
                    generalAssumptionObj.generalOverHead
                    }}</span>)<span class="hashcol ng-star-inserted">*</span></label>
                <p-slider [ngStyle]="{ width: '100%' }" formControlName="generalOverHead" [min]="0" [max]="100"
                  [step]="0.01" [(ngModel)]="generalAssumptionObj.generalOverHead"></p-slider>
                <input class="slider-input" formControlName="generalOverHead"
                  [(ngModel)]="generalAssumptionObj.generalOverHead" pInputText />
              </div>
            </div>
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" style="padding-bottom: 10px" for="country">
                  {{'FREIGHT_SALES' | translate}} (%) (<span class="black-color">{{
                    generalAssumptionObj.freightBasfSales
                    }}</span>)<span class="hashcol ng-star-inserted">*</span></label>
                <p-slider [ngStyle]="{ width: '100%' }" formControlName="freightBasfSales" [min]="0" [max]="100"
                  [step]="0.01" [(ngModel)]="generalAssumptionObj.freightBasfSales"></p-slider>
                <input class="slider-input" formControlName="freightBasfSales"
                  [(ngModel)]="generalAssumptionObj.freightBasfSales" pInputText />
              </div>
            </div>
          </div>
          <div class="row center-align" style="align-items: inherit">
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" style="padding-bottom: 10px" for="country">
                  {{'TAX_RATE' | translate}} (%) (<span class="black-color">{{
                    generalAssumptionObj.taxRate }}</span>)<span class="hashcol ng-star-inserted">*</span></label>
                <p-slider [ngStyle]="{ width: '100%' }" formControlName="taxRate" [min]="0" [max]="100" [step]="0.01"
                  [(ngModel)]="generalAssumptionObj.taxRate"></p-slider>
                <input class="slider-input" formControlName="taxRate"
                  [(ngModel)]="generalAssumptionObj.taxRate" pInputText />
              </div>
            </div>
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" style="padding-bottom: 10px" for="country">
                  {{'PV_DISCOUNT' | translate}} (%) (<span class="black-color">{{
                    generalAssumptionObj.pvDiscountRate
                    }}</span>)<span class="hashcol ng-star-inserted">*</span></label>
                <p-slider [ngStyle]="{ width: '100%' }" formControlName="pvDiscountRate" [min]="0" [max]="100"
                  [step]="0.01" [(ngModel)]="generalAssumptionObj.pvDiscountRate"></p-slider>
                <input class="slider-input" formControlName="pvDiscountRate"
                  [(ngModel)]="generalAssumptionObj.pvDiscountRate" pInputText />
              </div>
            </div>
          </div>
          <div class="row center-align" style="align-items: inherit">
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="country">{{'ESTIMATED_DSO' | translate}} <span
                    class="hashcol ng-star-inserted">*</span></label>
                <input class="inputTextStyling" type="number" style="width: 100%" pInput
                  formControlName="estimatedDso" />
              </div>
            </div>
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="country">{{'ESTIMATED_DIV' | translate}} <span
                    class="hashcol ng-star-inserted">*</span></label>
                <input class="inputTextStyling" type="number" style="width: 100%" pInput
                  formControlName="estimatedDiv" />
              </div>
            </div>
          </div>
          <div class="row center-align" style="align-items: inherit">
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" style="padding-bottom: 10px" for="country">
                  {{'COST_CAPITAL' | translate}} (%) (<span class="black-color">{{
                    generalAssumptionObj.costOfCapitalRate
                    }}</span>)<span class="hashcol ng-star-inserted">*</span></label>
                <p-slider [ngStyle]="{ width: '100%' }" formControlName="costOfCapitalRate" [min]="0" [max]="100"
                  [step]="0.01" [(ngModel)]="generalAssumptionObj.costOfCapitalRate"></p-slider>
                <input class="slider-input" formControlName="costOfCapitalRate"
                  [(ngModel)]="generalAssumptionObj.costOfCapitalRate" pInputText />
              </div>
            </div>
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" style="padding-bottom: 10px" for="country">
                  {{'HURDLE_RATE' | translate}} (%) (<span class="black-color">{{
                    generalAssumptionObj.hurdleRate }}</span>)<span class="hashcol ng-star-inserted">*</span></label>
                <p-slider [ngStyle]="{ width: '100%' }" formControlName="hurdleRate" [min]="0" [max]="100" [step]="0.01"
                  [(ngModel)]="generalAssumptionObj.hurdleRate"></p-slider>
                <input class="slider-input" formControlName="hurdleRate"
                  [(ngModel)]="generalAssumptionObj.hurdleRate" pInputText />
              </div>
            </div>
          </div>
          <div class="row center-align" style="align-items: inherit">
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" style="padding-bottom: 10px" for="country">
                  {{'ANNUAL_PRICE' | translate}} (%) (<span class="black-color">{{
                    generalAssumptionObj.annualPriceAssumption
                    }}</span>)<span class="hashcol ng-star-inserted">*</span></label>
                <p-slider [ngStyle]="{ width: '100%' }" formControlName="annualPriceAssumption" [min]="0" [max]="100"
                  [step]="0.01" [(ngModel)]="generalAssumptionObj.annualPriceAssumption"></p-slider>
                <input class="slider-input" formControlName="annualPriceAssumption"
                  [(ngModel)]="generalAssumptionObj.annualPriceAssumption" pInputText />
              </div>
            </div>
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" style="padding-bottom: 10px" for="country">
                  {{'MARGIN_ASSUMPTIONS' | translate}} (%) ({{
                  generalAssumptionObj.marginAssumption
                  }})<span class="hashcol ng-star-inserted">*</span></label>
                <p-slider [ngStyle]="{ width: '100%' }" formControlName="marginAssumption" [min]="0" [max]="100"
                  [step]="0.01" [(ngModel)]="generalAssumptionObj.marginAssumption"></p-slider>
                <input class="slider-input" formControlName="marginAssumption"
                  [(ngModel)]="generalAssumptionObj.marginAssumption" pInputText />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6"></div>
      </div>
      <div class="container-fluid mobileButtonClass paddingClassButtons">
        <div class="row">
          <div class="col-md-12" style="text-align: center;">
            <button type="button" (click)="cancel()" class="btn cancel-btn"
              style="background-color: #4f4f4f;">{{'CANCEL' | translate}}</button>
            <button type="button" class="btn submit-btn" (click)="submitForm()"
              [disabled]="generalAssumptionForm.invalid"
              (click)="generalAssumptionForm.invalid ? toastrAlert() : ''">{{'SUBMIT' |
              translate}}</button>
          </div>
        </div>
      </div>

    </form>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="line-scale"></ngx-spinner>