import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
  selector: 'app-distributor-registration',
  templateUrl: './distributor-registration.component.html',
  styleUrls: ['./distributor-registration.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DistributorRegistrationComponent implements OnInit {

  cols: any;
  distributorData: any;
  selectedRow: any;
  formType: any;
  showForm = false;
  distributorForm = new UntypedFormGroup({
    country: new UntypedFormControl('', Validators.required),

    distributor: new UntypedFormControl('', [
      Validators.required,
    ]),
    subJobber: new UntypedFormControl([],)
  });
  @Output() refreshDatas = new EventEmitter(false);
  @Input() country: any = [];
  @Input() subJobberList: any = [];
  @Input() distributorList: any = [];
  constructor(private dataService: DataService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnInit() {
    console.log(this.country)
    this.generateHeaders();
    this.loadData();
    this.selectedCols = JSON.parse(JSON.stringify(this.cols));
    this.orgCol = JSON.parse(JSON.stringify(this.cols.filter((x: any) => x.is_show)));
  }

  selectedCols: any[] = [];
  orgCol: any
  onChangeDDValue(_value: any) {
    this.cols = JSON.parse(JSON.stringify(this.dataService.sortSelectedColumns(this.selectedCols)));
  }

  exportExcel() {
    this.dataService.exportExcel(this.distributorData,this.cols, this.dataService.trnaslateFunction('EXPORT_DISTRIBUTOR_REGISTRATION'))
  }

  generateHeaders() {
    this.cols = [
      {
        field: 'country_name',
        header: 'Country',
        is_show: true,
        is_editable: true,
        serial_no: 1,
      },
      {
        field: 'distributor_name',
        header: 'Distributor',
        is_show: true,
        is_editable: true,
        serial_no: 2,
      },
      {
        field: 'distributor_subjobber_name',
        header: 'Sub Jobber',
        is_show: true,
        is_editable: true,
        serial_no: 3,
      },
    ];
  }

  loadData() {
    this.spinner.show();
    this.dataService.GetDistributorDetails().subscribe((response: any) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.distributorData = response.response;
      }
    });
  }

  valueChanged(value: any) {
    console.log(value)
  }

  onSelectRow(rowObject: { rowData: any, type: any }) {
    console.log(JSON.stringify(rowObject))

    let { rowData } = rowObject;
    this.selectedRow = JSON.parse(JSON.stringify(rowData));

    console.log(this.selectedRow)
    if (rowObject['type'] == 'edit') {

      this.distributorForm.patchValue({
        country: { id: this.selectedRow.country_id, value: this.selectedRow.country_name },
        distributor: this.selectedRow.distributor_name,
        subJobber: this.selectedRow.distributor_subjobber_name

      })
      console.log(this.distributorForm.value)
      this.formType = 'Edit';
      this.showForm = true;
    }
    else if (rowObject['type'] == 'delete') {
      this.deleteDistributororSubjobber()
    }
  }

  addNewDistributor() {
    this.formType = 'New';
    this.showForm = true;
    this.distributorForm.reset()
  }

  refreshData(eventvalue: any) {
    if (eventvalue) {
      this.loadData();
    }
    this.refreshDatas.emit(true)
    this.showForm = false;
  }

  deleteDistributororSubjobber() {
    let countryId = this.selectedRow.country_id
    let distributorId = this.selectedRow.distributor_id
    let subJobberId = this.selectedRow.distributor_subjobber_id
    this.dataService
      .DeleteDistributororSubjobber(countryId, distributorId, subJobberId)
      .subscribe((response: any) => {
        this.spinner.hide();
        if (!response['isError']) {
          this.toastr.success(this.dataService.trnaslateFunction('DISTRIBUTOR_DELETED'));
          this.refreshData('refresh')
        }
      });
  }


  checkDuplicate() {
    const duplicates = this.distributorData.filter((obj: any) => {
      if (obj.country_id == 1)
        return obj.country_id == this.distributorForm.value.country.id &&
          obj.distributor_name.toLowerCase() == this.distributorForm.value.distributor.toLowerCase()
      else if (obj.country_id == 2)
        return obj.country_id == this.distributorForm.value.country.id &&
          obj.distributor_name.toLowerCase() == this.distributorForm.value.distributor.toLowerCase() &&
          obj.distributor_subjobber_name.toLowerCase() == this.distributorForm.value.subJobber.toLowerCase()
      else
        return []
    })
    return duplicates
  }

  submitDistributor() {
    console.log(this.distributorForm.value)
    if (this.distributorForm.value.country?.value == 'CA' && this.distributorForm.value.subJobber == '') {
      this.toastr.warning(this.dataService.trnaslateFunction('ENTER_JOBBER_WARNING'));
      return;
    }
    const duplicates: any = this.checkDuplicate()
    console.log(duplicates)
    if (duplicates.length) {
      this.toastr.error(this.dataService.trnaslateFunction('DUPLICATE'));
      return
    }
    let distributorId = 0
    let subJobberId = 0
    if (this.formType == 'Edit') {

      subJobberId = this.selectedRow.distributor_subjobber_id
    }
    distributorId = this.distributorList.filter((x: any) => (x.distributor_name).toLowerCase() ==
      (this.distributorForm.value.distributor).toLowerCase()
      && x.country_id == this.distributorForm.value.country.id)[0]?.distributor_id
    if (distributorId == undefined) {
      distributorId = 0
    }
    let subJobber = []
    let subJobberList = []
    if (this.distributorForm.value.subJobber != null && this.distributorForm.value.subJobber != undefined && this.distributorForm.value.country.value != 'US') {
      subJobber = this.distributorForm.value.subJobber.split(',')
      subJobberList.push({ subjobberId: subJobberId, subjobberName: subJobber[0] })
      for (let i = 1; i < subJobber.length; i++)
        subJobberList.push({ subjobberId: 0, subjobberName: subJobber[i] })
    }

    console.log(subJobberList)
    let apiRequest =
    {
      "countryId": this.distributorForm.value.country.id,
      "countryName": this.distributorForm.value.country.value,
      "distributorId": distributorId,
      "distributorName": this.distributorForm.value.distributor,
      "subjobbers": subJobberList

    }
    this.spinner.show()
    console.log(JSON.stringify(apiRequest))
    this.dataService
      .InsertOrUpdateDistributor(apiRequest)
      .subscribe((response: any) => {
        this.spinner.hide();
        if (!response['isError']) {
          this.dataService.trnaslateFunction('DATA_ADDED_SUCCESSFULLY')
          this.refreshData('refresh')
        }
      });

    this.distributorForm.reset()
  }
}
