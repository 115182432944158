<div formGroupName="existing">
    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'EXISTING_TOTAL_MPR_FULFILLED' | translate}}
                <span *ngIf="parentIntakeForm.get('existing.total')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="total" width="100%" startAddOn="$" fieldType="inputTextGroup"
                    placeholder="{{'ENTER_EXISTING_MRP' | translate}}" (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'EXISTING_ACTUAL_MPR' | translate}}
                <span *ngIf="parentIntakeForm.get('existing.actual')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="actual" width="100%" startAddOn="$" fieldType="inputTextGroup"
                    placeholder="{{'ENTER_EXISTING_TOTAL_MRP' | translate}}" (blur)="valueChanged($event,0,'actual')">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'EFFECTIVE_DATE' | translate}}
                <span *ngIf="parentIntakeForm.get('existing.efdate')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="efdate" width="100%" fieldType="inputDate"
                    (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'THROUGH_DATE' | translate}}
                <span *ngIf="parentIntakeForm.get('existing.tdate')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="tdate" width="100%" fieldType="inputDate"
                    (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'MARKET_INVESTMENT' | translate}}
                <span *ngIf="parentIntakeForm.get('existing.marketInvestment')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="marketInvestment" width="100%" startAddOn="$" fieldType="inputTextGroup"
                    placeholder="{{'ENTER_MARKET_INVESTMENT' | translate}}" (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'ACTUAL_MPR_PURCHASED' | translate}}
                <span *ngIf="parentIntakeForm.get('existing.actualMprPurchased')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="actualMprPurchased" width="100%" startAddOn="$" fieldType="inputTextGroup"
                    placeholder="{{'ENTER_ACTUAL_MPR_PURCHASED' | translate}}" (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'CONTRACTUAL_MPR' | translate}}
                <span *ngIf="parentIntakeForm.get('existing.contractualMpr')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="contractualMpr" width="100%" startAddOn="$" fieldType="inputTextGroup"
                    placeholder="{{'ENTER_CONTRACTUAL_MPR' | translate}}" (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'TEAM_EXISTING' | translate}}
                <span *ngIf="parentIntakeForm.get('existing.teamExisting')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="teamExisting" width="100%" startAddOn="$" fieldType="inputTextGroup"
                    placeholder="{{'ENTER_TEAM_EXISTING' | translate}}" (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div *ngIf="0">
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'ACTUAL_MPR_FULFILLED' | translate}}
                <span *ngIf="parentIntakeForm.get('existing.actualMprFulfilled')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="actualMprFulfilled" width="100%" startAddOn="$" fieldType="inputTextGroup"
                    placeholder="{{'ENTER_ACTUAL_MPR_FULFILLED' | translate}}" (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

</div>