<div id="dynamicForm" [ngClass]="{ 'was-validated': controlDir.control?.valid }">

  <!-- Input text -->
  <input *ngIf="fieldType=='inputText'" type="text" (input)="onChange($any($event.target).value)"
    (blur)="onTouched(); blur.emit();" [disabled]="fieldDisable" [placeholder]="placeholder" [value]="controlDir.value"
    [ngStyle]="{'width':width}" [ngClass]="{
          'is-invalid': controlDir.control?.touched && !controlDir.control?.valid
        }" pInputText />

  <!-- Input text Group -->
  <div *ngIf="fieldType=='inputTextGroup'" class="p-inputgroup" [ngClass]="{
        'is-invalid': controlDir.control?.touched && !controlDir.control?.valid
      }">
    <span *ngIf="startAddOn!=''" class="p-inputgroup-addon">{{startAddOn}}</span>
    <input type="text" (input)="onChange($any($event.target).value)" (blur)="onTouched(); blur.emit()"
      [disabled]="fieldDisable" [placeholder]="placeholder" [value]="controlDir.value" pInputText />
    <span *ngIf="endAddOn!=''" class="p-inputgroup-addon">{{endAddOn}}</span>
  </div>

  <!-- Input percentage -->
  <p-inputNumber *ngIf="fieldType=='inputPercentage'" mode="decimal" [maxFractionDigits]="2" [min]="0" [max]="100"
    [ngModel]="controlDir.value" (onClick)="onTouched(); " (onInput)="validate($event.value)" [disabled]="fieldDisable"
    [ngStyle]="{'width':width}" [ngClass]="{
        'is-invalid': controlDir.control?.touched && !controlDir.control?.valid
      }"></p-inputNumber>

  <!-- Input Date -->
  <input *ngIf="fieldType=='inputDate'" type="date" [min]="minDate" [max]="maxDate"
    (input)="onChange($any($event.target).value)" (blur)="onTouched(); blur.emit()" [disabled]="fieldDisable"
    [placeholder]="placeholder" [value]="controlDir.value" [ngStyle]="{'width':width}" [ngClass]="{
          'is-invalid': controlDir.control?.touched && !controlDir.control?.valid
        }" pInputText />

  <!-- Slider -->
  <p-slider *ngIf="fieldType=='slider'" [ngModel]="controlDir.value" (onClick)="onTouched();"
  [step]="0.01"
    (onChange)="validate($event.value)" [disabled]="fieldDisable" [ngStyle]="{'width':width}" [ngClass]="{
          'is-invalid': controlDir.control?.touched && !controlDir.control?.valid
        }"></p-slider>
  <input style="float:right;width:100%;margin-top: 6px;" *ngIf="fieldType=='slider'" [value]="controlDir.value"
    (input)="validate($any($event.target).value)" pInputText />

<!-- Slider -->
<p-slider *ngIf="fieldType=='wholenumberslider'"  [ngModel]="controlDir.value" (onClick)="onTouched(); 
"[min]="0" [max]="100" [step]="1"
(onChange)="validate($event.value)" [disabled]="fieldDisable" [ngStyle]="{'width':width}" [ngClass]="{
      'is-invalid': controlDir.control?.touched && !controlDir.control?.valid
    }"></p-slider>
    <p-inputNumber *ngIf="fieldType=='wholenumberslider'"  mode="decimal" [maxFractionDigits]="0" [min]="0" [max]="100"
    [ngModel]="controlDir.value" (onClick)="onTouched(); " (onInput)="validate($event.value)" [disabled]="fieldDisable"
    [ngStyle]="{'width':width}" [ngClass]="{
        'is-invalid': controlDir.control?.touched && !controlDir.control?.valid
      }"></p-inputNumber>
  
<!-- Slider -->
<p-slider *ngIf="fieldType=='decimalnumberslider'"  [ngModel]="controlDir.value" (onClick)="onTouched(); 
"[min]="0" [max]="100" [step]="1"
(onChange)="validate($event.value)" [disabled]="fieldDisable" [ngStyle]="{'width':width}" [ngClass]="{
      'is-invalid': controlDir.control?.touched && !controlDir.control?.valid
    }"></p-slider>
    <p-inputNumber *ngIf="fieldType=='decimalnumberslider'"  mode="decimal" [maxFractionDigits]="1" [min]="0" [max]="100"
    [ngModel]="controlDir.value" (onClick)="onTouched(); " (onInput)="validate($event.value)" [disabled]="fieldDisable"
    [ngStyle]="{'width':width}" [ngClass]="{
        'is-invalid': controlDir.control?.touched && !controlDir.control?.valid
      }"></p-inputNumber>      

  <!-- Dropdown -->
  <p-dropdown *ngIf="fieldType=='dropdown'" [options]="options" [placeholder]="placeholder" appendTo="body" [ngModel]="controlDir.value"
    (onClick)="onTouched(); " [editable]="editable" [disabled]="fieldDisable" (onChange)="validate($event.value)"
    [optionLabel]="optionLabel" [ngStyle]="{'width':width}" [filter]="searchable" [filterBy]="optionLabel" [ngClass]="{
        'is-invalid': controlDir.control?.touched && !controlDir.control?.valid
      }" class="dropdown"></p-dropdown>

  <!-- autocomplete -->
  <p-autoComplete *ngIf="fieldType=='autocomplete'" [suggestions]="filteredOptions" [ngModel]="controlDir.value"
    (onSelect)="validate($event)" (onBlur)="validate($any($event.target).value)" [ngStyle]="{'width':width}"
    [field]="optionLabel" [dropdown]="true" (completeMethod)="filterOptions($event)" [placeholder]="placeholder"
    [ngClass]="{
            'is-invalid': controlDir.control?.touched && !controlDir.control?.valid
          }">
  </p-autoComplete>

  <!-- textarea -->
  <textarea style="vertical-align:middle" *ngIf="fieldType=='textarea'" [rows]="4" [cols]="50" pInputTextarea
    (input)="onChange($any($event.target).value)" (blur)="onTouched(); blur.emit()" [disabled]="fieldDisable"
    [placeholder]="placeholder" [value]="controlDir.value" [ngStyle]="{'width':width}" [ngClass]="{
          'is-invalid': controlDir.control?.touched && !controlDir.control?.valid
        }"></textarea>

  <!-- checkbox -->
  <input *ngIf="fieldType=='checkbox'" type="checkbox" style="height: 15px;width:20px;margin-right:10px"
    (input)="onChange($any($event.target).checked)" [ngModel]="controlDir.value" (blur)="onTouched(); blur.emit()"
    (change)="validate($any($event.target).checked)" />
  <label *ngIf="checkBoxMessage!=''" class="cust-form-label"
    style="vertical-align: text-bottom;">{{checkBoxMessage}}</label>

  <!-- radio button -->
  <span *ngIf="fieldType=='radiobutton'" style="font-size: 14px">
    <input [name]="radioName" value="Yes" type="radio" inputId="value1" [ngModel]="controlDir.value"
      (input)="onChange($any($event.target).value)" style="margin-right: 10px"
      (change)="validate($any($event.target).value)" />
    <label for="value1" style="margin-right: 30px;" class="radioClass">{{radioValue1}}</label>
    <input [name]="radioName" value="No" type="radio" (input)="onChange($any($event.target).value)"
      style="margin-right: 10px" [ngModel]="controlDir.value" (change)="validate($any($event.target).value)" />
    <label for="value2" style="margin-right: 10px;" class="radioClass">{{radioValue2}}</label>
  </span>

  <!-- input switch -->
  <span *ngIf="fieldType=='switch'">
    <label style="margin-right: 20px;" class="radioClass" [ngClass]="{'redColor':controlDir.value==false||controlDir.value=='No',
  'grayColor':controlDir.value==true||controlDir.value=='Yes'}">{{radioValue2}}</label>
    <p-inputSwitch *ngIf="fieldType=='switch'" [ngModel]="controlDir.value===true||controlDir.value=='Yes'?true:false"
      (onClick)="onTouched(); " (onChange)="validate($event.checked?'Yes':'No')" [disabled]="fieldDisable"
      [ngStyle]="{'width':width}" [ngClass]="{
        'is-invalid': controlDir.control?.touched && !controlDir.control?.valid
      }"></p-inputSwitch>
    <label style="margin-left: 20px;" class="radioClass" [ngClass]="{'blueColor':controlDir.value==true||controlDir.value=='Yes',
    'grayColor':controlDir.value==false||controlDir.value=='No'}">{{radioValue1}}</label>
  </span>

  <!-- Multi Select -->
  <p-multiSelect *ngIf="fieldType=='multiSelect'" [options]="options" [ngModel]="controlDir.value"
    [placeholder]="placeholder" display="chip" (onClick)="onTouched(); " (onChange)="validate($event.value)"
    [disabled]="fieldDisable" [optionLabel]="optionLabel" [ngStyle]="{'width':width}" [ngClass]="{
        'is-invalid': controlDir.control?.touched && !controlDir.control?.valid
      }" class="dropdown"></p-multiSelect>

  <div class="invalid-feedback">{{controlDir.control?.errors | validatorMessage }}</div>
</div>