import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FilterService } from 'primeng/api';
import * as moment from 'moment';
import * as saveAs from 'file-saver';
import { Table } from 'primeng/table';
import { DataService } from 'src/app/services/dataServices/data.service';
@Component({
  selector: 'app-dynamic-grid',
  templateUrl: './dynamic-grid.component.html',
  styleUrls: ['./dynamic-grid.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicGridComponent implements OnInit {
  @Input() gridCols: any;
  @Input() gridData: any;
  @Input() checkboxShow: any;
  @Input() infoIconShow: any;
  @Input() showEdit: any;
  @Input() showDelete: any;
  @Input() showView: any;
  @Input() showClone: any;
  @Input() showFilter: any;
  @Input() showActive: any;
  @Input() showEmail: any;
  @Input() showReview: any;
  @Input() dataKey: any;
  @Input() adminAction: any;
  @Input() showColSelection: any; 
  @Input() paginator: any = true;

  @Output() filterData = new EventEmitter();
  @Output() selectRow = new EventEmitter();
  @Output() sendSelectedRows = new EventEmitter();
  @ViewChild('dt') table: Table | undefined

  defaultWidth!: '100%';
  userId: any;
  userAdmin: boolean = false;
  filteredData: any;
  filteredFields = [];
  tempFilteredData: any;
  title = '';
  selectedRows: any;
  bu: any;
  filters: any = [];
  orgGridData: any;
  orgCols: any;
  isDistributor: boolean = false;

  constructor(private filterService: FilterService,private dataService:DataService) { }

  ngOnInit() {
    if (this.gridData) {
      this.orgGridData = JSON.parse(JSON.stringify(this.gridData));
      this.filterService.register(
        'custom',
        (
          value: number | null | undefined,
          filter: string | null | undefined
        ): boolean => {
          if (filter === undefined || filter === null || filter.trim() === '') {
            return true;
          }

          if (value === undefined || value === null) {
            return false;
          }

          return parseInt(filter) > value;
        }
      );
      this.orgCols = this.sortSelectedColumns(this.gridCols);
      this.selectedCols = JSON.parse(JSON.stringify(this.gridCols));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.clearSearch()
    if (this.gridData)
      this.orgGridData = JSON.parse(JSON.stringify(this.gridData));
    if (changes['gridCols'])
      this.orgCols = this.sortSelectedColumns(
        changes['gridCols']['currentValue']
      );
    this.selectedCols = JSON.parse(JSON.stringify(this.gridCols));
  }

  clearSearch() {
    this.gridCols.forEach((x: any) => {
      this.table?.filter('', x.field, '')
    }
    )
  }

  exportExcel() {
    this.dataService.exportExcel(this.gridData,this.gridCols, "Distributor Margin")
   
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + EXCEL_EXTENSION);
  }

  onChangeDDValue(_value: any) {
    console.log(_value);
    this.sortColumns();
  }

  selectedCols: any[] = [];
  sortColumns() {
    this.gridCols = JSON.parse(JSON.stringify(this.sortSelectedColumns(this.selectedCols)));
  }

  sortSelectedColumns(columsnData: any[]) {
    return columsnData.sort(
      (a: { serial_no: number }, b: { serial_no: number }) => {
        return a.serial_no - b.serial_no;
      }
    );
  }

  sendFilterData(value: any, filter: any) {
    console.log("filter",value,filter,)
    let filterObj = {
      value,
      filter,
    };
    this.filterData.emit(filterObj);
  }

  onSelectRow(rowData: any, type: any) {
    let rowObject = { rowData, type };
    this.selectRow.emit(rowObject);
  }

  onDeleteRow(rowData: any, type: any) {
    let rowObject = { rowData, type };
    this.selectRow.emit(rowObject);
  }

  checkSelectedRow(_event: any) {
    this.sendSelectedRows.emit(this.selectedRows);
  }

  checkDateField(fieldName: string | string[]) {
    if (fieldName.includes('Date') || fieldName.includes('date')) {
      return true;
    }
    return false;
  }

  buildFilter(event: any, fieldName: any) {
    console.log(event,fieldName)
    let filterObj:any = {
      fieldName: fieldName,
      value: event,
    };
    let fFieldName = this.filters.find(
      (f: { fieldName: any }) => f.fieldName === fieldName
    );
    if (!fFieldName) {
      if (filterObj.value != '') {
        this.filters.push(filterObj);
      } else {
        this.filters = this.filters.filter(
          (f: { fieldName: any }) => f.fieldName === fieldName
        );
      }
    } else {
      if (filterObj.value != '') {
        this.filters.forEach((f1: { fieldName: any; value: any }) => {
          if (f1.fieldName === fieldName) {
            f1.value = event;
          }
        });
      } else {
        this.filters = this.filters.filter(
          (f: { fieldName: any }) => f.fieldName !== fieldName
        );
      }
    }
    this.sortGridData();
  }

  sortGridData() {
    let tempOrgGridData = JSON.parse(JSON.stringify(this.orgGridData));

    this.filters.forEach(
      (filter: { fieldName: string | number; value: moment.MomentInput }) => {
        tempOrgGridData = tempOrgGridData.filter(
          (f: { [x: string]: moment.MomentInput }) =>
            moment(f[filter.fieldName]).format('L') ===
            moment(filter.value).format('L')
        );
      }
    );
    this.gridData = JSON.parse(JSON.stringify(tempOrgGridData));
  }
}
