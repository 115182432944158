import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer,  UntypedFormGroup, FormGroupDirective } from '@angular/forms';


@Component({
  selector: 'app-rebate',
  templateUrl: './rebate.component.html',
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class RebateComponent implements OnInit {

  @Input() masterDropDownDetails: any
  @Input() editFormId: number = 0
  parentIntakeForm!: UntypedFormGroup;
  constructor(private parent: FormGroupDirective) { }

  ngOnInit(): void {
    this.parentIntakeForm = this.parent.form;
    console.log(this.parentIntakeForm.value)
  }


  valueChanged(value: any, i = 0, formName = '') {
    console.log(value)
  }

  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ];

  validateFloatKeyPress(event:any) {
    var charCode = event.which ? event.which : event.keyCode;
    var number = event.target.value.split('.');
    var number1 = event.target.value;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    //just one dot
    if (number.length > 1 && charCode == 46) {
      return false;
    }
    //get the carat position
    var caratPos = number1.length;
    console.log(caratPos);
    var dotPos = event.target.value.indexOf('.');
    if (caratPos > dotPos && dotPos > -1 && number[1].length > 0) {
      return false;
    }
    return true;
  }

}
