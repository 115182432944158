<div id="distributorMarginPage">
    <div *ngIf="showForm" class="custom-sidenav">
        <div class="row headerStyling">
            <label class="col-md-12 header-label" *ngIf="formType=='Edit'">{{'EDIT_DISTRIBUTOR_MARGIN' |
                translate}}</label>
            <label class="col-md-12 header-label" *ngIf="formType=='New'">{{'NEW_DISTRIBUTOR_MARGIN' |
                translate}}</label>
        </div>
        <div class="row headerStyling" style="padding-top:0px">
            <div class="col-md-12">
                <form id="distributorMarginForm" [formGroup]="distributorMarginForm">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row" style="align-items: inherit;">

                                <div class="col-md-6 cust-form-group">
                                    <div class="form-group">
                                        <label class="cust-form-label" for="country">{{'COUNTRY' | translate}}
                                            <span class="asterikClass">*</span>
                                        </label>

                                        <app-dynamic-form-fields formControlName="country" fieldType="dropdown"
                                            placeholder="{{'SELECT_COUNTRY'  | translate}}" optionLabel="value"
                                            width="100%" [options]="country" (blur)="valueChanged($event)">
                                        </app-dynamic-form-fields>
                                    </div>

                                </div>

                                <div class="col-md-6 cust-form-group">
                                    <div class="form-group">
                                        <label class="cust-form-label" for="distributor">{{'DISTRIBUTOR' |
                                            translate}}
                                            <span class="asterikClass">*</span>
                                        </label>

                                        <app-dynamic-form-fields formControlName="distributor" fieldType="dropdown"
                                            placeholder="{{'SELECT_DISTRIBUTOR'  | translate}}"
                                            optionLabel="distributor_name" width="100%"
                                            [options]="distributorListCountryWise" (blur)="valueChanged($event)">
                                        </app-dynamic-form-fields>

                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-md-12">

                            <div class="row " style="align-items: inherit;">
                                <div class="col-md-6 cust-form-group" formArrayName="margin"
                                    *ngFor="let data of margin?.controls;let i=index">
                                    <div class="form-group" [formGroupName]="i">
                                        <label class="cust-form-label"
                                            for="distributor">{{displayHeaders[i]?.assumptions_variable_name}}
                                            <span class="asterikClass">*</span></label>

                                        <app-dynamic-form-fields formControlName="value" fieldType="inputText"
                                            width="100%" (blur)="valueChanged($event)">
                                        </app-dynamic-form-fields>

                                    </div>


                                </div>


                            </div>
                        </div>
                    </div>

                    <div class="container-fluid mobileButtonClass paddingClassButtons">
                        <div class="row">
                            <div class="col-md-12" style="text-align: center;">
                                <button type="button"
                                    (click)="showForm = false;distributorMarginForm.reset();distributorListCountryWise=[]"
                                    class="btn cancel-btn" style="background-color: #4f4f4f;">{{'CANCEL' |
                                    translate}}</button>
                                <button type="button" class="btn submit-btn" (click)="submit()"
                                    [disabled]="distributorMarginForm.invalid">{{'SUBMIT' |
                                    translate}}</button>
                            </div>
                        </div>
                    </div>


                </form>
            </div>
        </div>





    </div>
    <div>

        <div class="custom-padding">
            <div class="row" style="align-items: center; padding: 1rem 0rem">
                <div *ngIf="!showForm" class="col-md-6 mobileButtonClass">
                    <button type="button" class="btn add-btn" (click)="addNewDistributorMargin()">{{'ADD_NEW' |
                        translate}}</button>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">

                    <p-accordion [multiple]="true" id="countryAccordion">
                        <p-accordionTab header="{{'COUNTRY'  | translate}} - {{country.value}}"
                            *ngFor="let country of country;let i=index" [selected]="true">
                            <app-dynamic-grid [showDelete]="true" [gridCols]="cols[i]||[]" [gridData]="gridData[i]"
                                [showFilter]="true" [showEdit]="true" [showColSelection]="true"
                                (selectRow)="onSelectRow($event)">
                            </app-dynamic-grid>
                        </p-accordionTab>
                    </p-accordion>


                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="line-scale"></ngx-spinner>