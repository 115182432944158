import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JWTTokenService {
  constructor(private _http: HttpClient) {}

  RetrieveJWtToken(data: any) {
    let link: string = environment.retrieveJWTToken;
    return this._http.post(link, data).pipe(map((response) => response));
  }
}
