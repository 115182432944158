import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-banner-marketing-rebates',
  templateUrl: './banner-marketing-rebates.component.html',
  styleUrls: ['./banner-marketing-rebates.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BannerMarketingRebatesComponent implements OnInit {
  cols: any;
  rebatesData: any;
  selectedRow: any;

  shopList = []
  @Input() country: any = [];
  createdBy = sessionStorage.getItem('id')
  shopRebateForm!: UntypedFormGroup
  @Input() heading: string = ''
  @Input() bannerType: string = ''
  @Input() fieldName = ''
  rebatesgrid: any;
  constructor() {
    console.log(this.bannerType);
   }

  ngOnInit(): void {
   console.log(this.bannerType)
  }

  
}
