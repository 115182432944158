import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
  selector: 'app-general-assumptions-grid',
  templateUrl: './general-assumptions-grid.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class GeneralAssumptionsGridComponent implements OnInit {
  cols: any;
  usersData: any;
  selectedRow: any;
  formType: any;
  showForm = false;
  country: any = [];
  type: any = [];
  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.generateHeaders();
    this.loadData();
    this.loadMasterData();
    this.selectedCols = JSON.parse(JSON.stringify(this.cols));
    this.orgCol = JSON.parse(JSON.stringify(this.cols.filter((x: any) => x.is_show)));
  }

  selectedCols: any[] = [];
  orgCol: any
  onChangeDDValue(_value: any) {
    this.cols = JSON.parse(JSON.stringify(this.dataService.sortSelectedColumns(this.selectedCols)));
  }


  generateHeaders() {
    this.cols = [
      {
        field: 'id',
        header: 'ID',
        is_show: false,
        serial_no: 1,
        is_editable: false,
      },
      {
        field: 'country_id',
        header: 'Country Id',
        is_show: false,
        serial_no: 2,
        is_editable: false,
      },
      {
        field: 'country_name',
        header: 'Country Name',
        is_show: true,
        serial_no: 3,
        is_editable: true,
      },
      {
        field: 'type_id',
        header: 'Type Id',
        is_show: false,
        serial_no: 4,
        is_editable: false,
      },
      {
        field: 'type_name',
        header: 'Type Name',
        is_show: true,
        serial_no: 5,
        is_editable: true,
      },
      {
        field: 'general_over_head',
        header: 'General Over Head (%)',
        is_show: true,
        serial_no: 6,
        is_editable: true,
      },
      {
        field: 'frieght_basf_sales',
        header: 'Frieght BASF Sales (%)',
        is_show: true,
        serial_no: 7,
        is_editable: true,
      },
      {
        field: 'tax_rate',
        header: 'Tax Rate (%)',
        is_show: false,
        serial_no: 8,
        is_editable: true,
      },
      {
        field: 'pv_discount_rate',
        header: 'PV Discount Rate (%)',
        is_show: true,
        serial_no: 9,
        is_editable: true,
      },
      {
        field: 'estimated_dso',
        header: 'Estimated Dso',
        is_show: true,
        serial_no: 10,
        is_editable: true,
      },
      {
        field: 'estimated_div',
        header: 'Estimated Div',
        is_show: true,
        serial_no: 11,
        is_editable: true,
      },
      {
        field: 'cost_of_capital_rate',
        header: 'Cost Of Capital Rate (%)',
        is_show: true,
        serial_no: 12,
        is_editable: true,
      },
      {
        field: 'hurdle_rate',
        header: 'Hurdle Rate (%)',
        is_show: true,
        serial_no: 13,
        is_editable: true,
      },
      {
        field: 'annual_price_assumption',
        header: 'Annual Price Increase assumption (%)',
        is_show: true,
        serial_no: 14,
        is_editable: true,
      },
      {
        field: 'artap_margin_assumption',
        header: 'ART AP Margin Assumption (%)',
        is_show: true,
        serial_no: 15,
        is_editable: true,
      },
      {
        field: 'created_by',
        header: 'Created By',
        is_show: false,
        serial_no: 16,
        is_editable: false,
      },
      {
        field: 'tax_rate',
        header: 'Tax Rate',
        is_show: true,
        serial_no: 17,
        is_editable: false,
      },
    ];
  }

  loadData() {
    this.spinner.show();
    this.dataService.RetriveGeneralAssumptionDetails().subscribe((response) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.usersData = response.response;
      }
    });
  }

  exportExcel() {
    this.dataService.exportExcel(this.usersData,this.cols,this.dataService.trnaslateFunction('EXPORT_GENERALASSUMPTIONA'))
  }

  loadMasterData() {
    this.spinner.show();
    this.dataService
      .RetrieveGeneralAssumptionMasterData()
      .subscribe((response) => {
        this.spinner.hide();
        if (!response['isError']) {
          this.country = response.response.country_collection.map(
            (item: any) => {
              return { id: item.id, value: item.name };
            }
          );
          this.type = response.response.type_collection.map((item: any) => {
            return { id: item.id, value: item.name };
          });
        }
      });
  }
  onSelectRow(rowObject: { rowData: any }) {
    let { rowData } = rowObject;
    this.selectedRow = JSON.parse(JSON.stringify(rowData));

    this.formType = 'Edit';
    this.showForm = true;
  }

  addNewGeneralAssumption() {
    this.selectedRow = {
      basfUserId: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      isAdmin: false,
      country: '',
    };
    this.formType = 'New';
    this.showForm = true;
  }

  refreshGeneralAssumptions(eventvalue: any) {
    if (eventvalue) {
      this.loadData();
    }
    this.showForm = false;
  }
}
