import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';
@Component({
  selector: 'app-display-scatter-chart',
  templateUrl: './display-scatter-chart.component.html',
  styleUrls: ['./display-scatter-chart.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DisplayScatterChartComponent implements OnInit, OnChanges {
  @Input("data") data: any = []
  @Input("ccmi") ccmi: any = []

  @Input("xAxisData") xAxisData = ''
  @Input("yAxisData") yAxisData = ''

  groupes: any = [
    { name: "ebit Acc $", color: "#665faacc" }
  ];

  xStartDomain = 0
  xStopDomain = 0
  yStartDomain = 0
  yStopDomain = 0

  private svg: any;
  private margin = { top: 10, right: 30, bottom: 30, left: 40 };
  private width = 460 - this.margin.left - this.margin.right;
  private height = 160 - this.margin.top - this.margin.bottom;

  constructor() {
    console.log("constructor")
  }

  ngOnChanges() {
    console.log("on changes")
    console.log(this.data, this.xAxisData, this.yAxisData)
    if (this.data.length) {
      this.calculateDomain()
      this.calculateRectangleRange()
      this.svg?.selectAll("*").remove();
      this.drawPlot();
    }
  }

  ngOnInit(): void {
    this.calculateDomain()
    this.calculateRectangleRange()
    this.createSvg();
    this.drawPlot();
  }

  private createSvg(): void {
    this.svg = d3
      .select("figure#scatter")
      .append("svg")
      .attr(
        "viewBox",
        `0 0 ${this.width + this.margin.left + this.margin.right} ${this
          .height +
        this.margin.top +
        this.margin.bottom}`
      )
      .style("background-color", "white")
      .attr("class", "fontClass")
      .append("g")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );
  }


  calculateDomain() {
    this.xStartDomain = Math.ceil(Math.min.apply(Math, this.data.map((o: any) => { return o[this.xAxisData] })))
    this.xStopDomain = Math.ceil(Math.max.apply(Math, this.data.map((o: any) => { return o[this.xAxisData] })))
    this.yStartDomain = Math.ceil(Math.min.apply(Math, this.data.map((o: any) => { return o[this.yAxisData] })))
    this.yStopDomain = Math.ceil(Math.max.apply(Math, this.data.map((o: any) => { return o[this.yAxisData] })))
    console.log(this.xStartDomain, this.xStopDomain, this.yStartDomain, this.yStopDomain)
    console.log(this.xStartDomain, this.xStopDomain, this.yStartDomain, this.yStopDomain)
  }

  rectangleRange = {
    xRedMin: 0,
    xRedMax: 0,
    yRedMin: 0,
    yRedMax: 0,
    xAmberMin: 0,
    xAmberMax: 0,
    yAmberMin: 0,
    yAmberMax: 0,
    xGreenMin: 0,
    xGreenMax: 0,
    yGreenMin: 0,
    yGreenMax: 0,
  }
  calculateRectangleRange() {
    // red
    let redData = this.ccmi.find((x: any) => x.color_id == 1)
    console.log(redData)
    this.rectangleRange.xRedMax = (redData.maxval) * this.xStopDomain * .01
    this.rectangleRange.xRedMin = (redData.minval) * this.xStopDomain * .01
    this.rectangleRange.yRedMax = (redData.maxval) * this.yStopDomain * .01
    this.rectangleRange.yRedMin = (redData.minval) * this.yStopDomain * .01

    // amber
    let amberData = this.ccmi.find((x: any) => x.color_id == 2)
    console.log(redData)
    this.rectangleRange.xAmberMax = (amberData.maxval) * this.xStopDomain * .01
    this.rectangleRange.xAmberMin = (amberData.minval) * this.xStopDomain * .01
    this.rectangleRange.yAmberMax = (amberData.maxval) * this.yStopDomain * .01
    this.rectangleRange.yAmberMin = (amberData.minval) * this.yStopDomain * .01

    //green
    let greenData = this.ccmi.find((x: any) => x.color_id == 3)
    console.log(greenData)
    this.rectangleRange.xGreenMax = this.xStopDomain
    this.rectangleRange.xGreenMin = (greenData.minval) * this.xStopDomain * .01
    this.rectangleRange.yGreenMax = this.yStopDomain
    this.rectangleRange.yGreenMin = (greenData.minval) * this.yStopDomain * .01

    console.log(this.rectangleRange)
  }


  private drawPlot(): void {
    let yAxisData = this.yAxisData
    let xAxisData = this.xAxisData
    // Add X axis
    const x = d3
      .scaleLinear()
      .domain([this.xStartDomain, this.xStopDomain])
      .range([0, this.width]);
    this.svg
      .append("g")
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3.axisBottom(x))
      .style("font-size", "4px");

    // Add Y axis
    const y = d3
      .scaleLinear()
      .domain([this.yStartDomain, this.yStopDomain])
      .range([this.height, 0]);
    this.svg.append("g")
      .call(d3.axisLeft(y))
      .style("font-size", "4px");

    //colors
    const domainScale: any = [];
    const rangeColor: any = [];
    this.groupes.forEach((element: any) => {
      domainScale.push(element.name);
      rangeColor.push(element.color);
    });

    const color = d3
      .scaleOrdinal()
      .domain(domainScale)
      .range(rangeColor);

    const dataLength = this.data.length

    // Add dots
    const dots = this.svg.append("g");
    dots
      .selectAll("dot")
      .data(this.data)
      .enter()
      .append("circle")
      .attr("cx", function (d: any) {
        return x(d[xAxisData]);
      })
      .attr("cy", function (d: any) {
        return y(d[yAxisData]);
      })


      .attr("r", function (d: any, i: number) {
        if (i < dataLength - 1)
          return 2;
        else
          return 4;
      })

      .style("fill", function (d: any, i: number) {
        if (i < dataLength - 1)
          return color(d[xAxisData]);
        else
          return 'orange';
      });

    //x-asix label
    this.svg.append("text")      // text label for the x axis
      .attr("x", this.width / 2)
      .attr("y", this.height + 20)
      .style("text-anchor", "middle")
      .style("font-size", "6px")
      .text(this.transformTitle(this.xAxisData));

    //y-asix label
    this.svg.append("text")      // text label for the y axis
      .attr("x", -1 * this.height / 2)
      .attr("y", -35)
      .attr("transform", "rotate(-90)")
      .style("text-anchor", "middle")
      .style("font-size", "6px")
      .text(this.transformTitle(this.yAxisData));

  }

  addBackgroundColor(x:any,y:any) {
    // red
    this.svg.append("rect")
      .attr("x", 0)
      .attr("y", y(this.rectangleRange.yRedMax))
      .attr("width", x(this.rectangleRange.xRedMax))
      .attr("height", y(0) - y(this.rectangleRange.yRedMax))
      .attr("fill", 'red')
      .attr("opacity", 0.1);

    //amber
    //horizontal
    this.svg.append("rect")
      .attr("x", 0)
      .attr("y", y(this.rectangleRange.yAmberMax))
      .attr("width", x(this.rectangleRange.xAmberMax))
      .attr("height", y(this.rectangleRange.yAmberMin) - y(this.rectangleRange.yAmberMax))
      .attr("fill", '#FFF2CC')
      .attr("opacity", 0.5);

    //vertical
    this.svg.append("rect")
      .attr("x", x(this.rectangleRange.xAmberMin))
      .attr("y", y(this.rectangleRange.yAmberMin))
      .attr("width", x(this.rectangleRange.xAmberMax) - x(this.rectangleRange.xAmberMin))
      .attr("height", y(0) - y(this.rectangleRange.yAmberMin))
      .attr("fill", '#FFF2CC')
      .attr("opacity", 0.5);

    //green
    //horizontal
    this.svg.append("rect")
      .attr("x", x(this.rectangleRange.xGreenMin))
      .attr("y", y(this.rectangleRange.yGreenMax))
      .attr("width", x(this.rectangleRange.xGreenMax) - x(this.rectangleRange.xGreenMin))
      .attr("height", y(this.rectangleRange.yGreenMin) - y(this.rectangleRange.yGreenMax))
      .attr("fill", '#BFB')
      .attr("opacity", 0.5);
  }

  transformTitle(value: any): any {
    if (!value)
      return value;

    return value.replace(/\w\S*/g, (txt: any) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
  }

}
