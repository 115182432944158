import {
  Component,
  Output,
  Input,
  OnInit,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class KpiComponent implements OnInit {
  @Input() formData: any;
  @Input() formType: any;
  @Input() data: any;
  @Output() reLoadGrid = new EventEmitter();
  amberMaxValues = 0;
  amberMinValues = 0;
  redMaxValues = 0;
  redMinValues = 0;
  kpiForm = new UntypedFormGroup({
    redMinValues: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^-?\d+\.?\d{0,2}$/),
    ]),
    redMaxValues: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^-?\d+\.?\d{0,2}$/),
    ]),
    amberMinValues: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^-?\d+\.?\d{0,2}$/),
    ]),
    amberMaxValues: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^-?\d+\.?\d{0,2}$/),
    ]),
    kpi: new UntypedFormControl('', Validators.required),
  });
  greenMaxValues = 0;

  apiRequest = {
    id: 0,
    kpi_name_id: 0,
    created_by: sessionStorage.getItem('id') ,
    kpi_mapping: [
      {
        kpi_id: 0,
        color_id: 1,
        min_val: 'string',
        max_val: 'string',
      },
      {
        kpi_id: 0,
        color_id: 2,
        min_val: 'string',
        max_val: 'string',
      },
      {
        kpi_id: 0,
        color_id: 3,
        min_val: 'string',
        max_val: 'string',
      },
    ],
  };
  country: any = [];
  kpi: any = [];
  kpiValues: any = [];
  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadKpiList();
  }
  toastrAlert() {
    this.translate.get('ENTER_MANDATORY').subscribe((res: any) => {
      this.toastr.warning(res)
    })
  }

  submitUser() {
    console.log(this.kpiForm)
    this.apiRequest.id = this.kpiValues.length > 0 ? 1 : 0;
    this.apiRequest.kpi_name_id = this.kpiForm.get('kpi')?.value;

    for (const element of this.apiRequest.kpi_mapping) {
      element.kpi_id = this.kpiForm.get('kpi')?.value;
      if (element.color_id == 1) {
        element.min_val = this.kpiForm.get('redMinValues')?.value;
        element.max_val = this.kpiForm.get('redMaxValues')?.value;
      } else if (element.color_id == 2) {
        element.min_val = this.kpiForm.get('amberMinValues')?.value;
        element.max_val = this.kpiForm.get('amberMaxValues')?.value;
      } else if (element.color_id == 3) {
        element.min_val = this.kpiForm.get('amberMaxValues')?.value;
        element.max_val = '';
      }
    }
    this.dataService.saveKpiDetails(this.apiRequest).subscribe((response: any) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.toastr.success(this.dataService.trnaslateFunction('DATA_ADDED_SUCCESSFULLY'));
        this.resetKpiValues();
      }
    });
    return true;
  }
  loadKpiList() {
    this.dataService.RetriveAllFieldDetailsForKpi().subscribe((response) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.kpi = response.response.kpi_collection;
        if(response.response.kpi_collection.length>0){
          this.kpi = response.response.kpi_collection.filter((i:any)=>i.name =='cCM%');
        }

      }
    });
  }
  findKpiValues() {
    let kpiId = this.kpiForm.value.kpi;
    this.dataService.RetrieveKpiDetails(kpiId).subscribe((response) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.kpiValues = response.response;
        if (this.kpiValues.length > 0) {
          this.setKpiValues();
        } else {
          this.kpiForm.get('redMinValues')?.reset();
          this.kpiForm.get('redMaxValues')?.reset();
          this.kpiForm.get('amberMinValues')?.reset();
          this.kpiForm.get('amberMaxValues')?.reset();
        }
      }
    });
  }
  setKpiValues() {
    let redKpiValues,
      amberKpiValues,
      greenKpiValues = [];
    redKpiValues = this.kpiValues.filter((item: any) => {
      return item.color_name == 'Red';
    })[0];
    amberKpiValues = this.kpiValues.filter((item: any) => {
      return item.color_name == 'Amber';
    })[0];

    this.kpiForm.patchValue({
      redMinValues: Number(redKpiValues['min_val']),
      redMaxValues: Number(redKpiValues['max_val']),
      amberMinValues: Number(amberKpiValues['min_val']),
      amberMaxValues: Number(amberKpiValues['max_val']),
    });
    this.redMinValues = Number(redKpiValues['min_val']);
    this.redMaxValues = Number(redKpiValues['max_val']);
    this.amberMinValues = Number(amberKpiValues['min_val']);
    this.amberMaxValues = Number(amberKpiValues['max_val']);
  }
  resetKpiValues() {
    this.kpiForm.reset();
  }

  cancel() {
    this.kpiForm.reset();
  }
}
