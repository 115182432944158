import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent  implements OnInit {
  menuItems: any;
  constructor(
    private router: Router
  ) {}

  ngOnInit(): void { 
      this.roleId = atob(sessionStorage.getItem('roleId') + '')
  }

  roleId:any=0;
  logout() {
    this.router.navigate(['/']);
  }
}
