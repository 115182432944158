import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { DataService } from 'src/app/services/dataServices/data.service';
import { JWTTokenService } from 'src/app/services/jwttoken.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isError: boolean | undefined;
  returnUrl: string | undefined;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: DataService,
    private jwtservice: JWTTokenService,
    private authService: AuthServiceService,
    private spinner: NgxSpinnerService
  ) { }
  usertoken: any;
  notAuthorized = false;
  isloading = true;
  userData: any = {
    id: 0,
    basf_user_id: '',

    email: '',

    country: [{ id: 0, userId: 0, countryId: 0 }],
    role: [{ id: 0, userId: 0, roleId: 0 }],
  }
 
  _jwtToken: any;


  ngOnInit(): void {
    this.spinner.show();
    this.route.queryParams.subscribe((params) => {
       
      console.log(params, params['returnUrl'])
      if (params['returnUrl'] != undefined) {
        sessionStorage.setItem('returnUrl', params['returnUrl']);
      }
      let user = {
        id: params['EncuserId'] ? atob(params['EncuserId']) : '',
        basf_user_id: params['EncUserBasfId'] ? atob(params['EncUserBasfId']) : '',
        email: params['Email'] ? atob(params['Email']) : '',
        country: [{ id: 0, countryId: 0 }],
        role: [{ id: 0, roleId: 0 }],
      };
      console.log(user, "from params");
      if (user.basf_user_id == undefined || user.basf_user_id == '') {
        const isLoggedIn = sessionStorage.getItem('is_logged_in');
        console.log("isLoggedIn", isLoggedIn)
        if (isLoggedIn == 'true')
          // this.redirectToPage();
          // Else Showing Login page with button to login
          this.spinner.hide();
      } else {
        this.service.RetrieveJWtToken(user).subscribe({
          next: (result: any) => {
            console.log(result);
            if (result.token == "UnAuthorizedRegion") {
              this.spinner.hide();
              this.router.navigate(['/unauthorized']);
              return;
            }
            sessionStorage.setItem('TokenInfo', result.token);
            this.service.getLoggedinUserData(user.basf_user_id).subscribe({

              next: (result: any) => {
                console.log(result, "user Details after login");

                this.userData = result.response;

                this.authService.updateLoginUser(this.userData);
                this.service.sendUserData(this.userData);
                this.loadOpportunitySummary(this.userData)
                this.redirectToPage();
              },
              error: (error: any) => {
                console.log(error);
                this.isloading = false;
              },
            });
          },
          error: (error: any) => {
            this.isError = true;
            console.error(error);
          },
        });
      }
    });
  }
  loadOpportunitySummary(user:any){
    this.service.RetriveOpportunityData(user.basf_user_id).subscribe({

      next: (result: any) => {
        console.log(result, "opportunitities loaded successfully");

      
      },
      error: (error: any) => {
        console.log(error);

      },
    });
  }
  SAMLService() {
    this.spinner.show();
    this.service.getSAMLService().subscribe({
      next: (result: any) => {
        console.log(result);
        this.spinner.hide();
        window.location.href = result.toString();
      },
      error: (error: any) => {
        console.log('error ', error);
        this.spinner.hide();
      },
    });
  }

  redirectToPage() {
    this.spinner.show();
    console.log(sessionStorage.getItem('returnUrl'))
    if (sessionStorage.getItem('returnUrl') !== null)
      this.returnUrl = sessionStorage.getItem('returnUrl') as string;
    if (this.returnUrl == undefined || this.returnUrl == '') {
      this.router.navigate(['/home']);
    }
    else {
      console.log(this.returnUrl)
      sessionStorage.removeItem('returnUrl');
      this.returnUrl = decodeURIComponent(this.returnUrl);
      this.router.navigateByUrl(this.returnUrl);
    }
    this.spinner.hide();
  }
}
