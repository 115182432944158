<div id="defaultValueForm" style="padding:0px">
  <form [formGroup]="tagsForm" (ngSubmit)="onSubmit()">
    <div class="row" formArrayName="tags">
      <div *ngFor="let tag of tags.controls; let i = index">
        <div [formGroupName]="i" class="row">

          <div class="col-md-6 paddingDefault">
            <label for="form" class="w-20 col-form-label cust-form-label">{{'COUNTRY' | translate}} <sup><span
                  style="color: red">*</span></sup>
            </label>

            <p-dropdown [required]="true" [options]="country" formControlName="country" [editable]="false"
             optionLabel="value" optionValue="id" placeholder="{{'SELECT_COUNTRY' | translate}}"
               [style]="{ width: '100%' }" [disabled]="true" ></p-dropdown>
          </div>


          <div class="col-md-6 paddingDefault">
            <label for="form" class="w-20 col-form-label cust-form-label">{{'FORMS' | translate}} <sup><span
                  style="color: red">*</span></sup>
            </label>

            <p-dropdown [required]="true" [options]="data.forms" formControlName="form" [editable]="false"
              [disabled]="true" optionLabel="name" optionValue="id" placeholder="{{'SELECT_FORM' | translate}}"
              id="dropdown_UserForm" [style]="{ width: '100%' }" (onChange)="loadFields(i)"></p-dropdown>
          </div>
          <div class="col-md-6 paddingDefault">
            <label for="fields" class="w-20 col-form-label cust-form-label">{{'FIELDS' | translate}} <sup><span
                  style="color: red">*</span></sup>
            </label>

            <p-dropdown [required]="true" [options]="fields" formControlName="field" [editable]="false"
              optionLabel="name" optionValue="id" id="dropdown_UserField" [style]="{ width: '100%'  }" [disabled]="true"
              placeholder="{{'SELECT_FIELDS' | translate}} " (onChange)="fetchValue($event)"></p-dropdown>
          </div>
          <div class="col-md-6 paddingDefault">
            <label for="area" class="w-20 col-form-label cust-form-label">{{'VALUE' | translate}}<sup><span
                  style="color: red">*</span></sup></label>
            <input *ngIf="!dropdownShow" required="true" type="text" pInputText class="primeng-input"
              formControlName="value" placeholder="" (change)="allowPercentage()" />

            <p-dropdown *ngIf="dropdownShow" [required]="true" [options]="fieldsDropdown" formControlName="value"
              [editable]="false" optionLabel="name" optionValue="id" id="dropdown_UserField"
              [style]="{ width: '100%'  }" placeholder="{{'SELECT_VALUE' | translate}} "></p-dropdown>
          </div>
          <div class="col-md-6 paddingDefault" *ngIf="formType == 'New'">
            <button (click)="addTag()" type="button" style="margin-top: 17%" class="btn btn-outline-primary btn-sm">
              <em class="pi pi-plus"></em>
            </button>
            <button (click)="minusTag(i)" type="button" style="margin-top: 17%" class="btn btn-outline-primary btn-sm">
              <em class="pi pi-minus"></em>
            </button>
          </div>

          <div class="container-fluid mobileButtonClass paddingClassButtons">
            <div class="row">
              <div class="col-md-12" style="text-align: center;">
                <button type="button" (click)="cancel()" class="btn cancel-btn"
                  style="background-color: #4f4f4f;">{{'CANCEL' | translate}}</button>
                <button type="button" class="btn submit-btn" [disabled]="tagsForm.invalid == true"
                  (click)="tagsForm.invalid ? toastrAlert() : ''" type="submit">
                  {{'SUBMIT' |
                  translate}}</button>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>

  </form>
</div>