import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormGroup, FormGroupDirective } from '@angular/forms';


@Component({
  selector: 'app-existing',
  templateUrl: './existing.component.html',
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class ExistingComponent implements OnInit {

  @Input() masterDropDownDetails: any
  @Input() editFormId: number = 0
  parentIntakeForm!: UntypedFormGroup;
  constructor(private parent: FormGroupDirective) { }

  ngOnInit(): void {
    this.parentIntakeForm = this.parent.form;
    console.log(this.parentIntakeForm.value)
  }


  valueChanged(value: any, i = 0, formName = '') {
    if (formName == 'actual') {
      this.parentIntakeForm.patchValue({
        'existing': {
          'actualMprFulfilled': this.parentIntakeForm.value.existing.actual
        }
      })
    }
    console.log(this.parentIntakeForm.value,value)

  }

}
