<div>
    <div *ngIf="showForm" class="custom-sidenav">
        <div class="row headerStyling">
            <label class="col-md-12 header-label" *ngIf="formType=='Edit'">{{'EDIT_DISTRIBUTOR' | translate}}</label>
            <label class="col-md-12 header-label" *ngIf="formType=='New'">{{'NEW_DISTRIBUTOR' | translate}}</label>
        </div>
        <div class="row headerStyling" style="padding-top:0px">
            <div class="col-md-12">
                <form id="distributorForm" [formGroup]="distributorForm">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row" style="align-items: inherit;">

                                <div class="col-md-6 cust-form-group">
                                    <div class="form-group">
                                        <label class="cust-form-label" for="country">{{'COUNTRY' | translate}}
                                            <span class="asterikClass">*</span>
                                        </label>
                                        <app-dynamic-form-fields formControlName="country" fieldType="dropdown"
                                            placeholder="{{'SELECT_COUNTRY' | translate}}" optionLabel="value"
                                            width="100%" [options]="country" (blur)="valueChanged($event)">
                                        </app-dynamic-form-fields>
                                    </div>

                                </div>

                                <div class="col-md-6 cust-form-group">
                                    <div class="form-group">
                                        <label class="cust-form-label" for="distributor">
                                            {{'DISTRIBUTOR' | translate}}
                                            <span class="asterikClass">*</span></label>

                                        <app-dynamic-form-fields formControlName="distributor" fieldType="inputText"
                                            placeholder="{{'ENTER_DISTRIBUTOR' | translate}}" width="100%"
                                            (blur)="valueChanged($event)">
                                        </app-dynamic-form-fields>

                                    </div>
                                </div>

                                <div class="col-md-6 cust-form-group"
                                    *ngIf="distributorForm.get('country')?.value?.id==2">
                                    <div class="form-group">
                                        <label class="cust-form-label" for="distributor">{{'SUB_JOBBERS' |
                                            translate}}<span class="asterikClass">*</span></label>


                                        <app-dynamic-form-fields formControlName="subJobber" fieldType="inputText"
                                            placeholder="{{'COMMA_SEPERATED_VALUES' | translate}}" width="100%"
                                            (blur)="valueChanged($event)">
                                        </app-dynamic-form-fields>

                                    </div>

                                </div>

                                <div class="col-md-6 cust-form-group">
                                    <div class="form-group">
                                    </div>
                                </div>

                                <div class="container-fluid mobileButtonClass paddingClassButtons">
                                    <div class="row">
                                        <div class="col-md-12" style="text-align: center;">
                                            <button type="button" (click)="showForm = false;" class="btn cancel-btn"
                                                style="background-color: #4f4f4f;">{{'CANCEL' |
                                                translate}}</button>
                                            <button type="button" class="btn submit-btn" (click)="submitDistributor()"
                                                [disabled]="distributorForm.invalid">{{'SUBMIT' |
                                                translate}}</button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
    <div>

        <div class="custom-padding">
            <div class="row" style="align-items: center; padding: 1rem 0rem">
                <div *ngIf="!showForm" class="col-md-6 mobileButtonClass">
                    <button type="button" class="btn add-btn" (click)="addNewDistributor()">{{'ADD_NEW' |
                        translate}}</button>
                    <button type="button" (click)="exportExcel()" class="btn excelButton">
                        <em class="pi pi-file-excel"></em>{{'EXPORT_AS_EXCEL' | translate}} </button>
                </div>

                <div class="col-md-6" style="text-align: end;">
                    <p-multiSelect  [options]="orgCol" [(ngModel)]="selectedCols" name="colsDD3"
                        ngDefaultControl id="showGridClass" (onChange)="onChangeDDValue($any($event).value)" optionLabel="header"
                        defaultLabel="{{'SHOW_ITEMS_IN_GRID' | translate}}" [displaySelectedLabel]="false">
                    </p-multiSelect>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <app-dynamic-grid [gridCols]="cols" [showDelete]="true" [gridData]="distributorData"
                        [showFilter]="true" [showEdit]="true" (selectRow)="onSelectRow($event)">
                    </app-dynamic-grid>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="line-scale"></ngx-spinner>