<div class="row min-height">
  <div class="col-md-12">
    <form id="kpiForm" [formGroup]="kpiForm" class="kpiStyling">
      <div class="row">
        <div class="col-md-12">
          <div class="row" style="align-items: inherit">
            <div class="col-md-3 cust-form-label">
              <label class="cust-form-label" for="basfUserName">{{'KPI' | translate}}</label>
            </div>
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <p-dropdown [options]="kpi" formControlName="kpi" optionLabel="name" optionValue="id"
                  class="form-control-sm" id="dropdown_Kpi" (onChange)="findKpiValues()"
                  placeholder="{{'SELECT_KPI' | translate}} "></p-dropdown>
              </div>
              <div *ngIf="
                  kpiForm.get('kpi')?.invalid && kpiForm.get('kpi')?.touched
                " class="text-danger">
                <div *ngIf="kpiForm.get('kpi')?.hasError('required')">
                  {{'KPI_REQUIRED' | translate}}
                </div>
              </div>
            </div>
            <div class="col-md-3"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row" style="align-items: inherit">
            <div class="col-md-3 cust-form-label">
              <label class="cust-form-label" for="basfUserName">{{'RED' | translate}}</label>
            </div>
            <div class="col-md-3 cust-form-group">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-1">
                    {{ ">" }}
                  </div>

                  <div class="col-md-11">
                    <input type="number" class="form-control form-control-sm" formControlName="redMinValues"
                      [(ngModel)]="redMinValues" [max]="redMaxValues - 1" />
                  </div>
                </div>
              </div>
              <div *ngIf="
                  kpiForm.get('redMinValues')?.invalid &&
                  kpiForm.get('redMinValues')?.touched
                " class="text-danger">
                <div *ngIf="kpiForm.get('redMinValues')?.hasError('required')">
                  {{'VALUE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
            <div class="col-md-3 cust-form-group">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-1">
                    {{ "<=" }} </div>

                      <div class="col-md-11">
                        <input type="number" class="form-control form-control-sm" formControlName="redMaxValues"
                          [(ngModel)]="redMaxValues" [min]="redMinValues + 1" [max]="amberMinValues" />
                      </div>
                  </div>
                </div>
                <div *ngIf="
                  kpiForm.get('redMaxValues')?.invalid &&
                  kpiForm.get('redMaxValues')?.touched
                " class="text-danger">
                  <div *ngIf="kpiForm.get('redMaxValues')?.hasError('required')">
                    {{'VALUE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="row" style="align-items: inherit">
              <div class="col-md-3 cust-form-label">
                <label class="cust-form-label" for="basfUserName">{{'AMBER' | translate}}</label>
              </div>
              <div class="col-md-3 cust-form-group">
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-1">
                      {{ ">" }}
                    </div>

                    <div class="col-md-11">
                      <input type="number" class="form-control form-control-sm" formControlName="amberMinValues"
                        [(ngModel)]="amberMinValues" [min]="redMaxValues" [max]="redMaxValues" />
                    </div>
                  </div>
                </div>
                <div *ngIf="
                  kpiForm.get('amberMinValues')?.invalid &&
                  kpiForm.get('amberMinValues')?.touched
                " class="text-danger">
                  <div *ngIf="kpiForm.get('amberMinValues')?.hasError('required')">
                    {{'VALUE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
              <div class="col-md-3 cust-form-group">
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-1">
                      {{ "<=" }} </div>

                        <div class="col-md-11">
                          <input type="number" class="form-control form-control-sm" formControlName="amberMaxValues"
                            [(ngModel)]="amberMaxValues" [min]="amberMinValues + 1" />
                        </div>
                    </div>
                  </div>
                  <div *ngIf="
                  kpiForm.get('amberMaxValues')?.invalid &&
                  kpiForm.get('amberMaxValues')?.touched
                " class="text-danger">
                    <div *ngIf="kpiForm.get('amberMaxValues')?.hasError('required')">
                      {{'VALUE_REQUIRED' | translate}}
                    </div>
                  </div>
                </div>
                <div class="col-md-3"></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="row" style="align-items: inherit">
                <div class="col-md-3 cust-form-label" >
                  <label class="cust-form-label" for="basfUserName">{{'GREEN' | translate}}</label>
                </div>
                <div class="col-md-3 cust-form-group" >
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-1 greenStyling" >
                        {{ ">" }}
                      </div>
                      <div class="col-md-11 amberStyling" style="font-size: 12px;">
                        {{ amberMaxValues }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid mobileButtonClass paddingClassButtons">
            <div class="row">
              <div class="col-md-12" style="text-align: center;">
                <button type="button" (click)="cancel()" class="btn cancel-btn"
                  style="background-color: #4f4f4f;">{{'CANCEL' | translate}}</button>
                <button type="button" class="btn submit-btn" (click)="submitUser()" [disabled]="kpiForm.invalid"
                  (click)="kpiForm.invalid ? toastrAlert() : ''">
                  {{'SUBMIT' |
                  translate}}</button>
              </div>
            </div>
          </div>


    </form>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="line-scale"></ngx-spinner>