<div class="row">
  <div class="col-md-12">
    <form id="paintForm" [formGroup]="paintForm">
      <div class="row headerStyling" style="padding-top: 0px;">
        <div class="col-md-4"></div>
        <div class="col-md-12">
          <div class="row center-align" style="align-items: inherit">
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="country">{{'COUNTRY' | translate}}<span
                    class="hashcol ng-star-inserted">*</span></label>
                <p-dropdown [options]="country" placeholder="{{'SELECT_COUNTRY' | translate}}" formControlName="country"
                  optionLabel="value" optionValue="value" class="form-control-sm" id="multiSelect_UserRole"
                  (onChange)="getLineDetailsOnChange()"></p-dropdown>
              </div>
              <div *ngIf="
                  paintForm.get('country')?.invalid &&
                  paintForm.get('country')?.touched
                " class="text-danger">
                <div *ngIf="paintForm.get('country')?.hasError('required')">
                  {{'COUNTRY_REQUIRED' | translate}}
                </div>
              </div>
            </div>
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="type">{{'TYPE' | translate}} <span
                    class="hashcol ng-star-inserted">*</span></label>
                <p-dropdown [options]="type" placeholder="{{'SELECT_TYPE' | translate}}" formControlName="type"
                  optionLabel="value" optionValue="value" class="form-control-sm" id="multiSelect_UserRole"
                  (onChange)="getLineDetailsOnChange()"></p-dropdown>
              </div>
              <div *ngIf="
                  paintForm.get('type')?.invalid &&
                  paintForm.get('type')?.touched
                " class="text-danger">
                <div *ngIf="paintForm.get('type')?.hasError('required')">
                  {{'TYPE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="row center-align" style="align-items: inherit">
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="type">{{'LINE' | translate}} <span
                    class="hashcol ng-star-inserted">*</span></label>
                <p-dropdown [options]="line" placeholder="{{'SELECT_LINE' | translate}}" formControlName="line"
                  optionLabel="value" optionValue="value" class="form-control-sm" id="multiSelect_UserRole"
                  (onChange)="getLineDetailsOnChange()"></p-dropdown>
              </div>
              <div *ngIf="
                  paintForm.get('line')?.invalid &&
                  paintForm.get('line')?.touched
                " class="text-danger">
                <div *ngIf="paintForm.get('line')?.hasError('required')">
                  {{'LINE_REQUIRED' | translate}}
                </div>
              </div>
            </div>
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="country">{{'GM' | translate}} <span
                    class="hashcol ng-star-inserted">*</span></label>
                <input class="inputTextStyling" type="number" style="width: 100%" pInput formControlName="gm" />
              </div>
            </div>
          </div>

          <div class="row center-align" style="align-items: inherit">
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="country">{{'CM' | translate}} <span
                    class="hashcol ng-star-inserted">*</span></label>
                <input class="inputTextStyling" type="number" style="width: 100%" pInput formControlName="cm" />
              </div>
            </div>
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="country">{{'DIST_MARGIN' | translate}}<span
                    class="hashcol ng-star-inserted">*</span></label>
                <input class="inputTextStyling" type="number" style="width: 100%" pInput
                  formControlName="dist_margin_on_ref_sales" />
              </div>
            </div>
          </div>

          <div class="row center-align" style="align-items: inherit">
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="country">{{'EQUIPMENT_REDUCTION_FEE' | translate}}
                  Toners<span class="hashcol ng-star-inserted">*</span></label>
                <input class="inputTextStyling" type="number" style="width: 100%" pInput
                  formControlName="equipment_reduction_free_toners" />
              </div>
            </div>
            <div class="col-md-6 cust-form-group"></div>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>

      <div class="container-fluid mobileButtonClass paddingClassButtons">
        <div class="row">
          <div class="col-md-12" style="text-align: center;">
            <button type="button" (click)="cancel()" class="btn cancel-btn"
              style="background-color: #4f4f4f;">{{'CANCEL' | translate}}</button>
            <button type="button" class="btn submit-btn" (click)="submitForm()" [disabled]="paintForm.invalid"
              (click)="paintForm.invalid ? toastrAlert() : ''">{{'SUBMIT' |
              translate}}</button>
          </div>
        </div>
      </div>


    </form>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="line-scale"></ngx-spinner>