import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class BannerValidator {
    static validFieldMatch(
        controlNameCorporate: string,
        controlNameShop: string,
        controlNameCcg: string,
        controlNameRebates: string,
        controlNameMarketing: string
    ): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const controlValueCorporate: unknown | null = control.get(controlNameCorporate)?.value[0]?.amount?.rebateID ||
                control.get(controlNameCorporate)?.value[1]?.amount?.rebateID ||
                control.get(controlNameCorporate)?.value[2]?.amount?.rebateID ||
                control.get(controlNameCorporate)?.value[3]?.amount?.rebateID;
            const controlValueShop: unknown | null = control.get(controlNameShop)?.value[0]?.amount?.rebateID ||
                control.get(controlNameShop)?.value[1]?.amount?.rebateID ||
                control.get(controlNameShop)?.value[2]?.amount?.rebateID;
            const controlValueCCG: unknown | null = control.get(controlNameCcg)?.value[0]?.amount?.rebateID ||
                control.get(controlNameCcg)?.value[1]?.amount?.rebateID ||
                control.get(controlNameCcg)?.value[2]?.amount?.rebateID;
            const controlValueRebates: unknown | null = control.get(controlNameRebates)?.value;

            const controlValueMarketing: unknown | null = control.get(controlNameMarketing)?.value?.id;


            if (controlValueRebates == 'Yes') {
                if (!controlValueCorporate && !controlValueShop && !controlValueCCG) {
                    control.get(controlNameCorporate)?.setErrors({
                        required: '',
                    });
                }
                else {
                    control.get(controlNameCorporate)?.setErrors(null);
                }
                if (!controlValueMarketing) {
                    control.get(controlNameMarketing)?.setErrors({
                        required: '',
                    });
                }
                else {
                    control.get(controlNameMarketing)?.setErrors(null);
                }
            }
            else {
                control.get(controlNameCorporate)?.setErrors(null);
                control.get(controlNameMarketing)?.setErrors(null);
            }
            return null;
        }
    }
}