import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { AuthServiceService } from './services/auth-service.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})


export class AppComponent {
  userAccess = false;
  cities: string[] = [];
  selectedCity: string = '';
  constructor(public router: Router, private translate: TranslateService, public authService: AuthServiceService) {
    this.translate.setDefaultLang('en');

    let lang = this.translate.getBrowserLang();
    lang = lang?.match(/en|fr|es/) ? lang : 'en';
    lang = sessionStorage.getItem('lang') ?? lang;
    this.translate.use(lang);

    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.userAccess = false;
        if (
          event.url == '/' ||
          event.url == '/login-test' ||
          event.url == '/login' ||
          event.url == '/logout' ||
          event.url == '/registration' ||
          event.url == '/saml' ||
          event.url == '/error'
        ) {
          this.userAccess = false;
        } else {
          if (this.authService.isLoggedIn())
            this.userAccess = true;
          else
            this.userAccess = false
        }
      });
  }
}
