import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
  selector: 'app-default-form-grid',
  templateUrl: './default-form-grid.component.html',
  styleUrls: ['./default-form-grid.component.css'],
})
export class DefaultFormGridComponent implements OnInit {
  cols: any;
  formData: any;
  selectedRow: any;
  formType: any;
  showForm = false;
  forms: any;

  @Input() country: any;
  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.generateHeaders();
    this.loadData();
    this.loadForms();
    this.selectedCols = JSON.parse(JSON.stringify(this.cols));
    this.orgCol = JSON.parse(JSON.stringify(this.cols.filter((x: any) => x.is_show)));
  }

  selectedCols: any[] = [];
  orgCol: any
  onChangeDDValue(_value: any) {
    this.cols = JSON.parse(JSON.stringify(this.dataService.sortSelectedColumns(this.selectedCols)));
  }

  exportExcel() {
    this.dataService.exportExcel(this.formData,this.cols,this.dataService.trnaslateFunction('EXPORT_DEFAULT_FORMS'))
  }

  generateHeaders() {
    this.cols = [
      {
        field: 'country_name',
        header: 'Country',
        is_show: true,
        is_editable: true,
        serial_no:1
      },
      {
        field: 'id',
        header: 'Id',
        is_show: false,
        //id
        serial_no:2,
        is_editable: true,
      },
      {
        field: 'formsname',
        header: 'Form Name',
        is_show: true,
        //form name
        serial_no:3,
        is_editable: true,
      },
      {
        field: 'forms_id',
        header: 'Form Id',
        is_show: false,
        //form id
        serial_no:4,
        is_editable: true,
      },
      {
        field: 'defaultvalue',
        header: 'Default Value',
        is_show: false,
        //default value
        serial_no:5,
        is_editable: true,
      },
      {
        field: 'field_value',
        header: 'Default Value',
        is_show: true,
        //default value
        serial_no:5,
        is_editable: true,
      },
      {
        field: 'fields_id',
        header: 'Fields Id',
        is_show: false,
        //fields id
        is_editable: true,
      },
      {
        field: 'fieldsname',
        header: 'Field Name',
        is_show: true,
        //field name
        serial_no:6,
        is_editable: true,
      },
      {
        field: 'country_id',
        header: 'Country Id',
        is_show: false,
        serial_no:7,
        is_editable: true,
      },
      
    ];
  }

  loadData() {
    this.spinner.show();
    this.dataService.RetriveAllFormGrid().subscribe((response) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.formData = response.response;
      }
    });
  }

  loadForms() {
    this.spinner.show();
    this.dataService.RetriveFormDetails().subscribe((response) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.forms = response.response;
      }
    });
  }

  onSelectRow(rowObject: { rowData: any }) {
    let { rowData } = rowObject;

    this.selectedRow = JSON.parse(JSON.stringify(rowData));

    this.formType = 'Edit';
    this.showForm = true;
  }

  addNewForm() {
    this.selectedRow = {
      id: '',
      formsname: '',
      forms_id: '',
      defaultvalue: '',
      fields_id: '',
      fieldsname: '',
      country_id:''
    };
    this.formType = 'New';
    this.showForm = true;
  }

  refreshForms(eventvalue: any) {
    if (eventvalue) {
      this.loadData();
    }
    this.showForm = false;
  }
}
