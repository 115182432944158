import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
  selector: 'app-banner-shop-rebates',
  templateUrl: './banner-shop-rebates.component.html',
  styleUrls: ['./banner-shop-rebates.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BannerShopRebatesComponent implements OnInit {
  cols: any;
  rebatesData: any;
  selectedRow: any;

  shopList = []
  @Input() country: any = [];
  createdBy = sessionStorage.getItem('id')
  shopRebateForm!: UntypedFormGroup
  @Input() heading: string = ''
  @Input() bannerType: string = ''
  @Input() fieldName = ''
  rebatesgrid: any;
  headerstring: string = '';
  constructor(private fb: UntypedFormBuilder, private dataService: DataService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.generateForm();
    this.generateHeaders();
    if (this.bannerType != "Banner Marketing Rebates")
    this.getAllRebateValues(this.bannerType)
    else
    this.getAllBannerMarketingRebateValues(this.heading)
  }

  orgCols = []
  generateHeaders() {
    if(this.bannerType !='Banner Marketing Rebates'){
    this.cols = [
      {
        field: 'rebate_master_id',
        header: 'Rebate Id',
        is_show: false,
        //basg id
        serial_no: 1,
        is_editable: true,
      },
      {
        field: 'country_name',
        header: 'Country',
        is_show: true,
        //basg id
        serial_no: 2,
        is_editable: true,
      },
      {
        field: 'rebate_name',
        header: 'Rebate Name',
        is_show: true,
        //basg id
        serial_no: 3,
        is_editable: true,
      },
      {
        field: 'banner_type',
        header: 'Banner Type',
        is_show: true,
        //first anem
        serial_no: 4,
        is_editable: true,
      }
    ];
  }
  else{
    this.cols = [
      {
        field: 'rebate_master_id',
        header: 'Rebate Id',
        is_show: false,
        //basg id
        serial_no: 1,
        is_editable: true,
      },
      {
        field: 'country_name',
        header: 'Country',
        is_show: true,
        //basg id
        serial_no: 2,
        is_editable: true,
      },
      {
        field: 'rebate_name',
        header: 'Rebate Name',
        is_show: true,
        //basg id
        serial_no: 3,
        is_editable: true,
      }
      
    ];
  }
    this.orgCols = JSON.parse(JSON.stringify(this.cols))
  }

  getBannerDropdown(id: number) {
    if(this.bannerType !='Banner Marketing Rebates'){
    this.dataService.GetRebatesMaster(id, this.bannerType).subscribe((response: any) => {
      if (!response['isError']) {
        this.shopList = response.response
      }
    });
  }else{
    this.dataService.GetRebateMarketingMaster().subscribe((response: any) => {
      if (!response['isError']) {
         if(response.response.length>0)
         this.shopList = response.response.filter((i:any)=>i.id==id);
      
      }
    });
  }
  }

  getRebateValue(id: number) {
    if(this.bannerType !='Banner Marketing Rebates'){
    this.dataService.GetRebateValues(id).subscribe((response: any) => {
      if (!response['isError']) {
        let rebates = response.response
        if (rebates.length > 0) {
          rebates.forEach((element: any) => {
            this.addRebates(element.rebateValue, element.rebateID)
          });
        }
        else {
          this.addRebates()
        }

      }
    });
  }
  else{
    this.getmarketingrebate(id);
  }
  }
  getmarketingrebate(id: number){
    this.dataService.GetMarketingRebateValues().subscribe((response: any) => {
      if (!response['isError']) {
        let rebates = response.response;
        rebates=rebates.filter((i:any)=>i.rebate_master_id==id)
        if (rebates.length > 0) {
          
          rebates.forEach((element: any) => {
            this.addRebates(element.rebateValue, element.rebateID)
          });
        }
        else {
          this.addRebates()
        }
      }
    });
  }

  showNewRebate = false
  isEdit = false
  onSelectRow(rowObject: { rowData: any }) {
    this.isEdit = true
    this.showNewRebate = false
    this.shopRebateForm.reset()
    this.rebates.clear()
    let { rowData } = rowObject;
    this.selectedRow = JSON.parse(JSON.stringify(rowData));
    if(this.bannerType !='Banner Marketing Rebates'){
    this.dataService.GetRebatesMaster(this.selectedRow.country_id, this.bannerType).subscribe((response: any) => {
      if (!response['isError']) {
        this.shopList = response.response
        this.shopRebateForm.patchValue({
          country: { id: this.selectedRow.country_id, value: this.selectedRow.country_name },
          shopName: { id: this.selectedRow.rebate_master_id, name: this.selectedRow.rebate_name, country_id: 0 },
          rebates: []
        })

        this.getRebateValue(this.selectedRow.rebate_master_id);
      }
    });
  }
  else{
    this.dataService.GetRebateMarketingMaster().subscribe((response: any) => {
      if (!response['isError'] && response.response.length>0) {
        this.shopList = response.response
        this.shopList=this.shopList.filter((i:any)=>i.id==this.selectedRow.country_id)
        this.shopRebateForm.patchValue({
          country: { id: this.selectedRow.country_id, value: this.selectedRow.country_name },
          shopName: { id: this.selectedRow.rebate_master_id, name: this.selectedRow.rebate_name, country_id: this.selectedRow.country_id },
          rebates: []
        })
        this.getRebateValue(this.selectedRow.rebate_master_id);
      }
    });
  }

  }

  generateForm() {
    this.shopRebateForm = this.fb.group({
      country: new UntypedFormControl('', Validators.required),
      shopName: new UntypedFormControl('', [Validators.required,]),
      rebates: this.fb.array([])
    });


  }

  get rebates(): UntypedFormArray {
    return <UntypedFormArray>this.shopRebateForm.get('rebates');
  }

  createRebate(value: any, id: number): UntypedFormGroup {
    let tempForm = this.fb.group({
      rebateID: [id],
      rebateValue: [value, [Validators.required, Validators.pattern(/^\d+\.?\d{0,15}$/)]]
    });

    if (this.bannerType.toLowerCase().indexOf('fee') < 0) {
      tempForm.get('rebateValue')?.addValidators(Validators.max(100));
      tempForm.get('rebateValue')?.updateValueAndValidity();
    }
    return tempForm;
  }

  addRebates(value = '', id = 0) {
    if (this.shopRebateForm.valid) {
      this.rebates.push(this.createRebate(value, id))
    }
    else {
      this.dataService.trnaslateFunction('ENTER_MANDATORY')
    }
  }

  removeRebates(i: number) {
    this.rebates.removeAt(i)
  }

  valueChanged(value: any, field = '') {
    if (value?.name == 'country') {
      if (value.value?.id != undefined) {
        this.rebates.clear()
        this.shopRebateForm.get('shopName')?.reset()
        this.getBannerDropdown(value.value.id)
      }
    }
    if (value?.name == 'shopName') {
      this.rebates.clear()
      if (typeof value.value != 'string') {
        this.getRebateValue(value.value?.id || 0)
      }
      else {
        this.addRebates()
      }
    }
    if (field == 'shopNameInput') {
      this.rebates.clear()
      const duplicate = this.shopList.filter((x: any) => {
        return x.name.toLowerCase() == this.shopRebateForm.value.shopName.toLowerCase()
      })
      if (duplicate.length == 0)
        this.addRebates()
      else {
        this.toastr.error(this.dataService.trnaslateFunction('DUPLICATE'));
        return
      }
    }
  }

  getAllRebateValues(header: string) {
    this.cols = JSON.parse(JSON.stringify(this.orgCols))
    this.dataService.GetAllRebateValues(header).subscribe((response: any) => {
      if (!response['isError']) {
        let rebates = response.response
        let griddata = rebates.filter((i: any) => i.banner_type === this.bannerType)
        this.rebatesgrid = griddata;
        let rebatesList: any = []
        this.rebatesgrid.forEach((element: any) => {
          let o = Object.fromEntries(Object.entries(element).filter(([_, v]) => v != null));
          console.log(o)
          rebatesList.push(o)
        });
        console.log(rebatesList)
        let maxLength = Math.max(...rebatesList.map((el: any) => Object.keys(el).length))
        let col = []
        if (this.heading == 'One Time Fees') {
          this.headerstring = '';
        }
        else {
          this.headerstring = '%'
        }
        for (let i = 1; i <= maxLength - 5; i++) {
          col.push({ is_show: true, field: `${i}`, header: this.headerstring, serial_no: i + 4 })
        }
        this.cols.push(...col)
      }
    });
  }

  getAllBannerMarketingRebateValues(header: string) {
    this.cols = JSON.parse(JSON.stringify(this.orgCols))
    this.dataService.GetAllMarketingRebateValues().subscribe((response: any) => {
      if (!response['isError']) {
        let rebates = response.response
        let griddata = rebates //.filter((i: any) => i.banner_type === this.bannerType)
        this.rebatesgrid = griddata;
        let rebatesList: any = []
        this.rebatesgrid.forEach((element: any) => {
          let o = Object.fromEntries(Object.entries(element).filter(([_, v]) => v != null));
          rebatesList.push(o)
        });
        let maxLength = Math.max(...rebatesList.map((el: any) => Object.keys(el).length))
        let col = []
        for (let i = 1; i <= maxLength - 4; i++) {
          col.push({ is_show: true, field: `${i}`, header: '%', serial_no: i + 3 })
        }
        this.cols.push(...col)
      }
    });
  }

  addNewRebates() {
    this.showNewRebate = true;
    this.shopRebateForm.get('shopName')?.reset();
    this.rebates.clear()
  }

  convertToInteger(value: any) {
    if (value == '' || value == null || value == undefined)
      return 0
    else return parseFloat(value)
  }

  submit() {
    const duplicates = this.shopRebateForm.value.rebates.filter((obj: any, index: number) => {
      return this.shopRebateForm.value.rebates.findIndex((obj2: any) => {
        return obj.rebateValue == obj2.rebateValue && index != this.shopRebateForm.value.rebates.indexOf(obj2);
      }) != -1

    })
    if (duplicates.length) {
      this.toastr.error(this.dataService.trnaslateFunction('DUPLICATE'));
      return
    }
    let rebatesList: any = []
    let actionType = 2
    if (this.shopRebateForm.value.shopName?.id == undefined)
      actionType = 1
    this.shopRebateForm.value.rebates.forEach((element: any) => {
      let rebates =
      {
        "rebateID": element.rebateID,
        "rebateValue": this.convertToInteger(element.rebateValue)
      }
      rebatesList.push(rebates)
    });

    let payLoad = {
      "rebatesMasterID": this.shopRebateForm.value.shopName?.id || 0,
      "bannerType": this.bannerType,
      "bannerName": this.shopRebateForm.value.shopName?.name || this.shopRebateForm.value.shopName,
      "countryID": this.shopRebateForm.value.country?.id,
      "createdBy": this.createdBy,
      "rebatesValues": rebatesList
    }

    this.dataService.ManageBannerRebates(actionType, payLoad)
      .subscribe((response: any) => {
        this.spinner.hide();
        if (!response['isError']) {
          this.toastr.success(this.dataService.trnaslateFunction('DATA_ADDED_SUCCESSFULLY'));
          this.shopRebateForm.reset()
          this.rebates.clear()
          if(this.bannerType!='Banner Marketing Rebates'){
          
          this.showNewRebate = false
          this.getAllRebateValues(this.bannerType)
          }
          else{
            this.getAllBannerMarketingRebateValues(this.bannerType)
          }
        }
      });
  }
}
