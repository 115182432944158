import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthServiceService } from '../services/auth-service.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGaurd  {
  routparams: any;
  constructor(private authService: AuthServiceService, private route: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
       
      const expectedurl = route.data['expectedurl'];
      sessionStorage.setItem('expectedurl',expectedurl)
      this.routparams = route.queryParams;
    return this.checkUserLogin();
  }

  checkUserLogin(): boolean {
    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      this.route.navigate(['/unauthorized'])
      return false;
    }
  }
}
