import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-display-chart',
  templateUrl: './display-chart.component.html',
  styleUrls: ['./display-chart.component.css'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class DisplayChartComponent implements OnChanges {


  basicOptions: any;
  @Input() dataList: any
  basicData: any
  height = "35%"

  ngOnChanges(): void {
    console.log(window.screen.width)
    if (window.screen.width <= 767)
      this.height = "100%"
    console.log(this.dataList)
    this.basicData = JSON.parse(JSON.stringify(this.dataList))
    this.basicData.datasets = this.dataList?.datasets?.filter((x: any) => x.display)
    console.log(this.basicData)
  }

}
