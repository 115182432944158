<div class="min-height" id="defaultRegistration">
  <div *ngIf="showForm" class="custom-sidenav">
    <div class="row headerStyling">
      <label class="col-md-12 header-label" *ngIf="formType=='Edit'">{{'EDIT_FORM' | translate}}
      </label>
      <label class="col-md-12 header-label" *ngIf="formType=='New'">{{'NEW_FORM' | translate}}
      </label>
    </div>
    <div class="row headerStyling" style="padding-top:0px">
      <div class="col-md-12">
        <app-default-form-registration [formData]="selectedRow" [formType]="formType" [country]="country"
          [data]="{selectedRow,forms,formType}" (reLoadGrid)="refreshForms($event)">
        </app-default-form-registration>
      </div>
    </div>
  </div>
  <div>

    <div class="custom-padding">
      <div class="row" style="align-items: center; padding: 1rem 0rem">

        <div *ngIf="!showForm" class="col-md-6 mobileButtonClass">
          <button type="button" (click)="exportExcel()" class="btn excelButton">
            <em class="pi pi-file-excel"></em>{{'EXPORT_AS_EXCEL' | translate}} </button>
        </div>

        <div class="col-md-6" style="text-align: end;">
          <p-multiSelect [options]="orgCol" id="showGridClass"  [(ngModel)]="selectedCols" name="colsDD2" ngDefaultControl
            (onChange)="onChangeDDValue($any($event).value)" optionLabel="header"
            defaultLabel="{{'SHOW_ITEMS_IN_GRID' | translate}}" [displaySelectedLabel]="false">
          </p-multiSelect>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <app-dynamic-grid [gridCols]="cols" [gridData]="formData" [showFilter]="true" [showEdit]="true"
            (selectRow)="onSelectRow($event)">
          </app-dynamic-grid>
        </div>
      </div>
    </div>
  </div>

</div>