import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'titlecase1'
})
export class TitleCase implements PipeTransform {

    transform(value: any): any {
        if (!value)
            return value;

        return value.replace(/\w\S*/g, (txt:any) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        })
    }

}
