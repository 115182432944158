import { Component, Input,  OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer,  UntypedFormBuilder, UntypedFormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-additional',
  templateUrl: './additional.component.html',
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class AdditionalComponent implements OnInit {

  @Input() masterDropDownDetails: any
  @Input() editFormId: number = 0
  parentIntakeForm!: UntypedFormGroup;
  constructor(private parent: FormGroupDirective, private fb: UntypedFormBuilder) { }


  ngOnInit(): void {
    this.parentIntakeForm = this.parent.form;
    console.log(this.parentIntakeForm.value)
   
    console.log(this.parentIntakeForm.value)
  }

  valueChanged(value: any, i = 0, formName = '') {
    console.log(value, i, formName)
    console.log(this.parentIntakeForm.value)   
  }



}
