import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from './dataServices/data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceService {
  updateHeader = new BehaviorSubject({
    isAdmin: false,
    isLoggedIn: false,
  });
  isLogin: boolean = false;
  isAdmin: boolean = false;
  constructor(private service: DataService) { }

  isLoggedIn() {
    const loggedIn = sessionStorage.getItem('is_logged_in');
    if (loggedIn == 'true') this.isLogin = true;
    else this.isLogin = false;
    return this.isLogin;
  }
  isUserAdmin() {
    const isAdmin = sessionStorage.getItem('is_admin');
    if (isAdmin == 'true') this.isAdmin = true;
    else this.isAdmin = false;
    return this.isAdmin;
  }
  updateLoginUser(userObj: any) {
    this.service.getUserData().subscribe({
      next: (_data: any) => {
        sessionStorage.setItem('id', userObj.id);
        sessionStorage.setItem('basf_user_id', userObj.basf_user_id);

        const role = userObj.role.reduce((prev: any, current: any) =>
          prev.role_id > current.role_id ? prev : current, 0)
        sessionStorage.setItem('roleId', btoa(role.role_id));
        sessionStorage.setItem('country', JSON.stringify([...new Set(userObj.country)].map((obj: any) => obj.country_id)));

        if (userObj.id != 0 && userObj.id != null && userObj.id != undefined) {
          sessionStorage.setItem('is_logged_in', 'true');
          this.updateHeaderMethod();
        }
      },
    });
  }
  updateHeaderMethod() {
    this.updateHeader.next({
      isLoggedIn: this.isLoggedIn(),
      isAdmin: this.isUserAdmin(),
    });
  }
  updateToken(token: any) {
    if (token == '') {
      sessionStorage.clear();
    } else {
      sessionStorage.setItem('TokenInfo', JSON.stringify(token));
    }
  }
}
