import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
  selector: 'app-paint-registration',
  templateUrl: './paint-registration.component.html',
  styleUrls: ['./paint-registration.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PaintRegistrationComponent {
  @Input() formData: any;
  @Input() formType: any;
  @Input() data: any;
  @Output() reLoadGrid = new EventEmitter();

  paintForm = new UntypedFormGroup({
    country: new UntypedFormControl('', Validators.required),
    type: new UntypedFormControl('', Validators.required),
    line: new UntypedFormControl('', Validators.required),
    gm: new UntypedFormControl('', Validators.required),
    cm: new UntypedFormControl('', Validators.required),
    dist_margin_on_ref_sales: new UntypedFormControl('', Validators.required),
    equipment_reduction_free_toners: new UntypedFormControl('', Validators.required),
  });
  lineObj = {
    gm: 0,
    cm: 0,
    dist_margin_on_ref_sales: 0,
    equipment_reduction_free_toners: 0,
  };
  apiRequest = {
    id: 0,
    line_country_id: 0,
    line_country_name: '',
    line_type_id: 0,
    line_type_name: '',
    deal_master_id: 0,
    deal_master_name: '',
    cm: 0,
    gm: 0,
    dist_margin_on_ref_sales: 0,
    equipment_reduction_free_toners: 0,
    created_by: sessionStorage.getItem('id') ,
  };
  country: any = [];
  type: any = [];
  line: any = [];
  selectedCountry: any = [];
  selectedType: any = [];
  selectedLine: any = [];

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }
  toastrAlert() {
    this.translate.get('ENTER_MANDATORY').subscribe((res: any) => {
      this.toastr.warning(res)
    })
  }
  setDropDownValues(formDataField: any, formField: any) {
    let tBUnits = this.formData[formDataField].split(',');
    tBUnits.forEach((t: string) => {
      let fBUnit: any = [];

      if (formDataField == 'line_country_name') {
        fBUnit = this.country.find((c: any) => c.value == t.trim());
      } else if (formDataField == 'line_type_name') {
        fBUnit = this.type.find((c: any) => c.value == t.trim());
      } else {
        fBUnit = this.line.find((c: any) => c.value == t.trim());
      }

      if (fBUnit) {
        if (formDataField == 'line_country_name') {
          this.selectedCountry = fBUnit.value;
        } else if (formDataField == 'line_type_name') {
          this.selectedType = fBUnit.value;
        } else if (formDataField == 'deal_master_name') {
          this.selectedLine = fBUnit.value;
        }
      }
    });


    this.paintForm.controls[formField].setValue(
      this.generateFormControls(formDataField)
    );
  }

  generateFormControls(formDataField: any) {
    let data: any
    if (formDataField == 'line_country_name')
      data = this.selectedCountry
    else {
      if (formDataField == 'line_type_name')
        data = this.selectedType
      else
        data = this.selectedLine
    }
    return data;
  }

  ngOnChanges(): void {
    this.country = this.data.country;
    this.type = this.data.type;
    this.line = this.data.line;
    if (this.formType !== 'New') {
      this.selectedCountry = [];
      this.selectedType = [];
      this.selectedLine = [];
      this.setDropDownValues('line_country_name', 'country');
      this.setDropDownValues('line_type_name', 'type');
      this.setDropDownValues('deal_master_name', 'line');
      this.lineObj = {
        gm: this.formData['gm'],
        cm: this.formData['cm'],
        dist_margin_on_ref_sales: this.formData['dist_margin_on_ref_sales'],
        equipment_reduction_free_toners:
          this.formData['equipment_reduction_free_toners'],
      };
      this.paintForm.controls['gm'].setValue(this.formData['gm']);
      this.paintForm.controls['cm'].setValue(this.formData['cm']);
      this.paintForm.controls['dist_margin_on_ref_sales'].setValue(
        this.formData['dist_margin_on_ref_sales']
      );
      this.paintForm.controls['equipment_reduction_free_toners'].setValue(
        this.formData['equipment_reduction_free_toners']
      );
    }
  }
  getLineDetailsOnChange() {
    if (
      this.paintForm.value.line == '' ||
      this.paintForm.value.type == '' ||
      this.paintForm.value.country == ''
    )
      return;
    let selectedLine = this.line.find(
      (c: any) => c.value == this.paintForm.value.line.trim()
    );
    let selectedCountry = this.country.find(
      (c: any) => c.value == this.paintForm.value.country.trim()
    );
    let selectedType = this.type.find(
      (c: any) => c.value == this.paintForm.value.type.trim()
    );
    let apiRequest = {
      countryId: selectedCountry.id,
      typeId: selectedType.id,
      dealMasterId: selectedLine.id,
    };
    this.dataService
      .RetrivePaintLineMarginByCountry(apiRequest)
      .subscribe((response) => {
        this.spinner.hide();
        if (!response['isError']) {
          if (response.response != null) {
            this.apiRequest['id'] = response.response['id'];
            this.paintForm.controls['gm'].setValue(response.response['gm']);
            this.paintForm.controls['cm'].setValue(response.response['cm']);
            this.paintForm.controls['dist_margin_on_ref_sales'].setValue(
              response.response['dist_margin_on_ref_sales']
            );
            this.paintForm.controls['equipment_reduction_free_toners'].setValue(
              response.response['equipment_reduction_free_toners']
            );
          } else {
            this.paintForm.controls['gm'].setValue('');
            this.paintForm.controls['cm'].setValue('');
            this.paintForm.controls['dist_margin_on_ref_sales'].setValue('');
            this.paintForm.controls['equipment_reduction_free_toners'].setValue(
              ''
            );
          }
        }
      });
  }
  submitForm() {
    if (this.paintForm.invalid) {
      this.translate.get('FILL_ALL_FIELDS').subscribe((res: any) => {
        this.toastr.warning(res)
      })
      return false;
    }

    this.spinner.show();

    if (this.formType === 'New') {
      this.apiRequest['created_by'] = sessionStorage.getItem('UserId') || '1';
    } else {
      this.apiRequest['created_by'] = sessionStorage.getItem('id') || '1';
      this.apiRequest['id'] = Number(this.formData.id);
    }
    this.apiRequest['line_country_id'] = this.data.country.filter(
      (item: any) => {
        return item.value == this.paintForm.value.country;
      }
    )[0].id;
    this.apiRequest['line_country_name'] = this.data.country.filter(
      (item: any) => {
        return item.value == this.paintForm.value.country;
      }
    )[0].value;
    this.apiRequest['line_type_id'] = this.data.type.filter((item: any) => {
      return item.value == this.paintForm.value.type;
    })[0].id;

    this.apiRequest['line_type_name'] = this.data.type.filter((item: any) => {
      return item.value == this.paintForm.value.type;
    })[0].value;

    this.apiRequest['deal_master_id'] = this.data.line.filter((item: any) => {
      return item.value == this.paintForm.value.line;
    })[0].id;

    this.apiRequest['deal_master_name'] = this.data.line.filter((item: any) => {
      return item.value == this.paintForm.value.line;
    })[0].value;
    this.apiRequest['cm'] = Number(this.paintForm.value.cm);
    this.apiRequest['gm'] = Number(this.paintForm.value.gm);
    this.apiRequest['dist_margin_on_ref_sales'] = Number(
      this.paintForm.value.dist_margin_on_ref_sales
    );
    this.apiRequest['equipment_reduction_free_toners'] = Number(
      this.paintForm.value.equipment_reduction_free_toners
    );

    this.dataService
      .PaintLineMarginDetails(this.apiRequest)
      .subscribe((response: any) => {
        this.spinner.hide();
        if (!response['isError']) {
          this.toastr.success(this.dataService.trnaslateFunction('DATA_ADDED_SUCCESSFULLY'));
          this.reLoadGrid.emit(true);
        }
      });
    return true;
  }

  cancel() {
    this.reLoadGrid.emit(false);
  }
}
