<div formGroupName="jobber">
    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'JOBBER_NAME' | translate}}
                <span *ngIf="parentIntakeForm.get('jobber.name')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields (click)="warningMessage('jobber')" formControlName="name" width="100%"
                    fieldType="dropdown" placeholder="{{'SELECT_JOBBER_NAME' | translate}}" optionLabel="name"
                    [options]="jobberOptions()" (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'EQUIPMENT_PROVIDED' | translate}}
                <span *ngIf="parentIntakeForm.get('jobber.equipment')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="equipment" width="100%" startAddOn="$"
                    fieldType="inputTextGroup" placeholder="{{'ENTER_MPR' | translate}}" (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'JOBBER_UPFRONT_INVESTMENT' | translate}}
                <span *ngIf="parentIntakeForm.get('jobber.upfront')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="upfront" width="100%" startAddOn="$"
                    fieldType="inputTextGroup" placeholder="{{'ENTER_UPFRONT_INVESTMENT' | translate}}"
                    (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div *ngIf="parentIntakeForm.value.custInformation?.country?.id==2">
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'SUB_JOBBER' | translate}}
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="sub_jobber" (click)="warningMessage('sub-jobber')"
                    width="100%" fieldType="dropdown" placeholder="{{'SELECT_SUB_JOBBER' | translate}}"
                    optionLabel="distributor_subjobber_name" [options]="loadSubJobberOptions()"
                    (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>
    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'JOBBER_POS_DISCOUNT' | translate}}
                <span *ngIf="parentIntakeForm.get('jobber.discount')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="discount" width="100%" fieldType="decimalnumberslider"
                    (blur)="valueChanged($event)" (keypress)="validateFloatKeyPress($event)" >
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>
    <div *ngIf="parentIntakeForm.value.custInformation?.country?.id==2">
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'DISTRIBUTOR_PROMPT_PAY' | translate}}
                <span *ngIf="parentIntakeForm.get('jobber.distributorToShop')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 ">
                <app-dynamic-form-fields formControlName="distributorToShop" width="100%" startAddOn="$"
                    fieldType="inputTextGroup" placeholder="{{'ENTER_DISTRIBUTOR_PROMPT_PAY' | translate}}"
                    (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div *ngIf="parentIntakeForm.value.custInformation?.country?.id==2">
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'DISTRIBUTOR_TO_SUBJOBBER' | translate}}
                <span *ngIf="parentIntakeForm.get('jobber.distributorToSubJobber')|hasRequiredField"
                    class="hashcol">*</span>
            </div>
            <div class="col-md-4 ">
                <app-dynamic-form-fields formControlName="distributorToSubJobber" width="100%" startAddOn="$"
                    fieldType="inputTextGroup" placeholder="{{'ENTER_DISTRIBUTOR_TO_SUBJOBBER' | translate}}"
                    (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>






</div>