<div class="row" id="displayAccordion" style="padding:20px 0px">
    <div class="col-md-12">
        <p-accordion [multiple]="true">

            <p-accordionTab  header="{{'EXISTING_DEAL' | translate}}" *ngIf="status?.id==1" formGroupName="existing">
                <app-existing [masterDropDownDetails]="masterDropDownDetails" [editFormId]="editFormId"></app-existing>
            </p-accordionTab>


            <p-accordionTab header="{{'GENERAL' | translate}}">
                <app-general [masterDropDownDetails]="masterDropDownDetails" [editFormId]="editFormId"
                [subJobberOptions]="subJobberOptions"  [yearValueChanged]="yearValueChanged" 
                [contractValueChanged]="contractValueChanged"
                [defaultValue]="defaultValue"
                ></app-general>

            </p-accordionTab>
            <p-accordionTab header="{{'JOBBER' | translate}}">
                <app-jobber [subJobberOptions]="subJobberOptions" [masterDropDownDetails]="masterDropDownDetails"
                    [editFormId]="editFormId"></app-jobber>
            </p-accordionTab>

            <p-accordionTab header="{{'REBATE' | translate}}">
                <app-rebate [masterDropDownDetails]="masterDropDownDetails" [editFormId]="editFormId"></app-rebate>
            </p-accordionTab>
           

            <p-accordionTab header="{{'BANNER' | translate}}">
                <app-banner [masterDropDownDetails]="masterDropDownDetails" [editFormId]="editFormId"></app-banner>

            </p-accordionTab>
           
            <p-accordionTab header="{{'ADDITIONAL' | translate}}" >
                <app-additional [masterDropDownDetails]="masterDropDownDetails" [editFormId]="editFormId"
               >
                </app-additional>
            </p-accordionTab> 
           
        </p-accordion>
    </div>
</div>