<div class="row">
    <div class="col-md-12">

        <div id="dealCrafterForm" formGroupName="custInformation" style="padding: 20px;background-color: white;">
            <div class="row" style="font-style: italic;display: inline;font-size: 12px;">
                <div class="hashcol" style="display: inline;vertical-align: sub;padding-right:8px">*</div>{{'FIELDS_ARE_MANDATORY' |
                translate}}

            </div>
            <div class="row" style="margin-top:10px;align-items: start;">
                <div class="col-md-3 cust-form-group">
                    <div class="form-group">
                        <label class="cust-form-label">{{'OPPORTUNITY' | translate}}
                            <span *ngIf="parentIntakeForm.get('custInformation.opportunity')|hasRequiredField"
                                class="hashcol">*</span></label>
                        <app-dynamic-form-fields formControlName="opportunity" fieldType="dropdown"
                            placeholder="{{'SELECT_OPPORTUNITY' | translate}}" optionLabel="name" width="100%"
                            [options]="opportunity" (blur)="valueChanged($event)">
                        </app-dynamic-form-fields>
                    </div>
                </div>

                <div class="col-md-3 cust-form-group">
                    <div class="form-group">
                        <label class="cust-form-label">{{'COUNTRY' | translate}}
                            <span *ngIf="parentIntakeForm.get('custInformation.country')|hasRequiredField"
                                class="hashcol">*</span></label>
                        <app-dynamic-form-fields formControlName="country" fieldType="dropdown"
                            placeholder="{{'SELECT_COUNTRY' | translate}}" optionLabel="name" width="100%"
                            [options]="country" (blur)="valueChanged($event)">
                        </app-dynamic-form-fields>
                    </div>
                </div>

                <div class="col-md-3 cust-form-group">
                    <div class="form-group">
                        <label class="cust-form-label">{{'ACCOUNT_STATUS' | translate}}
                            <span *ngIf="parentIntakeForm.get('custInformation.accountStatus')|hasRequiredField"
                                class="hashcol">*</span></label>
                        <app-dynamic-form-fields formControlName="accountStatus" fieldType="dropdown"
                            placeholder="{{'SELECT_ACCOUNT_STATUS' | translate}}" optionLabel="name" width="100%"
                            [options]="masterDropDownDetails?.deal_active_status" (blur)="valueChanged($event)">
                        </app-dynamic-form-fields>
                    </div>
                </div>

                <div class="col-md-3 cust-form-group">
                    <div class="form-group">
                        <label class="cust-form-label">{{'CUSTOMER_INFORMATION' | translate}}
                            <span *ngIf="parentIntakeForm.get('custInformation.customerInformation')|hasRequiredField"
                                class="hashcol">*</span></label>
                        <app-dynamic-form-fields formControlName="customerInformation" fieldType="inputText"
                            placeholder="{{'ENTER_CUSTOMER_INFORMATION' | translate}}" width="100%"
                            (blur)="valueChanged($event)">
                        </app-dynamic-form-fields>
                    </div>
                </div>

                <div class="col-md-3 cust-form-group"
                    *ngIf="parentIntakeForm.value.custInformation.accountStatus.id==1">
                    <div class="form-group">
                        <label class="cust-form-label">{{'REF_ID' | translate}}
                            <span *ngIf="parentIntakeForm.get('custInformation.refId')|hasRequiredField"
                                class="hashcol">*</span></label>
                        <app-dynamic-form-fields formControlName="refId" fieldType="inputText"
                            placeholder="{{'ENTER_REF_ID' | translate}}" width="100%" (blur)="valueChanged($event,'ref_id')">
                        </app-dynamic-form-fields>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>