<div formGroupName="banner">
    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'BANNER_REBATES' | translate}}
                <span *ngIf="parentIntakeForm.get('banner.bannerRebates')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="bannerRebates" width="60%" fieldType="switch"
                    radioName="bannerRebates" radioValue1="Yes" radioValue2="No" (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
    </div>

    <!-- divider accordion -->
    <div class="divider" [ngClass]="{'dividerAccordion':parentIntakeForm.get('banner.bannerRebates')?.value=='Yes'}">
    </div>
    <div *ngIf="parentIntakeForm.get('banner.bannerRebates')?.value=='Yes'">
        <div class="row paddingClass" id="bannerAccordion">
            <div class="col-md-5 widthClass">
            </div>
            <div class="col-md-4 widthClassRemaining">
                <div class="row paddingClassAccordion " style="padding:5px 0px">
                    <div class="col-md-7 widthClassAccordionRem widthClass" style="text-align: start;">
                        {{'CORPORATE_REBATE' | translate}} <span class="hashcol">*</span></div>
                    <div class="col-md-5 widthClassAccordionRem widthClass bannerTitle leftPadding-0"
                        style="width:inherit">{{'AMOUNT' | translate}} (%) <span class="hashcol">*</span>
                    </div>
                </div>
                <div formArrayName="bannerCorporateRebate">
                    <div class=" row paddingClassAccordion" style="padding-top:0px;"
                        *ngFor="let data of bannerCorporateRebate.controls;let i=index" [formGroupName]="i">
                        <div class="col-md-7 widthClassAccordionRem widthClass" style="text-align: start;">
                            <app-dynamic-form-fields formControlName="value" width="100%" fieldType="dropdown"
                                placeholder="{{'SELECT_CORPORATE_REBATE' | translate}}" optionLabel="name"
                                [options]="bannerOptions('corporate')"
                                (blur)="valueChanged($event,i,'bannerCorporateRebate')">
                            </app-dynamic-form-fields>
                        </div>

                        <div class="col-md-5 widthClassAccordionRem leftPadding-0">
                            <app-dynamic-form-fields formControlName="amount" width="100%" fieldType="dropdown"
                                optionLabel="rebateValue" placeholder="{{'SELECT_AMOUNT' | translate}}"
                                [options]="data.value.options" (blur)="valueChanged($event)">
                            </app-dynamic-form-fields>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="divider dividerAccordion"> </div>
        <div class="row paddingClass" id="bannerAccordion">
            <div class="col-md-5 widthClass">
            </div>
            <div class="col-md-4 widthClassRemaining">
                <div class="row paddingClassAccordion" style="padding:5px 0px">
                    <div class="col-md-7 widthClassAccordionRem widthClass" style="text-align: start;">
                        {{'SHOP_REBATE' | translate}} <span class="hashcol">*</span></div>
                    <div class="col-md-5 widthClassAccordionRem widthClass bannerTitle leftPadding-0"
                        style="width:inherit">{{'AMOUNT' | translate}} (%) <span class="hashcol">*</span>
                    </div>
                </div>
                <div formArrayName="bannerShopRebate">
                    <div class=" row paddingClassAccordion" style="padding-top:0px"
                        *ngFor="let data of bannerShopRebate.controls;let i=index" [formGroupName]="i">
                        <div class="col-md-7 widthClassAccordionRem widthClass" style="text-align: start;">
                            <app-dynamic-form-fields formControlName="value" width="100%" fieldType="dropdown"
                                placeholder="{{'SELECT_SHOP_REBATE' | translate}}" optionLabel="name"
                                [options]="bannerOptions('shop')" (blur)="valueChanged($event,i,'bannerShopRebate')">
                            </app-dynamic-form-fields>
                        </div>
                        <div class="col-md-5 widthClassAccordionRem leftPadding-0">
                            <app-dynamic-form-fields placeholder="{{'SELECT_AMOUNT' | translate}}"
                                formControlName="amount" width="100%" fieldType="dropdown" optionLabel="rebateValue"
                                [options]="data.value.options" (blur)="valueChanged($event)">
                            </app-dynamic-form-fields>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="divider dividerAccordion"> </div>
        <div class="row paddingClass" id="bannerAccordion">
            <div class="col-md-5 widthClass">
            </div>
            <div class="col-md-4 widthClassRemaining">
                <div class="row paddingClassAccordion" style="padding:5px 0px">
                    <div class="col-md-7 widthClassAccordionRem widthClass" style="text-align: start;">
                        {{'CCG_SET_UP' | translate}} <span class="hashcol">*</span>
                    </div>
                    <div class="col-md-5 widthClassAccordionRem widthClass bannerTitle leftPadding-0"
                        style="width:inherit">{{'AMOUNT' | translate}} (%) <span class="hashcol">*</span>
                    </div>
                </div>
                <div formArrayName="ccg">
                    <div class=" row paddingClassAccordion" style="padding-top:0px"
                        *ngFor="let data of ccg.controls;let i=index" [formGroupName]="i">

                        <div class="col-md-7 widthClass" style="text-align: start;">
                            <app-dynamic-form-fields formControlName="value" width="100%" fieldType="dropdown"
                                placeholder="{{'SELECT_CCG' | translate}}" optionLabel="name"
                                [options]="bannerOptions('ccg')" (blur)="valueChanged($event,i,'ccg')">
                            </app-dynamic-form-fields>
                        </div>

                        <div class="col-md-5 widthClassAccordionRem leftPadding-0">
                            <app-dynamic-form-fields width="100%" fieldType="dropdown" optionLabel="rebateValue"
                                formControlName="amount" placeholder="{{'SELECT_AMOUNT' | translate}}"
                                [options]="data.value.options" (blur)="valueChanged($event)">
                            </app-dynamic-form-fields>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="divider dividerAccordion"> </div>

    </div>

    <div [ngClass]="{'dividerAccordionTop':parentIntakeForm.get('banner.bannerRebates')?.value=='Yes'}">
        <div class="row paddingClass" style="padding-top:0px">
            <div class="col-md-5 widthClass">{{'BANNER_MARKETING_REBATE' | translate}}
                <span *ngIf="parentIntakeForm.get('banner.bannerRebates')?.value=='Yes'"
                    class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields (click)="warningMessage()" formControlName="bannerMarketingRebate" width="100%" fieldType="dropdown"
                    placeholder="{{'SELECT_MARKETING_REBATE' | translate}}" optionLabel="name"
                    [options]="bannerMarketingOptions()" (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
    </div>
</div>