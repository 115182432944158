import { TranslateService } from "@ngx-translate/core";

export class GetFieldName {
    private translate: TranslateService
    constructor(translate: TranslateService) {
        this.translate = translate
    }

    public GetFieldNameMethod(control_name: string) {
        let text = ''
        this.translate.get(this.fieldNameMap(control_name)).subscribe(async (res: any) => {
            console.log(res)
            text = res
            return res
        })
        return text
    }

    fieldNameMap(name: string) {
        let text = ''
        switch (name) {
            case 'country': text = 'COUNTRY'; break;
            case 'accountStatus': text = 'ACCOUNT_STATUS'; break;
            case 'customerInformation': text = 'CUSTOMER_INFORMATION'; break;

            case 'nonStandardGrowth': text = 'NON_STANDARD_GROWTH'; break;
            case 'mprStructure': text = 'MRP_STRUCTURE'; break;
            case 'targetTotalMpr': text = 'TARGET_TOTAL_MRP'; break;
            case 'contract': text = 'CONTRACT_YEARS'; break;

            case 'bannerRebates': text = 'BANNER_REBATES'; break;
            case 'bannerMarketingRebate': text = 'BANNER_MARKETING_REBATE'; break;

            case 'equipment': text = 'EQUIPMENT_PROVIDED'; break;
            case 'name': text = 'JOBBER_NAME'; break;
            case 'paint': text = 'PAINT_LINE'; break;
            case 'upfront': text = 'UPFRONT_INVESTMENT'; break;
            case 'pos': text = 'IS_VARIABLE_REBATE_INCLUDED'; break;
            case 'year': text = 'MPR_YEAR0'; break;

            case 'sub_jobber': text = 'SUB_JOBBER'; break;
            case 'upfront': text = 'JOBBER_UPFRONT_INVESTMENT'; break;
            case 'discount': text = 'JOBBER_POS_DISCOUNT'; break;

            case 'sub_jobber_rebate': text = 'BASF_VARIABLE_REBATE_SUBJOBBER'; break;
            case 'variable': text = 'BASF_VARIABLE_REBATE'; break;
            case 'fixed': text = 'FIXED_REBATE'; break;

            default: text = name

        }
        return text
    }
}