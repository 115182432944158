<div class="form-wraper" style="width:500px;margin:auto">
    <div class="form-container card">
        <div class="form-header" style="padding:20px;text-align: center;">
            <a class="navbar-brand nav-img p-0 m-0" href="#">
                <div class="header-logo"  
                    style="display: inline-flex;border:1px solid #219fd2;background-color: #219fd2;">
                    <div>
                        <img class="header-Logo-Basf" src="../../assets/images/BASFo_wh100tr.png" alt=""
                            loading="lazy" />
                    </div>
                    <div style="display:flex;align-items: center;">
                        <div style="display: inline-flex">
                            <div class="header-Logo-Dash"></div>
                            <img class="header-Logo-Hand" src="../../assets/images/icons/sales.png" alt=""
                                loading="lazy" />
                            <p style="color:white;margin:10px">
                                Digital Deal
                                <br />
                                Crafter
                            </p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="form-body" style="padding:20px">
            <div class="flex items-center justify-between" style="text-align : center">
                <button (click)="SAMLService()" class="btn submit-btn">
                    Log in
                </button>

            </div>
        </div>
    </div>
</div>