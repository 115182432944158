import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlContainer, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/services/dataServices/data.service';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-create-deal-form',
  templateUrl: './create-deal-form.component.html',
  styleUrls: ['./create-deal-form.component.css'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class CreateDealFormComponent implements OnInit {

  @Input() masterDropDownDetails: any
  @Input() country: any
  @Input() opportunity: any
  @Input() defaultValue: any
  @Output() countryChange:any=new EventEmitter
  parentIntakeForm!: UntypedFormGroup;

  constructor(private parent: FormGroupDirective, private translate: TranslateService, private _service: DataService, private spinner: NgxSpinnerService) { }

  countryBasedOnRole: any = []
  ngOnInit(): void {
    console.log(this.opportunity, this.country)
    this.parentIntakeForm = this.parent.form;
    this.countryBasedOnRole = JSON.parse(sessionStorage.getItem('country') + '')
    console.log(this.countryBasedOnRole)
  }

  countryBasedOnRoleOptions() {
    let data = this.country?.filter((obj: any) => this.countryBasedOnRole?.includes(obj.country_id))
    return data
  }

  valueChanged(value: any, field = '') {
    console.log(this.parentIntakeForm.value)
    console.log(value)
    console.log(this.defaultValue, "default value")
    if (value?.name == 'country') {
      console.log("country")
      this.parentIntakeForm.get('general.name')?.reset()
      this.parentIntakeForm.get('jobber.sub_jobber')?.reset()
      this.parentIntakeForm.get('banner.bannerMarketingRebate')?.reset()
      this.parentIntakeForm.get('banner.bannerCorporateRebate')?.reset()
      this.parentIntakeForm.get('banner.bannerShopRebate')?.reset()
      this.parentIntakeForm.get('banner.ccg')?.reset()
      this.countryChange.emit(true)
      console.log("create deal banner maeketing rebate,", this.parentIntakeForm.value)
      if (value.value.id == 1) {//US
        this.translate.get('US_MPR_STRUCTURE').subscribe((res: any) => {
          this.parentIntakeForm.patchValue({
            'additional': {
              'mprStructure': this.masterDropDownDetails.mpr_structure_collection?.
                filter((x: any) => x.name.toLowerCase() == res.toLowerCase())[0]
            },
          })
        })
      }
      else if (value.value.id == 2) {//CA
        this.translate.get('CA_MPR_STRUCTURE').subscribe((res: any) => {
          console.log(res, "translate")
          this.parentIntakeForm.patchValue({
            'additional': {
              'mprStructure': this.masterDropDownDetails.mpr_structure_collection?.
                filter((x: any) => x.name.toLowerCase() == res.toLowerCase())[0]

            }
          })
        })
      }
    }

    if(value?.name == 'opportunity')
    {
      console.log(value)
      this.parentIntakeForm.get('custInformation.customerInformation')?.reset()

       this.parentIntakeForm.patchValue({
            'custInformation': {
              'customerInformation': value?.value?.account_name
            },
          })
      
    }
    if (field == 'ref_id') {
      if (0) {//remove this code while integrating with sales force with actual data
        this.parentIntakeForm.patchValue({
          'existing': {
            'actual': 44,
            'tdate': this.converDate(new Date())

          }
        })
      }
    }

  }

  converDate(value: any) {
    if (value == '' || value == undefined) return '';
    else return formatDate(value, 'yyyy-MM-dd', 'en-US');
  }

}
