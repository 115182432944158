import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { ApiResponse } from 'src/app/components/models/api-response';
import { environment } from 'src/environments/environment';
import * as saveAs from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private http: HttpClient, private translate: TranslateService) { }

  dealFormData = new BehaviorSubject({});


  sendDealFormData(value: any) {
    this.dealFormData.next(value);
  }
  getDealFormData(): Observable<any> {
    return this.dealFormData.asObservable();
  }

  insertregistrationdetails(data: any) {
    return this.http.post<ApiResponse>(
      environment.insertregistrationdetails,
      data
    );
  }



  exportExcel(dataValue: any, cols: any, fileName = '') {
    const colData = cols.filter((x: any) => x.is_show )
    const fieldValues: any[] = colData.map((field: { field: any; }) => field.field);
    const headerValues: string[] = colData.map((header: { header: any; }) => header.header);
    const exportJson = JSON.parse(JSON.stringify(dataValue));

    const displayedJson: any[] = [];
    for (let originalValue of exportJson) {
      let jsonObject: any = {};
      for (let i = 0; i <= fieldValues.length; i++) {
        jsonObject[headerValues[i]] = originalValue[fieldValues[i]];
      }
      let jsonString = JSON.stringify(jsonObject);
      displayedJson.push(JSON.parse(jsonString));
    }
    console.log(displayedJson)

    //not using data
    dataValue.map((value: any) => {
      const updateValue: any = {};
      for (let field in value) {
        let a: any = field.replace(/_/g, ' ').replace(/\w\S*/g, (w: any) => (w.replace(/^\w/, (c: any) => c.toUpperCase())))
        updateValue[a] = value[field]
      }
      return updateValue;
    })
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(displayedJson);
      const workbook = { Sheets: { [fileName]: worksheet }, SheetNames: [fileName] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, fileName);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + EXCEL_EXTENSION);
  }

  sortSelectedColumns(columsnData: any[]) {
    return columsnData.sort(
      (a: { serial_no: number }, b: { serial_no: number }) => {
        return a.serial_no - b.serial_no;
      }
    );
  }

  trnaslateFunction(name: string) {
    let text = name
    this.translate.get(name).subscribe(async (res: any) => {
      console.log(res)
      text = res
    })
    return text
  }

  RetrieveJWtToken(user: any) {
    return this.http.post<any>(environment.getJWTToken, user);
  }

  getAdminList() {
    return this.http.get<ApiResponse>(environment.RetrieveAllUsers);
  }

  getLoggedinUserData(basf_user_id: string) {
    return this.http.get<any>(
      `${environment.RetrieveUserDetailsByBasfUserId}?BasfUserId=${basf_user_id}`
    );
  }

  getCountryList() {
    return this.http.get<ApiResponse>(environment.RetrieveCountryDetail);
  }
  getUserMaster() {
    return this.http.get<any>(environment.RetrieveUserMaster);
  }

  getRoleList() {
    return this.http.get<ApiResponse>(environment.RetrieveRoleDetail);
  }
  getKpiList() {
    return this.http.get<ApiResponse>(environment.RetrieveAllKpis);
  }
  saveKpiDetails(data: any) {
    return this.http.post<ApiResponse>(environment.SaveKpiDetails, data);
  }
  RetriveAllFormGrid() {
    return this.http.get<ApiResponse>(environment.RetriveFormDataDetails);
  }
  RetriveFormDetails() {
    return this.http.get<ApiResponse>(environment.RetriveFormDetails);
  }
  SaveFormFieldDetails(data: any) {
    return this.http.post<ApiResponse>(environment.SaveFormFieldDetails, data);
  }
  RetriveAllFieldDetailsByFormId(data: any) {
    return this.http.get<ApiResponse>(
      `${environment.RetriveAllFieldDetailsByFormId}?FormId=${data}`
    );
  }
  RetriveAllFieldDetailsForKpi() {
    return this.http.get<ApiResponse>(environment.RetrieveMasterDataDetail);
  }
  RetrieveKpiDetails(data: any) {
    return this.http.get<ApiResponse>(
      `${environment.RetrieveKpiDetails}?KpiNameId=${data}`
    );
  }

  SaveDealFormFieldDetails(data: any) {
    return this.http.post<any>(environment.SAVEDEALDETAILS, data);
  }
  SendtoSalesforce(data: any) {
    return this.http.post<any>(environment.SendtoSalesforce, data);
  }
  

  CalculateDeals(data: any) {
    return this.http.post<any>(environment.CALCULATEDEALDETAILS, data);
  }


  RetriveDealDetils(data: any) {
    return this.http.get(
      `${environment.GETDEALDETAILS}?UserId=${data}`
    );
  }


  RetrieveFormDetails() {
    return this.http.get(environment.RetrieveFormDetails);
  }

  RetriveMasterDealDetils() {
    return this.http.get(environment.RETRIEVEMASTERDEALETAILS);
  }



  getScatterPlotData(data: any) {
    return this.http.get(
      `${environment.GETSCATTERPLOTDATA}?CountryId=${data}`
    );
  }

  RetrieveCountryDetailsById(data: any) {
    return this.http.get(
      `${environment.GETCOUNTRYDETAILS}?basfuserid=${data}`
    );
  }
  RetrieveOpportunity(data: any) {
    return this.http.get(
      `${environment.GETOPPORTUNITY}?UserId=${data}`
    );
  }
  RetriveOpportunityData(data:any){
    return this.http.get(
      `${environment.RetriveOpportunityData}?basfUserId=${data}`
    );
  }

  GetKpiDealData(data: any) {
    return this.http.get(
      `${environment.GETKPIDEALDATA}?DealId=${data}`
    );
  }

  RetrieveDealDefaultValue(data: any) {
    return this.http.get(
      `${environment.GETDEALDEFAULTVALUE}?FormId=${data}`
    );
  }
  RetrieveGeneralAssumptionMasterData() {
    return this.http.get<ApiResponse>(
      `${environment.RetrieveGeneralAssumptionMasterData}`
    );
  }

  RetriveGeneralAssumptionDetails() {
    return this.http.get<ApiResponse>(
      `${environment.RetriveGeneralAssumptionDetails}`
    );
  }
  RetriveGeneralAssumptionDetailsByCountry(data: any) {
    return this.http.get<ApiResponse>(
      `${environment.RetriveGeneralAssumptionDetailsByCountry}?CountryId=${data.countryId}&TypeId=${data.typeId}`
    );
  }
  GeneralAssumptionDetails(data: any) {
    return this.http.post<ApiResponse>(
      `${environment.GeneralAssumptionDetails}`,
      data
    );
  }

  GetDistributorDetails() {
    return this.http.get(
      `${environment.GetDistributorDetails}`
    );
  }

  GetAdminDropdownList() {
    return this.http.get(
      `${environment.GetAdminDropdownList}`
    );
  }

  InsertOrUpdateDistributor(data: any) {
    return this.http.post(
      `${environment.InsertOrUpdateDistributor}`, data
    );
  }
  RetrievePaintLineMasterData() {
    return this.http.get<ApiResponse>(
      `${environment.RetrievePaintLineMasterData}`
    );
  }
  RetrivePaintLineMarginDetails() {
    return this.http.get<ApiResponse>(
      `${environment.RetrivePaintLineMarginDetails}`
    );
  }
  RetrivePaintLineMarginByCountry(data: any) {
    return this.http.get<ApiResponse>(
      `${environment.RetrivePaintLineMarginByCountry}?CountryId=${data.countryId}&TypeId=${data.typeId}&DealmasterId=${data.dealMasterId}`
    );
  }
  PaintLineMarginDetails(data: any) {
    return this.http.post<ApiResponse>(
      `${environment.PaintLineMarginDetails}`,
      data
    );
  }

  GetDistributorAssumptionsHeaders(countryId: any, subJobber: any) {
    return this.http.get(
      `${environment.GetDistributorAssumptionsHeaders}?countryId=${countryId}&subJobber=${subJobber}`
    );
  }
  GetDistributorAssumptionsList() {
    return this.http.get(`${environment.GetDistributorAssumptionsList}`);
  }
  InsertOrUpdateDistributorAssumptions(data: any) {
    return this.http.post(
      `${environment.InsertOrUpdateDistributorAssumptions}`, data
    );
  }

  DeleteDistributororSubjobber(countryId: any, distributorId: any, subJobber: any) {
    return this.http.get(
      `${environment.DeleteDistributororSubjobber}?countryId=${countryId}&distributorId=${distributorId}&subJobberId=${subJobber}`
    );
  }

  DeleteDistributororSubjobberMargin(countryId: any, distributorId: any, subJobber: any) {
    return this.http.get(
      `${environment.DeleteDistributororSubjobberMargin}?countryId=${countryId}&distributorId=${distributorId}&subJobberId=${subJobber}`
    );
  }

  RetrieveDealByDealId(id: number) {
    return this.http.get(`${environment.RetrieveDealByDealId}?DealId=${id}`);
  }

  GetRebateValues(id: number) {
    return this.http.get(`${environment.GetRebateValues}/${id}`);
  }

  GetRebatesMaster(id: number, type: string) {
    return this.http.get(`${environment.GetRebatesMaster}?CountryID=${id}&BannerType=${type}`);
  }
  GetAllRebateValues(data: any) {
    return this.http.get(`${environment.GetAllRebateValues}?BannerType=${data}`);
  }

  ManageBannerRebates(actionType: number, data: any) {
    return this.http.post(`${environment.ManageBannerRebates}?ActionType=${actionType}`, data);
  }

  GetRebateMarketingMaster() {
    return this.http.get(`${environment.GetRebateMarketingMaster}`);
  }
  GetMarketingRebateValues() {
    return this.http.get(`${environment.GetMarketingRebateValues}`);
  }
  GetAllMarketingRebateValues() {
    return this.http.get(`${environment.GetAllMarketingRebateValues}`);
  }

  ManageBannerMarketingRebates(actionType: number, data: any) {
    return this.http.post(`${environment.ManageBannerMarketingRebates}?ActionType=${actionType}`, data);
  }


  // login functionality

  userData = new BehaviorSubject({
    id: 0,
    basf_user_id: '',
    first_name: '',
    last_name: '',
    email: '',
    created_by: '',
    is_active: true,
    country: [{ id: 0, userId: 0, countryId: 0 }],
    country_name: '',
    role: [{ id: 0, userId: 0, roleId: 0 }],
    role_name: '',
  });
  sendUserData(value: any) {
    this.userData.next(value);
  }
  getUserData(): Observable<any> {
    return this.userData.asObservable();
  }

  getUser(user: any) {
    return this.http
      .post(environment.GetUser, user)
      .pipe(map((res: any) => res));
  }

  getSAMLService() {
    return this.http.get(environment.SAML);
  }

  getUsersforLogin() {
    return this.http
      .get(`${environment.getUsersforLogin}`)
      .pipe(map((response) => response));
  }
}
