import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-display-deal-form',
  templateUrl: './display-deal-form.component.html',
  styleUrls: ['./display-deal-form.component.css'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class DisplayDealFormComponent implements OnInit {

  @Input() status: any
  @Input() contractValueChanged: any = 0
  @Input() yearValueChanged: any = 0
  @Input() masterDropDownDetails: any
  @Input() subJobberOptions: any = []
  @Input() defaultValue:any=[]
  parentIntakeForm!: UntypedFormGroup;
  constructor(private parent: FormGroupDirective,
    private route: ActivatedRoute) {
  }

  editFormId: number = 0
  ngOnInit(): void {
    this.parentIntakeForm = this.parent.form;
    this.editFormId = parseInt(atob(this.route.snapshot.paramMap.get('id') + '')) || 0;
    if (this.editFormId < 0)
      this.editFormId = 0
    console.log("edit id", this.editFormId, this.route.snapshot.paramMap.get('id'))

  }

  valueChanged(value: any, i = 0, formName = '') {
    console.log(value)
  }

}
