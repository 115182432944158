import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Injector, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'validatorMessage'
})
export class ValidatorMessagePipe implements PipeTransform, OnDestroy {
  private asyncPipe!: AsyncPipe
  constructor(private translate: TranslateService, private injector: Injector) {
    this.asyncPipe = new AsyncPipe(injector.get(ChangeDetectorRef))
  }

  ngOnDestroy(): void {
    this.asyncPipe.ngOnDestroy()
  }

  transform(value: any): string {
    if (value == null)
      return ''
    if (value['required']) {
      return this.asyncPipe.transform(this.translate.get('FIELD_REQUIRED'))
    }
    else if (value['minlength'])
      return this.asyncPipe.transform(this.translate.get('MIN_LENGTH')) + ':' + value['minlength'].requiredLength
    else if (value['max'])
      return this.asyncPipe.transform(this.translate.get('MAX_VALUE')) + ':' + value['max'].max
    else if (value['email'])
      return this.asyncPipe.transform(this.translate.get('EMAIL_ID_NOT_VALID'))
    else if (value['pattern']) {
      if (value['pattern']['requiredPattern'] == '/^\\d+$/')
        return this.asyncPipe.transform(this.translate.get('FIXED_NUMBERS'))
      else if (value['pattern']['requiredPattern'] == '/^[a-zA-Z]+$/')
        return this.asyncPipe.transform(this.translate.get('ALPHABETS'))
      else if (value['pattern']['requiredPattern'] == '/^[a-zA-Z0-9]+$/')
        return this.asyncPipe.transform(this.translate.get('ALPHA_NUMERIC'))
      else if (value['pattern']['requiredPattern'] == '/^\\d+\\.?\\d{0,2}$/')
        return this.asyncPipe.transform(this.translate.get('POSITIVE_DECIMAL'))
      else if (value['pattern']['requiredPattern'] == '/^-?\\d+\\.?\\d{0,2}$/')
        return this.asyncPipe.transform(this.translate.get('DECIMAL_NUMBERS'))
        else if (value['pattern']['requiredPattern'] == '/^-?\\d+\\.?\\d{0,3}$/')
        return this.asyncPipe.transform(this.translate.get('DECIMAL_NUMBERS'))
    }

    return ''
  }

}
