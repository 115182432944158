<div class="min-height" style="padding:20px">
    <div class="row" style="align-items: center; padding: 1rem 0rem">
        <label class="col-md-12 header-label">{{heading}}</label>
    </div>

    <div class="row">
        <div class="col-md-12">


            <div class="row">
                <div class="col-md-12">
                    <form id="shopRebateForm" [formGroup]="shopRebateForm">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row" style="align-items: inherit">

                                    <div class="col-md-3 cust-form-group">
                                        <div class="form-group">
                                            <label class="cust-form-label" for="country">{{'COUNTRY' | translate}}
                                                <span class="asterikClass">*</span>
                                            </label>

                                            <app-dynamic-form-fields formControlName="country" fieldType="dropdown"
                                                placeholder="{{'SELECT_COUNTRY' | translate}}" optionLabel="value"
                                                width="100%" [options]="country" (blur)="valueChanged($event)">
                                            </app-dynamic-form-fields>
                                        </div>

                                    </div>

                                    <div class="col-md-3 cust-form-group"
                                        *ngIf="shopRebateForm.value.country?.id!=undefined && !showNewRebate">
                                        <div class="form-group">
                                            <label class="cust-form-label" for="shopName">{{heading}} <span
                                                    class="asterikClass">*</span></label>

                                            <app-dynamic-form-fields id="shopNameDropdown" formControlName="shopName"
                                                fieldType="dropdown" placeholder="{{fieldName}}" optionLabel="name"
                                                width="100%" [options]="shopList" (blur)="valueChanged($event)">
                                            </app-dynamic-form-fields>

                                        </div>


                                    </div>

                                    <div class="col-md-3 cust-form-group" *ngIf="showNewRebate">
                                        <div class="form-group">
                                            <label class="cust-form-label" for="country">{{'NEW_REBATE_NAME' |
                                                translate}}
                                                <span class="asterikClass">*</span>
                                            </label>

                                            <app-dynamic-form-fields formControlName="shopName" fieldType="inputText"
                                                placeholder="{{'ENTER_REBATE' | translate}}" width="100%"
                                                (blur)="valueChanged($event,'shopNameInput')">
                                            </app-dynamic-form-fields>
                                        </div>

                                    </div>
                                    <div class="col-md-3 " *ngIf="shopRebateForm.value.country?.id!=undefined"
                                        style="display: flex;align-items: center;margin-top: 10px;">
                                        <em *ngIf="!showNewRebate" class="pi pi-plus-circle"
                                            style="padding-left: 6px;color:#2cc865" (click)="addNewRebates()"></em>
                                        <em *ngIf="showNewRebate" class="pi pi-times-circle" style="color:#ff5d5d"
                                            (click)="showNewRebate=false"></em>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div *ngIf="shopRebateForm.value.rebates.length>0"
                                style="align-items: center; padding: 1rem 0rem">
                                <label class="col-md-12 header-label"
                                    style="font-size:16px;padding-left:10px;color:#a8a8a8">
                                    {{'REBATES' | translate}}<span *ngIf="heading !='One Time Fees'">(%)</span>
                                    <em class="pi pi-plus-circle" style="padding-left: 6px;color:#2cc865"
                                        (click)="addRebates()"></em></label>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row" style="align-items: inherit">
                                        <div class="cust-form-group widthChips" formArrayName="rebates"
                                            *ngFor="let data of rebates?.controls;let i=index">
                                            <div class="form-group" [formGroupName]="i" style="display:flex">
                                                <app-dynamic-form-fields formControlName="rebateValue"
                                                    fieldType="inputText" width="100%" (blur)="valueChanged($event)">
                                                </app-dynamic-form-fields>
                                                <div style="display: flex;align-items: center;padding: 0px 4px;">
                                                    <em class="pi pi-times-circle"  style="color:#ff5d5d" (click)="removeRebates(i)"></em>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="container-fluid mobileButtonClass paddingClassButtons">
                                            <div class="row">
                                                <div class="col-md-12" style="text-align: center;">
                                                    <button type="button"
                                                        (click)="shopRebateForm.reset();rebates.clear();showNewRebate=false;isEdit=false"
                                                        class="btn cancel-btn">{{'CANCEL' | translate}}</button>
                                                    <button type="button" class="btn submit-btn" (click)="submit()"
                                                        [disabled]="shopRebateForm.invalid">{{'SUBMIT' |
                                                        translate}}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </form>
                </div>
            </div>




        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <app-dynamic-grid [gridCols]="cols" [gridData]="rebatesgrid" [showFilter]="true" [showEdit]="true"
                (selectRow)="onSelectRow($event)">
            </app-dynamic-grid>
        </div>
    </div>

</div>