import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DatePipe } from '@angular/common';

import { InputNumberModule } from 'primeng/inputnumber';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ChipsModule } from 'primeng/chips';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { PanelMenuModule } from 'primeng/panelmenu';
import { DialogModule } from 'primeng/dialog';
import { FieldsetModule } from 'primeng/fieldset';
import { KeyFilterModule } from 'primeng/keyfilter';
import { DividerModule } from 'primeng/divider';
import { ChartModule } from 'primeng/chart';

import { CalendarModule } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
import { DynamicGridComponent } from './components/dynamic-grid/dynamic-grid.component';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AdminComponent } from './components/admin/admin.component';
import { UserGridComponent } from './components/admin/user/user-grid/user-grid.component';
import { UserRegistrationComponent } from './components/admin/user/user-registration/user-registration.component';
import { SlideMenuModule } from 'primeng/slidemenu';
import {MenuModule} from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { SliderModule } from 'primeng/slider';
import { ListboxModule } from 'primeng/listbox';
import { SidebarModule } from 'primeng/sidebar';
import { HttpconfigInterceptor } from './_helpers/interceptor/httpconfig.interceptors';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ValidatorMessagePipe } from './pipes/validator-message.pipe';
import { LoginTestComponent } from './components/login-test/login-test.component';
import { ConfirmationService } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OnlyaplhanumericDirective } from './directives/authorization/attribute/onlyaplhanumeric.directive';
import { DefaultFormGridComponent } from './components/admin/default-form/default-form-grid/default-form-grid.component';
import { DefaultFormRegistrationComponent } from './components/admin/default-form/default-form-registration/default-form-registration.component';
import { CreateDealFormComponent } from './components/deal-form/create-deal-form/create-deal-form.component';
import { DisplayDealFormComponent } from './components/deal-form/display-deal-form/display-deal-form.component';
import { DisplayChartComponent } from './components/deal-form/display-chart/display-chart.component';
import { DealFormComponent } from './components/deal-form/deal-form.component';
import { DynamicFormFieldsComponent } from './components/common/dynamic-form-fields/dynamic-form-fields.component';
import { DisplayKpiComponent } from './components/deal-form/display-kpi/display-kpi.component';
import { KpiComponent } from './components/admin/kpi/kpi/kpi.component';
import { DisplayDealGridComponent } from './components/display-deal-grid/display-deal-grid.component';
import { DistributorRegistrationComponent } from './components/admin/distributor-registration/distributor-registration.component';
import { DistributorMarginsComponent } from './components/admin/distributor-margins/distributor-margins.component';
import { SubJobberMarginsComponent } from './components/admin/sub-jobber-margins/sub-jobber-margins.component';

import { GeneralAssumptionsGridComponent } from './components/admin/general-assumptions/general-assumptions-grid/general-assumptions-grid.component';
import { GeneralAssumptionsRegistrationComponent } from './components/admin/general-assumptions/general-assumptions-registration/general-assumptions-registration.component';
import { PaintGridComponent } from './components/admin/paint/paint-grid/paint-grid.component';
import { PaintRegistrationComponent } from './components/admin/paint/paint-registration/paint-registration.component';
import { BannerShopRebatesComponent } from './components/admin/banner-shop-rebates/banner-shop-rebates.component';
import { HasRequiredFieldPipe } from './pipes/has-required-field.pipe';
import { DisplayScatterChartComponent } from './components/deal-form/display-scatter-chart/display-scatter-chart.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SubmitDealComponent } from './components/deal-form/submit-deal/submit-deal.component';
import { AdditionalComponent } from './components/deal-form/display-deal-form/additional/additional.component';
import { BannerComponent } from './components/deal-form/display-deal-form/banner/banner.component';
import { GeneralComponent } from './components/deal-form/display-deal-form/general/general.component';
import { JobberComponent } from './components/deal-form/display-deal-form/jobber/jobber.component';
import { RebateComponent } from './components/deal-form/display-deal-form/rebate/rebate.component';
import { CustomLoaderComponent } from './components/common/custom-loader/custom-loader.component';
import { ExistingComponent } from './components/deal-form/display-deal-form/existing/existing.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGaurd } from './_helpers/auth.guard';
import { DisplayCalculationGridComponent } from './components/deal-form/display-calculation-grid/display-calculation-grid.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { TitleCase } from './pipes/title-case.pipe';
import { BannerMarketingRebatesComponent } from './components/admin/banner-marketing-rebates/banner-marketing-rebates.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    ValidatorMessagePipe,
    TitleCase,
    DynamicFormFieldsComponent,
    DynamicGridComponent,
    AdminComponent,
    UserGridComponent,
    UserRegistrationComponent,
    LoginTestComponent,
    OnlyaplhanumericDirective,
    DefaultFormGridComponent,
    DefaultFormRegistrationComponent,
    CreateDealFormComponent,
    DisplayDealFormComponent,
    DisplayChartComponent,
    DealFormComponent,
    DisplayKpiComponent,
    KpiComponent,
    DisplayDealGridComponent,
    DistributorRegistrationComponent,
    DistributorMarginsComponent,
    SubJobberMarginsComponent,
    HasRequiredFieldPipe,
    GeneralAssumptionsGridComponent,
    GeneralAssumptionsRegistrationComponent,
    PaintGridComponent,
    PaintRegistrationComponent,
    BannerShopRebatesComponent,
    DisplayScatterChartComponent,
    SubmitDealComponent,
    AdditionalComponent,
    BannerComponent,
    GeneralComponent,
    JobberComponent,
    RebateComponent,
    CustomLoaderComponent,
    ExistingComponent,
    LoginComponent,
    DisplayCalculationGridComponent,
    UnauthorizedComponent,
    BannerMarketingRebatesComponent
  ],
  imports: [
    AccordionModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DropdownModule,
    FieldsetModule,
    DividerModule,
    InputTextModule,
    SliderModule,
    MenuModule,
    InputSwitchModule,
    SlideMenuModule,
    ListboxModule,
    SidebarModule,
    PanelMenuModule,
    RadioButtonModule,
    CheckboxModule,
    ReactiveFormsModule,
    TabViewModule,
    ChipsModule,
    ChartModule,
    MultiSelectModule,
    InputSwitchModule,
    NgxSpinnerModule,
    InputNumberModule,
    CalendarModule,
    KeyFilterModule,
    DialogModule,
    AutoCompleteModule,
    HttpClientModule,
    TabMenuModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
    }),
    DynamicDialogModule,
    TableModule,
    ButtonModule,
    TooltipModule,
    ConfirmDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    AuthGaurd,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpconfigInterceptor,
      multi: true,
    },
    ConfirmationService,
    DatePipe,
    FormGroupDirective,
  ],
  bootstrap: [AppComponent],
  exports: [NgxSpinnerModule],
})
export class AppModule { }
