<div class="card mx-auto" style="width: 22rem;margin-top: 50px;">
  <div class="card-body">
      <form>
          <div class="form-group row">
              <label for="user" class="col-sm-4 col-form-label cust-label">User</label>
              <div class="col-sm-8">
                <select class="form-control" id="user" name="user" [(ngModel)]="selectedUser">
                  <option value="" selected>Select User</option>
                  <option *ngFor="let user of users" [value]="user.email">
                    {{user.first_name}} {{user.last_name}}</option>
                </select>
              </div>
            </div>
          <button type="button" class="btn btn-primary" (click)="loginbtn()" style="float: right; margin: 10px 0;">Login</button>
      </form>
  </div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="line-scale"></ngx-spinner>
</div>