<div formGroupName="rebate">
    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'FIXED_REBATE' | translate}}
                <span *ngIf="parentIntakeForm.get('rebate.fixed')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="fixed" width="100%" startAddOn="$"
                    fieldType="inputTextGroup" placeholder="{{'ENTER_FIXED_REBATE' | translate}}"
                    (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'IS_VARIABLE_REBATE_INCLUDED' | translate}}
                <span *ngIf="parentIntakeForm.get('rebate.pos')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">

                <app-dynamic-form-fields formControlName="pos" width="60%" fieldType="switch" radioName="pos"
                    radioValue1="Yes" radioValue2="No" (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'BASF_VARIABLE_REBATE' | translate}}
                <span *ngIf="parentIntakeForm.get('rebate.variable')|hasRequiredField"
                    class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="variable" width="100%" fieldType="decimalnumberslider"
                    (blur)="valueChanged($event)" (keypress)="validateFloatKeyPress($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div *ngIf="parentIntakeForm.value.custInformation?.country?.id==2">
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'BASF_VARIABLE_REBATE_SUBJOBBER' | translate}}
                <span *ngIf="parentIntakeForm.get('rebate.sub_jobber_rebate')|hasRequiredField"
                    class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="sub_jobber_rebate" width="100%" fieldType="wholenumberslider"
                    (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>

    </div>
</div>
