<div class="container-fluid home-bg">
  <div class="container">
    <div class="row">
      <div class="w-100">
        <div class="home-bg-text">
          <span style="font-size: 20px; padding-left: 5px">Welcome to</span>
          <br /><span style="font-size: 50px">Digital</span> <br /><span
            style="font-size: 50px"
            >Deal Crafter.</span
          >
        </div>
      </div>
    </div>
    </div>
</div>
