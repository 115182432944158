import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class BannerComponent implements OnInit {

  @Input() masterDropDownDetails: any
  @Input() editFormId: number = 0
  parentIntakeForm!: UntypedFormGroup;
  constructor(private parent: FormGroupDirective, private fb: UntypedFormBuilder,
    private _service: DataService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.parentIntakeForm = this.parent.form;
    console.log(this.parentIntakeForm.value)
  }

  bannerOptions(name = '') {
    if (this.parentIntakeForm.value.custInformation.country.id == undefined) {
      return []
    }
    else {
      if (name == 'corporate')
        return this.masterDropDownDetails.deal_banner_corporate_data.filter((x: any) => x.country_id == this.parentIntakeForm.value.custInformation.country.id)
      else if (name == 'shop')
        return this.masterDropDownDetails.deal_banner_shop_data.filter((x: any) => x.country_id == this.parentIntakeForm.value.custInformation.country.id)
      else if (name == 'ccg')
        return this.masterDropDownDetails.deal_banner_ccg_data
          .filter((x: any) => x.country_id == this.parentIntakeForm.value.custInformation.country.id)

    }
  }

  warningMessage() {
      if (this.parentIntakeForm.value.custInformation.country.id == undefined) {
        this.translate.get('CHOOSE_COUNTRY').subscribe((res: any) => {
          this.toastr.warning(res)
        })
      }
  }

  bannerMarketingOptions() {
    if (this.parentIntakeForm.value.custInformation.country.id == undefined) {
      return []
    }
    else {
        return this.masterDropDownDetails?.banner_percentage_collection
          .filter((x: any) => x.country_id == this.parentIntakeForm.value.custInformation.country.id)
    }
  }

  get bannerCorporateRebate() {
    return <UntypedFormArray>this.parentIntakeForm.get('banner.bannerCorporateRebate');
  }

  get bannerShopRebate() {
    return <UntypedFormArray>this.parentIntakeForm.get('banner.bannerShopRebate');
  }

  get ccg() {
    return <UntypedFormArray>this.parentIntakeForm.get('banner.ccg');
  }


  getRebateValue(id: number, i: number, formName: any) {
    this._service.GetRebateValues(id).subscribe((response: any) => {
      this.spinner.show()
      if (!response['isError']) {
        this.spinner.hide()
        let data: any = {}
        let rebates: any = []
        response.response.forEach((x: any) => {
          data = {
            rebateID: x.rebateID,
            'rebateValue': x.rebateValue.toString()
          }
          rebates.push(data)
        })
        console.log(rebates)
        let formArrayControl = this.parentIntakeForm.get(`banner.${formName}`) as UntypedFormArray
        formArrayControl.controls[i].get('options')?.setValue(rebates)
        console.log(this.parentIntakeForm.value)
      }
    });
  }

  valueChanged(value: any, i = 0, formName = '') {
    console.log(value, i, formName)
    console.log(this.parentIntakeForm.value)

    if (value?.name == 'bannerRebates' && value?.value == 'Yes') {
      if (this.parentIntakeForm.value.custInformation.country.id == undefined) {
        this.translate.get('CHOOSE_COUNTRY').subscribe((res: any) => {
          this.toastr.warning(res)
        })
      }
    }

    if (formName == 'bannerCorporateRebate') {
      const corporateRebate = this.parentIntakeForm.value.banner.bannerCorporateRebate.filter((x: any) => {
        return x.value?.id == value.value?.id
      })
      console.log(corporateRebate)
      if (corporateRebate.length > 1) {
        this.duplicateError(formName,i)
        return
      }
    }

    if (formName == 'ccg') {
      const ccg = this.parentIntakeForm.value.banner.ccg.filter((x: any) => {
        return x.value?.id == value.value?.id
      })
      if (ccg.length > 1) {
        this.duplicateError(formName,i)
        return
      }
    }

    if (formName == 'bannerShopRebate') {
      const bannerShopRebate = this.parentIntakeForm.value.banner.bannerShopRebate.filter((x: any) => {
        return x.value?.id == value.value?.id
      })
      if (bannerShopRebate.length > 1) {
        this.duplicateError(formName,i)
        return
      }
    }

    
    if (value?.name == 'value' && formName != '') {
      this.getRebateValue(value.value.id, i, formName)
    }

  }


  duplicateError(formName:any,i:number)
  {
    this.toastr.error(this._service.trnaslateFunction('DUPLICATE'));
    let formArrayControl = this.parentIntakeForm.get(`banner.${formName}`) as UntypedFormArray
    formArrayControl.controls[i].get('options')?.setValue(null)
    formArrayControl.controls[i].get('id')?.setValue(null)
    formArrayControl.controls[i].get('amount')?.setValue(null)
    formArrayControl.controls[i].get('value')?.setValue(null)
  }

}
