<div>
  <div *ngIf="showForm" class="custom-sidenav">
    <div class="row headerStyling" >
      <label class="col-md-12 header-label" *ngIf="formType=='Edit'">{{'EDIT_GENERAL_ASSUMPTIONS' | translate}}
      </label>
      <label class="col-md-12 header-label" *ngIf="formType=='New'">{{'NEW_GENERAL_ASSUMPTIONS' | translate}}
      </label>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-general-assumptions-registration [formData]="selectedRow" [formType]="formType" [data]="{country,type}"
          (reLoadGrid)="refreshGeneralAssumptions($event)">
        </app-general-assumptions-registration>
      </div>
    </div>
  </div>
  <div class="custom-padding">
    <div class="row" style="align-items: center; padding: 1rem 0rem">

      <div *ngIf="!showForm" class="col-md-6 mobileButtonClass">
        <button type="button" class="btn add-btn" (click)="addNewGeneralAssumption()">{{'ADD_NEW' | translate}}</button>
        <button type="button" (click)="exportExcel()" class="btn excelButton">
          <em class="pi pi-file-excel"></em>{{'EXPORT_AS_EXCEL' | translate}} </button>
      </div>

      <div class="col-md-6" style="text-align: end;">
        <p-multiSelect id="showGridClass" [options]="orgCol" [(ngModel)]="selectedCols" name="colsDD4" ngDefaultControl
          (onChange)="onChangeDDValue($any($event).value)" optionLabel="header"
          defaultLabel="{{'SHOW_ITEMS_IN_GRID' | translate}}" [displaySelectedLabel]="false">
        </p-multiSelect>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-dynamic-grid [gridCols]="cols" [gridData]="usersData" [showFilter]="true" [showEdit]="true"
          (selectRow)="onSelectRow($event)">
        </app-dynamic-grid>
      </div>
    </div>
  </div>
</div>