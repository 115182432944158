<div class="container side-spacing" style="min-height: 100vh;padding-bottom:80px ;">

    <div class="row" style="align-items: center;padding:0px 10px; padding-bottom: 30px ">
        <div class="col-md-12 header-label" style="align-items: baseline;display:flex">
            <label [routerLink]="['/display-deal-form']" style="font-size:12px;cursor: pointer;">{{'DEAL_FORM' |
                translate}}</label>
            <em style="padding:0px 6px;font-size:12px;" class="pi pi-chevron-right"></em>
            <label *ngIf="!editFormId" style="cursor: pointer;">{{'CREATE_DEAL' | translate}}</label>
            <label *ngIf="editFormId" style="cursor: pointer;">{{'EDIT_DEAL' | translate}}</label>
        </div>
    </div>

    <div style="background-color: white;margin:0px 10px">
        <form id="dealCrafterForm" [formGroup]="dealCrafterForm">
            <div class="row">
                <div class="col-md-12">
                    <app-create-deal-form [defaultValue]="defaultValue"
                     [masterDropDownDetails]="masterDropDownDetails" [country]="country"
                      [opportunity]="opportunity"
                      (countryChange)="countryChange($event)">
                    </app-create-deal-form>
                </div>


                <div class="col-md-12">
                    <app-display-deal-form [masterDropDownDetails]="masterDropDownDetails"
                        [status]="dealCrafterForm.value.custInformation.accountStatus"
                        [subJobberOptions]="subJobberOptions" [contractValueChanged]="contractValueChanged"
                        [yearValueChanged]="yearValueChanged"
                        [defaultValue]="defaultValue"></app-display-deal-form>
                </div>

                <div id="display-chart" *ngIf="calculate==true" class="col-md-12"
                    style="padding-top:20px;padding-bottom: 20px;">
                    <div class="row" style="position: relative;">
                        <div class="widthClass" style="height: 100%;" *ngIf="tabValue=='KPI'">
                            <app-display-chart [dataList]="chartData"></app-display-chart>
                        </div>
                        <div class="widthClass" style="height: 100%;text-align: start;" *ngIf="tabValue=='Plot'">
                            <app-display-scatter-chart *ngIf="scatterChartData.length" [data]="scatterChartData" 
                            [ccmi]="ccmi"
                            [yAxisData]="yAxisData"
                            [xAxisData]="xAxisData"
                            ></app-display-scatter-chart>
                        </div>
                        <div class="widthClassRemaining" >
                            <app-display-kpi (activeSelection)="kpiTabSelection($event)" [kpiData]="kpiData"
                                (calculateChartData)="calculateChartData($event)"
                                (calculateScatterData)="calculateScatterData($event)"
                            (scatterChartData)="scatterChartDataGraph($event)"
                            [countryId]="dealCrafterForm.value.custInformation.country.id"></app-display-kpi>
                        </div>
                    </div>
                </div>

                <app-submit-deal [editFormId]="editFormId" [masterDropDownDetails]="masterDropDownDetails"
                    [displayChart]="displayChart" [bannerString]="bannerString" (calculate)="showChart($event)"
                    (kpiData)="generateKPIData($event)">
                </app-submit-deal>
               
            </div>
        </form>
    </div>
</div>