import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
  selector: 'app-display-kpi',
  templateUrl: './display-kpi.component.html',
  styleUrls: ['./display-kpi.component.css'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class DisplayKpiComponent implements OnInit, OnChanges {
  ccmpervalue: any;
isNumeric(val: any):  boolean  {
  return !isNaN(Number(val));
}

  items!: MenuItem[];
  activeItem!: MenuItem;
  
  dataList: any =
    [
      {
        item: 'BASF Net Sales To Distributor',
        value: '22222',
        color: this.generateColor('22222'),
        selected: true
      },
      {
        item: 'EBIT Acc $',
        value: '555',
        color: '#fff2cc',
        selected: true
      },
      {
        item: 'EBIT Acc % of MPR',
        value: '22222',
        color: '#c2ffad',
        selected: false
      },
      {
        item: 'Present Value Of Future Cash Flows',
        value: '555',
        color: '#fff2cc',
        selected: false
      },
      {
        item: 'PVI',
        value: '22222',
        color: '#c2ffad',
        selected: false
      },
      {
        item: 'Discounted Payback',
        value: '555',
        color: '#fff2cc',
        selected: false
      },
      {
        item: 'Investment as % of MPR',
        value: '22222',
        color: '#c2ffad',
        selected: false
      },
      {
        item: 'BASF vs Distibutor Investment split',
        value: '555',
        color: '#fff2cc',
        selected: false
      },
      {
        item: 'Cumulative MPR @ Refinish',
        value: '555',
        color: '#fff2cc',
        selected: false
      },
    ]
  @Output() calculateChartData: EventEmitter<any> = new EventEmitter<any>();
  @Output() calculateScatterData: EventEmitter<any> = new EventEmitter<any>();
  @Output() scatterChartData: EventEmitter<any> = new EventEmitter<any>();
  @Output() activeSelection: EventEmitter<any> = new EventEmitter<any>();
  @Input() kpiData: any = []
  @Input() countryId: any = 0

  constructor(private _service: DataService) { }

  scatterDataList!: any
  scatterDataListOptions: any = []
  ngOnChanges(): void {
    this.scatterDataList = this.kpiData.table1[0]
    this.ccmpervalue= this.scatterDataList['cCM%']
    delete this.scatterDataList['cCM%']
    for (let [key, value] of Object.entries(this.scatterDataList)) {
      console.log(key, value)
      this.scatterDataListOptions?.push({ name: key, value: value, disabled: this.checkRange(value) })
    }
    console.log("scatter", this.scatterDataListOptions,)
    console.log(this.scatterDataList, "scatter")
    console.log(this.kpiData, "kpiData")
    let i = 0
    let data = this.kpiData.table2
    for (let key in data) {
      console.log(key, data[key].kpiName)
      console.log(this.dataList, "data")
      this.dataList.find((c: any) => c.item == data[key].kpiName).value = data[key].value;
      this.dataList.find((c: any) => c.item == data[key].kpiName).color = data[key].color;//==""?'blue':data[key].color;
      // this.dataList.find((c: any) => c.item == data[key].kpiName).selected = ;
      i++
    }
    console.log(this.scatterDataListOptions)
  }

  xAxis = { name: 'ebit Acc $', value: 0 }
  yAxis = { name: 'basf Net Sales To Distributor', value: 0 }
  checkRange(data: any) {
    if (typeof data == 'string')
      return true
    else
      return false
  }

  checkRangeLine(data: any) {
    if (data.includes('No Payback')|| data.includes('%'))
      return true
    else
      return false
  }

  ngOnInit(): void {
    this.items = [
      {
        label: 'KPI',
        command: (event) => {
          this.activeItem = event.item;
        }
      },
      {
        label: 'Plot',
        command: (event) => {
          this.activeItem = event.item;
        }
      },
    ]
    this.activeItem = this.items[0];
  }



  generateColor(value: any) {
    if (value == '555')
      return '#fff2cc'
    else
      return '#c2ffad'
  }

  populateChartData(data: any) {
    console.log(data)
    data.selected = !data.selected;
    this.calculateChartData.emit(data)
  }


  sendAxisData() {
    console.log(this.xAxis, this.yAxis)
    this.calculateScatterData.emit({ index: this.index++, x_axis: this.xAxis.name, y_axis: this.yAxis.name })
  }

  index = 0
  populateScatterData(data: any) {
    console.log(data, this.xAxis, this.yAxis)
    this.calculateScatterData.emit({ index: this.index++, data: data.key, x_axis: this.xAxis, y_axis: this.yAxis })
  }


  ccmDivColor = ''
  getBackgroundColor(ccmValue: any) {
    console.log(this.ccmColor)
    let redData: any = this.ccmColor.find((x: any) => x.color_id == 1)
    let amberData: any = this.ccmColor.find((x: any) => x.color_id == 2)
    let greenData: any = this.ccmColor.find((x: any) => x.color_id == 3)
    console.log(redData)
    if (ccmValue >= redData.minval && ccmValue <= redData.maxval)
      this.ccmDivColor = 'red'
    else if (ccmValue >= amberData.minval && ccmValue <= amberData.maxval)
      this.ccmDivColor = 'amber'
    else if (ccmValue >= greenData.minval)
      this.ccmDivColor = 'green'
    else
      this.ccmDivColor = 'red'
      console.log("ccmcolor",this.ccmDivColor)
  }

  ccmColor = []
  getScatterData() {
    this._service.getScatterPlotData(this.countryId).subscribe((response: any) => {
      if (!response['isError']) {
        let data = response.response
        console.log(data)
        this.ccmColor = data.table1

        this.getBackgroundColor(Number(this.ccmpervalue))
        this.scatterChartData.emit(data)
      }
    });
  }

  activeItemSelection(data: any) {
    console.log(data)
    if (data.label == 'Plot')
      this.getScatterData()
    this.activeSelection.emit(data)
  }

}
