import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
  selector: 'app-paint-grid',
  templateUrl: './paint-grid.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class PaintGridComponent implements OnInit {
  cols: any;
  usersData: any;
  selectedRow: any;
  formType: any;
  showForm = false;
  country: any = [];
  type: any = [];
  line: any = [];
  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.generateHeaders();
    this.loadData();
    this.loadMasterData();
    this.selectedCols = JSON.parse(JSON.stringify(this.cols));
    this.orgCol = JSON.parse(JSON.stringify(this.cols.filter((x: any) => x.is_show)));
  }

  selectedCols: any[] = [];
  orgCol: any
  onChangeDDValue(_value: any) {
    this.cols = JSON.parse(JSON.stringify(this.dataService.sortSelectedColumns(this.selectedCols)));
  }

  exportExcel() {
    this.dataService.exportExcel(this.usersData,this.cols,this.dataService.trnaslateFunction('EXPORT_PAINT'))
  }

  generateHeaders() {
    this.cols = [
      {
        field: 'id',
        header: 'ID',
        is_show: false,
        //id
        is_editable: true,
      },
      {
        field: 'line_country_id',
        header: 'Country Id',
        is_show: false,
        //country id
        is_editable: true,
      },
      {
        field: 'line_country_name',
        header: 'Country Name',
        is_show: true,
        //country name
        is_editable: true,
      },
      {
        field: 'line_type_id',
        header: 'Type Id',
        is_show: false,
        //type id
        is_editable: true,
      },
      {
        field: 'line_type_name',
        header: 'Type Name',
        is_show: true,
        //type name
        is_editable: true,
      },
      {
        field: 'deal_master_id',
        header: 'Deal Master Id',
        is_show: false,
        //deal master
        is_editable: true,
      },
      {
        field: 'deal_master_name',
        header: 'Paint Line Name',
        is_show: true,
        //paint line
        is_editable: true,
      },
      {
        field: 'cm',
        header: 'CM1%',
        is_show: true,
        //cm
        is_editable: true,
      },
      {
        field: 'gm',
        header: 'GM%',
        is_show: true,
        //gm
        is_editable: true,
      },
      {
        field: 'dist_margin_on_ref_sales',
        header: 'Dist Margin on Ref Sales %',
        is_show: true,
        //margin
        is_editable: true,
      },
      {
        field: 'equipment_reduction_free_toners',
        header: 'Equipment reduction - Free toners',
        is_show: true,
        //reduction
        is_editable: true,
      },
      {
        field: 'created_by',
        header: 'Created By',
        is_show: false,
        //created by
        is_editable: false,
      },
    ];
  }

  loadData() {
    this.spinner.show();
    this.dataService.RetrivePaintLineMarginDetails().subscribe((response) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.usersData = response.response;
      }
    });
  }

  loadMasterData() {
    this.spinner.show();
    this.dataService.RetrievePaintLineMasterData().subscribe((response) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.country = response.response.line_country_collection.map(
          (item: any) => {
            return { id: item.id, value: item.name };
          }
        );
        this.type = response.response.line_type_collection.map((item: any) => {
          return { id: item.id, value: item.name };
        });
        this.line = response.response.line_master.map((item: any) => {
          return { id: item.id, value: item.name };
        });
      }
    });
  }
  onSelectRow(rowObject: { rowData: any }) {
    let { rowData } = rowObject;
    this.selectedRow = JSON.parse(JSON.stringify(rowData));

    this.formType = 'Edit';
    this.showForm = true;
  }

  addNewPaint() {
    this.selectedRow = {};
    this.formType = 'New';
    this.showForm = true;
  }

  refreshPaint(eventvalue: any) {
    if (eventvalue) {
      this.loadData();
    }
    this.showForm = false;
  }
}
