import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-loader',
  templateUrl: './custom-loader.component.html',
  styleUrls: ['./custom-loader.component.css']
})
export class CustomLoaderComponent implements OnInit {

  chartLoading = true
  loadingText: any = []
  ngOnInit(): void {
    let count = 1
    let loadMessage = ['Hold on,Data is being calculated', 'Few more seconds', 'Thanks for waiting', 'Nearly there']
    this.loadingText = loadMessage[0]
    let loadInterval = setInterval(() => {
      this.loadingText = loadMessage[count]
      count++
      console.log(count)
      if (this.loadingText == loadMessage[loadMessage.length - 1])
        clearInterval(loadInterval)
    }, 5000)
  }

}
