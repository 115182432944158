<div class="container side-spacing" style="padding-bottom: 100px;">
    <div class="row" style="align-items: center;padding-bottom: 40px;">
        <label class="col-md-6 header-label" style="padding:0px 20px">{{'DEAL_FORM' | translate}}</label>

    </div>
    <div class="row" style="background-color: white;margin: 0px 10px;">
        <div class="custom-padding" style="padding-top:0px">
            <div class="row" style="align-items: center; padding: 1rem 0rem">

                <div class="col-md-6 mobileButtonClass">
                    <input type="button" value="{{'CREATE_NEW_DEAL' | translate}}" class="btn add-btn"
                        [routerLink]="['/create-deal']" />
                    <button type="button" (click)="exportExcel()" class="btn excelButton">
                        <em class="pi pi-file-excel"></em>{{'EXPORT_AS_EXCEL' | translate}} </button>
                </div>

                <div class="col-md-3 offset-md-3" >
                    <p-multiSelect id="showGridClassDeals" [options]="orgCol" [(ngModel)]="selectedCols" name="colsDD"
                        (onChange)="onChangeDDValue($any($event).value)" optionLabel="header"
                        defaultLabel="{{'SHOW_ITEMS_IN_GRID' | translate}}" [displaySelectedLabel]="false">
                    </p-multiSelect>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <app-dynamic-grid [gridCols]="cols" [gridData]="gridData" [showFilter]="true" [showEdit]="true"
                        (selectRow)="onSelectRow($event)">
                    </app-dynamic-grid>
                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="line-scale"></ngx-spinner>