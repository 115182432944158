<div class="row">
  <div class="col-md-12">
    <form id="userRegistrationForm" [formGroup]="userRegistrationForm">
      <div class="row">
        <div class="col-md-12">
          <div class="row" style="align-items: inherit">
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="basfUserName">{{'BASF_USER_ID' | translate}}
                  <span class="asterikClass">*</span>
                </label>
                <input type="text" class="form-control " formControlName="basfUserId" />
              </div>
              <div *ngIf="
                  userRegistrationForm.get('basfUserId')?.invalid &&
                  userRegistrationForm.get('basfUserId')?.touched
                " class="text-danger">
                <div *ngIf="
                    userRegistrationForm.get('basfUserId')?.hasError('required')
                  ">
                  {{'BASF_ID_REQUIRED' | translate}}
                </div>
              </div>
            </div>
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="email">{{'EMAIL' | translate}} <span
                    class="asterikClass">*</span></label>
                <input type="text" class="form-control " formControlName="emailAddress" />
              </div>
              <div *ngIf="
                  userRegistrationForm.get('emailAddress')?.invalid &&
                  userRegistrationForm.get('emailAddress')?.touched
                " class="text-danger">
                <div *ngIf="
                    userRegistrationForm
                      .get('emailAddress')
                      ?.hasError('required')
                  ">
                  {{'EMAIL_REQUIRED' | translate}}
                </div>
                
                <div *ngIf="
                    userRegistrationForm.get('emailAddress')?.hasError('pattern')
                  ">
                  {{'EMAIL_ID_NOT_VALID' | translate}}
                </div>
              </div>
            </div>
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="firstName">{{'FIRST_NAME' | translate}}
                  <span class="asterikClass">*</span>
                </label>
                <input type="text" class="form-control " formControlName="firstName" />
              </div>
              <div *ngIf="
                  userRegistrationForm.get('firstName')?.invalid &&
                  userRegistrationForm.get('firstName')?.touched
                " class="text-danger">
                <div *ngIf="
                    userRegistrationForm.get('firstName')?.hasError('required')
                  ">
                  {{'FIRST_NAME_REQUIRED' | translate}}
                </div>
                <div *ngIf="
                    userRegistrationForm.get('firstName')?.hasError('pattern')
                  ">
                  {{'FIRST_NAME_NOT_NUMBER' | translate}}
                </div>
              </div>
            </div>
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="lastName">{{'LAST_NAME' | translate}} <span
                    class="asterikClass">*</span></label>
                <input type="text" class="form-control " formControlName="lastName" />
              </div>
              <div *ngIf="
                  userRegistrationForm.get('lastName')?.invalid &&
                  userRegistrationForm.get('lastName')?.touched
                " class="text-danger">
                <div *ngIf="
                    userRegistrationForm.get('lastName')?.hasError('required')
                  ">
                  {{'LAST_NAME_REQUIRED' | translate}}
                </div>
                <div *ngIf="
                    userRegistrationForm.get('lastName')?.hasError('pattern')
                  ">
                  {{'LAST_NAME_NOT_NUMBER' | translate}}
                </div>
              </div>
            </div>
            
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="country">{{'COUNTRY' | translate}} <span
                    class="asterikClass">*</span></label>
                <p-multiSelect [options]="country" formControlName="country" optionLabel="value" class=""
                  id="multiSelect_UserCountry"></p-multiSelect>
              </div>
              <div *ngIf="
                  userRegistrationForm.get('country')?.invalid &&
                  userRegistrationForm.get('country')?.touched
                " class="text-danger">
                <div *ngIf="
                    userRegistrationForm.get('country')?.hasError('required')
                  ">
                  {{'COUNTRY_REQUIRED' | translate}}
                </div>
              </div>
            </div>
            <div class="col-md-6 cust-form-group">
              <div class="form-group">
                <label class="cust-form-label" for="role">{{'SELECT_ROLE' | translate}} <span
                    class="asterikClass">*</span></label>
                <p-multiSelect [options]="role" formControlName="role" optionLabel="value" class=""
                  id="multiSelect_UserRole" [disabled]="roleId<3 && formType!='New'"></p-multiSelect>
              </div>
              <div *ngIf="
                  userRegistrationForm.get('role')?.invalid &&
                  userRegistrationForm.get('role')?.touched
                " class="text-danger">
                <div *ngIf="userRegistrationForm.get('role')?.hasError('required')">
                  {{'ROLE_REQUIRED' | translate}}
                </div>
              </div>
            </div>

            <div class="container-fluid mobileButtonClass paddingClassButtons">
              <div class="row">
                <div class="col-md-12" style="text-align: center;">
                  <button type="button" (click)="cancel()" class="btn cancel-btn"
                    style="background-color: #4f4f4f;">{{'CANCEL' | translate}}</button>
                  <button type="button" class="btn submit-btn" (click)="submitUser()"
                    [disabled]="userRegistrationForm.invalid"
                    (click)="userRegistrationForm.invalid ? toastrAlert() : ''">{{'SUBMIT' |
                    translate}}</button>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

    </form>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="line-scale"></ngx-spinner>