<div class="container header-side-style">
  <nav id="header_component" class="navbar navbar-expand-lg navbar-light bg-light navbar-static-top">

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse header-navbar" id="navbarNav">
      <ul class="navbar-nav  header-navbar-ul">
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse">
          <a [routerLink]="['/home']" class="nav-link" routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: false }">{{'HOME' | translate}} </a>
        </li>
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse"
          [routerLinkActiveOptions]="{ exact: false }" routerLinkActive="active">
          <a [routerLink]="['/display-deal-form']" routerLinkActive="active" class="nav-link">{{'DEAL' | translate}}
          </a>
          <a [routerLink]="['/create-deal']" class="nav-link" routerLinkActive="active" style="display: none;"> </a>
          <a [routerLink]="['/edit-deal']" class="nav-link" routerLinkActive="active" style="display: none;"> </a>
        </li>
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse"
          *ngIf="roleId=='2'||roleId=='3'">
          <a [routerLink]="['/admin']" class="nav-link" routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: false }">{{'ADMIN' | translate}}</a>
        </li>


      </ul>
      <ul class="navbar-nav ms-auto header-navbar-ul">
        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse">
          <a style="font-size: 12px; padding: 8px 0px 8px 0px;margin-right: 0px;" [routerLink]="['/']"
            class="nav-link">{{'LOGOUT' |
            translate}}</a>
        </li>
        <li class="nav-item" style="text-align: center">
          <img src="../../../assets/images/Image-2.png" class="img-responsive logoutIcon" alt="" style="height: 40px" />
          <p style="text-align: center">
          </p>
        </li>

      </ul>
    </div>

    <a class="navbar-brand" href="#">
      <div class="header-logo" style="display: inline-flex;border:1px solid #219fd2">
        <div>
          <img class="header-Logo-Basf" src="../../assets/images/BASFo_wh100tr.png" alt="" loading="lazy" />
        </div>
        <div>
          <div style="display: inline-flex">
            <div class="header-Logo-Dash"></div>
            <img class="header-Logo-Hand" src="../../assets/images/icons/sales.png" alt="" loading="lazy" />
            <p class="header-logo-text">
              Digital Deal
              <br />
              Crafter
            </p>
          </div>
        </div>
      </div>
    </a>

  </nav>
</div>