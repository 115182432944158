import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
  selector: 'app-user-grid',
  templateUrl: './user-grid.component.html'
})
export class UserGridComponent implements OnInit {
  cols: any;
  usersData: any;
  selectedRow: any;
  formType: any;
  showForm = false;
  country: any = [];
  role: any = [];
  basfUserId: any = sessionStorage.getItem('basf_user_id') + ''
  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private authService: AuthServiceService
  ) { }
  @Output() refreshUser = new EventEmitter()
  ngOnInit() {
    this.generateHeaders();
    this.loadData();
    this.loadUserMaster();
    this.selectedCols = JSON.parse(JSON.stringify(this.cols));
    this.orgCol = JSON.parse(JSON.stringify(this.cols.filter((x: any) => x.is_show)));
  }

  selectedCols: any[] = [];
  orgCol: any
  onChangeDDValue(_value: any) {
    this.cols = JSON.parse(JSON.stringify(this.dataService.sortSelectedColumns(this.selectedCols)));
  }

  exportExcel() {
    this.dataService.exportExcel(this.usersData,this.cols, this.dataService.trnaslateFunction('EXPORT_USER'))
  }

  generateHeaders() {
    this.cols = [
      {
        field: 'basf_user_id',
        header: 'BASF User ID',
        is_show: true,
        //basg id
        serial_no: 1,
        is_editable: true,
      },
      {
        field: 'first_name',
        header: 'First Name',
        is_show: true,
        //first anem
        serial_no: 2,
        is_editable: true,
      },
      {
        field: 'last_name',
        header: 'Last Name',
        is_show: true,
        //last name
        serial_no: 3,
        is_editable: true,
      },
      {
        field: 'email',
        header: 'Email',
        is_show: true,
        serial_no: 4,
        //email
        is_editable: true,
      },
      {
        field: 'country_name',
        header: 'Country',
        is_show: true,
        //country
        serial_no: 7,
        is_editable: true,
      },
      {
        field: 'role',
        header: 'Role',
        is_show: false,
        //role
        serial_no: 8,
        is_editable: true,
      },
      {
        field: 'role_name',
        header: 'Role Name',
        is_show: true,
        //role name
        serial_no: 9,
        is_editable: true,
      },
    ];
  }

  loadData() {
    this.spinner.show();
    this.dataService.getAdminList().subscribe((response) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.usersData = response.response;
      }
    });
  }
  loadCountry() {
    this.spinner.show();
    this.dataService.getCountryList().subscribe((response) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.country = response.response.map((item: any) => {
          return { id: item.id, value: item.name };
        });
      }
    });
  }
  loadRole() {
    this.spinner.show();
    this.dataService.getRoleList().subscribe((response) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.role = response.response.map((item: any) => {
          return { id: item.id, value: item.name };
        });
      }
    });
  }
  loadUserMaster() {
    this.spinner.show();
    this.dataService.getUserMaster().subscribe((response: any) => {
      this.spinner.hide();
      if (!response['IsError']) {
        this.role = response.roleMaster.map((item: any) => {
          return { id: item.id, value: item.name };
        });
        this.country = response.countryMaster.map((item: any) => {
          return { id: item.id, value: item.name };
        });
      }
    });
  }
  onSelectRow(rowObject: { rowData: any }) {
    let { rowData } = rowObject;
    this.selectedRow = JSON.parse(JSON.stringify(rowData));

    this.formType = 'Edit';
    this.showForm = true;
  }

  addNewUser() {
    this.selectedRow = {
      basfUserId: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      isAdmin: false,
      country: '',
    };
    this.formType = 'New';
    this.showForm = true;
  }

  refreshUsers(eventvalue: any) {
    if (eventvalue) {
      this.loadData();
      this.dataService.getLoggedinUserData(this.basfUserId).subscribe((result: any) => {

        this.authService.updateLoginUser(result.response);
        this.dataService.sendUserData(result.response);
        this.refreshUser.emit(true)
      })
    }
    this.showForm = false;
  }
}
