import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
  selector: 'app-distributor-margins',
  templateUrl: './distributor-margins.component.html',
  styleUrls: ['./distributor-margins.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DistributorMarginsComponent implements OnInit, OnChanges {

  cols: any = [];
  distributorMarginData: any;
  selectedRow: any;
  formType: any;
  showForm = false;
  distributorMarginForm!: UntypedFormGroup
  @Input() country: any = [];
  @Input() subJobberList: any = [];
  @Input() distributorList: any = [];
  constructor(private fb: UntypedFormBuilder, private dataService: DataService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnChanges(): void {
    this.distributorMarginForm?.reset()
    this.loadGridHeader()
  }

  loadGridHeader(): void {
    this.country?.forEach((element: any, i: number) => {
      this.spinner.show();
      this.dataService.GetDistributorAssumptionsHeaders(element.id, 0).subscribe((response: any) => {
        this.spinner.hide();
        if (!response['isError']) {
          let col = response.response
            .map((item: any, i: number) => {
              return { serial_no: i + 3, is_show: true, field: item.assumptions_variable_name.toLowerCase(), header: item.assumptions_variable_name };
            });

          col.unshift({ is_show: true, field: 'distributor_name', header: 'Distributor', serial_no: 2 })
          col.unshift({ is_show: true, field: 'country_name', header: 'Country', serial_no: 1 })
          this.cols[i] = col

          this.loadData();
        }
      });

    });
  }

  ngOnInit() {
    this.generateForm()
    this.selectedCols = JSON.parse(JSON.stringify(this.cols));
    this.orgCol = JSON.parse(JSON.stringify(this.cols.filter((x: any) => x.is_show)));
  }

  selectedCols: any[] = [];
  orgCol: any
  onChangeDDValue(_value: any) {
    this.cols = JSON.parse(JSON.stringify(this.dataService.sortSelectedColumns(this.selectedCols)));
  }

  exportExcel() {
    this.dataService.exportExcel(this.gridData[0].concat(this.gridData[1]),this.cols,this.dataService.trnaslateFunction('EXPORT_DISTRIBUTOR_MARGIN'))
  }

  generateForm() {
    this.distributorMarginForm = this.fb.group({
      country: new UntypedFormControl('', Validators.required),
      distributor: new UntypedFormControl('', [Validators.required,]),
      margin: this.fb.array([])
    });
  }

  gridData: any = []
  loadData() {
    this.dataService.GetDistributorAssumptionsList().subscribe((response: any) => {
      if (!response['isError']) {
        this.distributorMarginData = response.response.distributorlist
        this.country?.forEach((element: any, i: number) => {
          let gridData = this.distributorMarginData.filter((x: any) => x.country_id == element.id)
          this.gridData[i] = gridData
        })
      }
    });

  }

  loadHeader() {
    this.spinner.show();
    this.dataService.GetDistributorAssumptionsHeaders(this.distributorMarginForm.value.country.id, 0).subscribe((response: any) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.displayHeaders = response.response;
        this.removeFormControl()
        this.addFormControl()
      }
    });
  }

  distributorListCountryWise: any = []
  valueChanged(value: any) {
    if (value?.name == 'country') {
      this.distributorListCountryWise = this.distributorList.filter((x: any) => x.country_id == value.value.id)
      this.distributorMarginForm.get('distributor')?.reset()
      this.selectedRow = []
      this.formType = 'New'
      this.loadHeader()
    }
    if (value?.name == 'distributor') {
      this.checkValueExits()
    }
    if (value?.name == 'country' || value?.name == 'distributor') {
      if (this.distributorMarginForm.value.country?.value != undefined && this.distributorMarginForm.value.distributor?.distributor_name != undefined) {
        this.loadHeader()
      }
    }
  }

  onSelectRow(rowObject: { rowData: any, type: any }) {
    let { rowData } = rowObject;
    this.selectedRow = JSON.parse(JSON.stringify(rowData));
    console.log(this.selectedRow)
    console.log(JSON.stringify(rowData))
    if (rowObject['type'] == 'edit') {

      this.distributorMarginForm.patchValue({
        country: { id: this.selectedRow.country_id, value: this.selectedRow.country_name },
        distributor: { country_id: this.selectedRow.country_id, country_name: this.selectedRow.country_name, distributor_id: this.selectedRow.distributor_id, distributor_name: this.selectedRow.distributor_name },

      })
      this.loadHeader()
      console.log(this.distributorMarginForm.value, this.distributorListCountryWise)
      this.distributorListCountryWise = this.distributorList.filter((x: any) => x.country_id == this.distributorMarginForm.value.country.id)
      this.formType = 'Edit';
      this.showForm = true;
    }
    else if (rowObject['type'] == 'delete') {
      this.deleteDistributororSubjobber()
    }
  }

  deleteDistributororSubjobber() {
    let countryId = this.selectedRow.country_id
    let distributorId = this.selectedRow.distributor_id
    let subJobberId = 0
    this.dataService
      .DeleteDistributororSubjobberMargin(countryId, distributorId, subJobberId)
      .subscribe((response: any) => {
        this.spinner.hide();
        if (!response['isError']) {
          this.toastr.success(this.dataService.trnaslateFunction('DATA_DELETED_SUCCESSFULLY'));
          this.refreshUsers('refresh')
        }
      });
  }

  displayHeaders: any = []
  addFormControl() {
    this.distributorMarginForm.addControl('margin', this.fb.array([]))
    this.displayHeaders.forEach((element: any, i: number) => {
      let value = ''
      if (this.formType == 'Edit' || this.checkValueExits()) {
        let val = element.assumptions_variable_name.toLowerCase()
        if (this.selectedRow != undefined)
          value = this.selectedRow[val]
      }
      this.margin.push(this.createMargin(element.assumptions_variable_id, value));
    });
    console.log(this.distributorMarginForm.value)
  }

  checkValueExits() {
    console.log(this.distributorMarginForm.value.distributor?.distributor_name, 'ckeck exists')
    if (this.distributorMarginForm.value.country?.value != undefined && this.distributorMarginForm.value.distributor?.distributor_name != undefined) {
      const data = this.distributorMarginData?.filter((x: any) => x.distributor_name == this.distributorMarginForm.value.distributor?.distributor_name
        && x.country_name == this.distributorMarginForm.value.country?.value)[0]
      console.log(data)
      this.selectedRow = data
      return data
    }
    return 0
  }

  get margin(): UntypedFormArray {
    return <UntypedFormArray>this.distributorMarginForm.get('margin');
  }

  createMargin(variableId: any, value?: any): UntypedFormGroup {
    return this.fb.group({
      id: [variableId],
      value: [value, [Validators.required, Validators.pattern(/^\d+\.?\d{0,2}$/), Validators.max(100)]]
    })
  }


  removeFormControl() {
    this.distributorMarginForm.removeControl('margin')
  }

  addNewDistributorMargin() {
    this.formType = 'New';
    this.showForm = true;
    this.distributorMarginForm.reset()
    this.removeFormControl()
  }

  refreshUsers(eventvalue: any) {
    if (eventvalue) {
      this.loadData();
    }
    this.showForm = false;
    this.distributorMarginForm.reset()
  }

  submit() {
    console.log(this.distributorMarginForm.value)
    let countryId = this.distributorMarginForm.value.country?.id
    let distributorId = this.distributorMarginForm.value.distributor?.distributor_id
    let distributorList: any = []
    this.distributorMarginForm.value.margin?.forEach((element: any) => {
      let data = {
        "countryId": countryId,
        "distributorId": distributorId,
        "subjobberId": 0,
        "assumptionsVariableId": element.id,
        "value": element.value
      }
      distributorList.push(data)
    });

    console.log(JSON.stringify({ assumptionsValueInput: distributorList }))
    this.dataService
      .InsertOrUpdateDistributorAssumptions({ assumptionsValueInput: distributorList })
      .subscribe((response: any) => {
        this.spinner.hide();
        if (!response['isError']) {
          this.toastr.success(this.dataService.trnaslateFunction('DATA_ADDED_SUCCESSFULLY'));
          this.refreshUsers('refresh')
        }
      });





  }
}
