<div class="card" id="kpiCard" style="height:100%">
    <p-tabMenu [model]="items" [activeItem]="activeItem" (click)="activeItemSelection(activeItem)"></p-tabMenu>
    <div *ngIf="activeItem==items[0]" class="scrollOverflow">
        <div *ngFor="let data of dataList" class="row borderClassDiv" 
         [ngClass]="{ selectedClass :data.selected||data.display, notSelectedClass:!data.selected,
         disabledClass: checkRangeLine(data.value) }"
         (click)="populateChartData(data)">

            <div class="borderClassScatter col-md-6">
                {{data.item }}</div>
            <div class="borderClassScatterData col-md-6">
            <p *ngIf="isNumeric(data.value) && ( data.item =='BASF Net Sales To Distributor' || data.item=='Cumulative MPR @ Refinish' ||data.item =='EBIT Acc $' || data.item =='Present Value Of Future Cash Flows' )">{{data.value|currency:"USD":"symbol":"1.0-0"}} </p> 
            <p *ngIf="isNumeric(data.value) && (data.item =='EBIT Acc % of MPR' || data.item =='Investment as % of MPR' )">{{data.value/100 | percent:'2.0-1'}} </p> 
            <p *ngIf="isNumeric(data.value) && (data.item =='PVI' || data.item =='Discounted Payback' )">{{data.value | number:'1.2-2'}} </p> 
            <p *ngIf="!isNumeric(data.value)">{{data.value  }} </p> 
            </div>

        </div>
    </div>
    <div *ngIf="activeItem==items[1]" class="col-md-12 scrollOverflow" [ngClass]="{red:ccmDivColor=='red',
    amber:ccmDivColor=='amber',
    green:ccmDivColor=='green'}" >
        <div style="border-bottom: 1px solid #f2f2f2;">
            <form #f="ngForm" class="row" style="margin:0px;">
                <div class="col-md-6" style="padding:10px">
                    <label style="font-size: 12px;color:#878787">Select X-axis</label>
                    <p-dropdown [options]="scatterDataListOptions" optionLabel="name" placeholder="X-axis"
                        [(ngModel)]="xAxis" name="xaxis" (onChange)="sendAxisData()" appendTo="body">
                        <ng-template pTemplate="itemx" let-itemx>{{itemx.name| titlecase1}}</ng-template></p-dropdown>

                </div>
                <div class="col-md-6">
                    <label style="font-size: 12px;color:#878787">Select Y-axis</label>
                    <p-dropdown [options]="scatterDataListOptions" optionLabel="name" placeholder="Y-axis"
                        [(ngModel)]="yAxis" name="yaxis" (onChange)="sendAxisData()" appendTo="body">
                        <ng-template pTemplate="itemy" let-itemy>{{itemy.name| titlecase1}}</ng-template>
                    </p-dropdown>
                </div>
            </form>

        </div>
       
        <div *ngFor="let data of dataList" class="row borderClassDiv"
        [ngClass]="{disabledClass: checkRangeLine(data.value) || data.item=='cCM%'}">

        <div class="borderClassScatter col-md-6" *ngIf="data.item!='cCM%'">
            {{data.item | titlecase1}}</div>
        <div class="borderClassScatterData col-md-6" *ngIf="data.item!='cCM%'">
            <!-- <p *ngIf="( data.key=='basf Net Sales To Distributor'  || data.key=='cumulative MPR @ Refinish'  || data.key =='ebit Acc $' || data.key =='present Value Of Future Cash Flows' )"> {{data.value|currency:"USD":"symbol":"1.0-0"}} </p> 
            <p *ngIf="isNumeric(data.value) && (data.key=='ebit Acc % of MPR' || data.key =='investment as % of MPR' )">{{data.value/100 | percent:'2.0-1'}} </p> 
            <p *ngIf="isNumeric(data.value) && (data.key =='pvi' || data.key =='discounted Payback' )">{{data.value | number:'1.2-2'}} </p> 
            <p *ngIf="!isNumeric(data.value)">{{data.value  }} </p>  -->
            <p *ngIf="isNumeric(data.value) && ( data.item =='BASF Net Sales To Distributor' || data.item=='Cumulative MPR @ Refinish' ||data.item =='EBIT Acc $' || data.item =='Present Value Of Future Cash Flows' )">{{data.value|currency:"USD":"symbol":"1.0-0"}} </p> 
            <p *ngIf="isNumeric(data.value) && (data.item =='EBIT Acc % of MPR' || data.item =='Investment as % of MPR' )">{{data.value/100 | percent:'2.0-1'}} </p> 
            <p *ngIf="isNumeric(data.value) && (data.item =='PVI' || data.item =='Discounted Payback' )">{{data.value | number:'1.2-2'}} </p> 
            <p *ngIf="!isNumeric(data.value)">{{data.value  }} </p> 
            </div>
        </div>
    </div>
</div>