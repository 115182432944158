import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TabView } from 'primeng/tabview';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
   selector: 'app-admin',
   templateUrl: './admin.component.html',
   styleUrls: ['./admin.component.css'],
   encapsulation: ViewEncapsulation.None,
})


export class AdminComponent implements AfterViewInit, OnInit {


   @ViewChild("tabView") tabView: TabView | undefined
   constructor(private dataService: DataService, private spinner: NgxSpinnerService, private toastr: ToastrService) {
   }

   ngOnInit(): void {
      this.roleId = atob(sessionStorage.getItem('roleId') + '') || 0
   }

   refreshUser(value: any) {
      if (value)
         this.roleId = atob(sessionStorage.getItem('roleId') + '') || 0
   }

   headers: any
   selelectedHeader: any
   activeIndex = 0
   roleId: any = 0
   ngAfterViewInit(): void {
      if (this.roleId == 2)
         this.headers = [{ name: 'Users', index: 0 }, { name: 'Default Forms', index: 1 },
         { name: 'KPI', index: 2 }]
      else if (this.roleId == 3)
         this.headers = [{ name: 'Users', index: 0 }, { name: 'Default Forms', index: 1 },
         { name: 'KPI', index: 2 }, { name: 'Distributor Registration', index: 3 },
         { name: 'Distributor Margins', index: 4 }, { name: 'Sub-Jobber Margins', index: 5 },
         { name: 'General Assumptions', index: 6 }, { name: 'Paint', index: 7 },{ name: 'Banner Marketing Rebate', index: 8 },
         { name: 'Banner Shop Rebates', index: 9 }, { name: 'Corporate Banners', index: 10 },
         { name: 'One Time Fee', index: 11 }
         ]
      this.selelectedHeader = { name: 'Users', index: 0 }
   }

   listBoxSelection() {
      this.activeIndex = this.selelectedHeader.index
      this.display = false
   }

   display = false
   firstLoad = true
   handleChange(value: any) {
      if (value.index == 1 || value.index == 3 || value.index == 4 || value.index == 5 || value.index == 8 || value.index == 9 || value.index == 10 || value.index == 11) {
         if (this.firstLoad) {
            this.firstLoad = false
            this.loadCountry()
         }
      }
   }

   subJobberList: any
   distributorList: any
   country: any
   loadCountry() {
      this.spinner.show();
      this.dataService.GetAdminDropdownList().subscribe((response: any) => {
         this.spinner.hide();
         if (!response['isError']) {
            this.subJobberList = response.response.subjobberInputList
            this.country = response.response.countryList.map((item: any) => {
               return { id: item.country_id, value: item.country_name };
            });
            this.distributorList = response.response.distributrList
         }
         else
            this.toastr.error(this.dataService.trnaslateFunction('COULD_NOT_LOAD_DATA'))
      });
   }


   refreshs(value: any) {
      if (value)
         this.loadCountry()
   }


}
