import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlContainer, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataServices/data.service';
import { GetFieldName } from '../../common/get-field-name';
import { GetFormValidationErrors } from '../../common/get-form-validation-errors';

@Component({
  selector: 'app-submit-deal',
  templateUrl: './submit-deal.component.html',
  styleUrls: ['./submit-deal.component.css'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ]
})
export class SubmitDealComponent implements OnInit, OnChanges {
  dealRef_id: any;

  constructor(private toastr: ToastrService, public router: Router, private parent: FormGroupDirective, private translate: TranslateService, private _service: DataService, private spinner: NgxSpinnerService) { }

  @Input() editFormId = 0
  @Input() displayChart = false
  @Input() masterDropDownDetails: any = []
  @Input() bannerString: any = {}
  @Output() calculate: EventEmitter<any> = new EventEmitter<any>();
  @Output() kpiData: EventEmitter<any> = new EventEmitter<any>();
  dealCrafterForm!: UntypedFormGroup;
  createdBy = sessionStorage.getItem('id') || '1'
  basfUserId = "testtwe"
  loadingText: any = ''
  chartLoading = false
  ngOnInit(): void {
    this.dealCrafterForm = this.parent.form;
  }

  ngOnChanges(): void {
    if (this.displayChart)
      this.getKpiDeala()
  }

  convertToInteger(value: any) {
    if (value == '' || value == null || value == undefined)
      return 0
    else return parseFloat(value)
  }

  getKpiDeala() {
    this._service.GetKpiDealData(this.editFormId).subscribe((response: any) => {
      if (response.response != undefined) {
        this.calculate.emit(true)
        this.tableData = response.response.table
        this.dealRef_id = response.response?.table[0]?.dealRef_id;
        this.kpiData.emit(response.response)
      }
    })
  }

  getContractYearPayLoad() {
    let contractYearList: any = []
    let flag = 0
    console.log("edit",this.dealCrafterForm.value.general.contractValue,
    this.dealCrafterForm.value.general.contractValue.length)
    if (this.dealCrafterForm.value.general.contractValue.length == 0) {
      console.log("length 0")      
        this.translate.get('FILL_CONTRACT_YEAR').subscribe((res: any) => {
          this.toastr.warning(res)
          flag = 1
      })      
    }
    this.dealCrafterForm.value.general.contractValue.forEach((x: any) => {
      console.log("inside for")
      if (x.value === '') {
        console.log("inside empty check",x.value)
        this.translate.get('FILL_CONTRACT_YEAR').subscribe((res: any) => {
          this.toastr.warning(res)
          flag = 1
        })
      }
      else if (x.value != null && x.value !== '') {
        let contractYear =
        {
          "id": x.id,
          "deal_id": this.editFormId,
          "contract_year_id": this.convertToInteger(this.dealCrafterForm.value.general.contract?.id),
          "contract_year_name": this.dealCrafterForm.value.general.contract?.id,
          "value": x.value
        }
        contractYearList.push(contractYear)
      }
    })
    if (flag == 1)
      return 1
    else
      return contractYearList
  }

  generateMPRYearPayLoad() {
    let mprYearPurchaseFinish: any = []
    let flag = 0
    if (this.dealCrafterForm.value.general.yearValue.length == 0) {
      this.translate.get('FILL_MPR_MONTH_VALUE').subscribe((res: any) => {
        this.toastr.warning(res)
        flag = 1
      }
      )
    }
    this.dealCrafterForm.value.general.yearValue.forEach((x: any) => {
      if (x.value === '') {
        this.translate.get('FILL_MPR_MONTH_VALUE').subscribe((res: any) => {
          this.toastr.warning(res)
          flag = 1
        }
        )
      }
      else if (x.value != null && x.value != '') {
        let mprYearPurchase =
        {
          "id": x.id,
          "deal_id": this.editFormId,
          "deal_master_id": this.convertToInteger(this.dealCrafterForm.value.general.year?.id),
          "deal_master_name": this.dealCrafterForm.value.general.year?.name,
          "value": x.value
        }

        mprYearPurchaseFinish.push(mprYearPurchase)
      }
    })
    if (flag == 1)
      return 1
    else
      return mprYearPurchaseFinish
  }


  generateRebatesPayLoad() {
    let bannerListMapping: any = []
    // corporateRebate
    this.dealCrafterForm.value.banner.bannerCorporateRebate.forEach((x: any) => {
      if (x.id == null)
        x.id = 0
      if (x.amount != null) {
        let bannerListMappingList =
        {
          "id": x.id,
          "deal_id": this.editFormId,
          "deal_master_id": x.value?.id,
          "deal_master_name": x.value?.name,
          "deal_banner_type": this.bannerString.corporateRebates,
          "value": this.convertToInteger(x.amount.rebateValue)
        }

        bannerListMapping.push(bannerListMappingList)
      }
    })

    // shopRebate
    this.dealCrafterForm.value.banner.bannerShopRebate.forEach((x: any) => {
      if (x.id == null)
        x.id = 0
      if (x.amount != null) {
        let bannerListMappingList =
        {
          "id": x.id,
          "deal_id": this.editFormId,
          "deal_master_id": x.value?.id,
          "deal_master_name": x.value?.name,
          "deal_banner_type": this.bannerString.shopRebate,
          "value": this.convertToInteger(x.amount.rebateValue)
        }

        bannerListMapping.push(bannerListMappingList)
      }
    })

    // ccg
    this.dealCrafterForm.value.banner.ccg.forEach((x: any) => {
      if (x.id == null)
        x.id = 0
      if (x.amount != null) {
        let bannerListMappingList =
        {
          "id": x.id,
          "deal_id": this.editFormId,
          "deal_master_id": x.value?.id,
          "deal_master_name": x.value?.name,
          "deal_banner_type": this.bannerString.ccg,
          "value": this.convertToInteger(x.amount.rebateValue)
        }
        bannerListMapping.push(bannerListMappingList)
      }
    })
    console.log(bannerListMapping)
    return bannerListMapping
  }

  generatePayLoad() {
    if (this.dealCrafterForm.value.custInformation.accountStatus?.id == 2) {
      this.dealCrafterForm.controls['existing'].reset()
    }

    let contractYearList: any = []
    contractYearList = this.getContractYearPayLoad()

    console.log(contractYearList)
    let mprYearPurchaseFinish: any = []
    mprYearPurchaseFinish = this.generateMPRYearPayLoad()
    console.log(mprYearPurchaseFinish)

    let bannerListMapping: any = []
    if (this.dealCrafterForm.value.banner.bannerRebates == 'Yes')
      bannerListMapping = this.generateRebatesPayLoad()
    console.log(this.masterDropDownDetails.deal_master_data.filter((x: any) => x.name == this.dealCrafterForm.value.banner.bannerRebates)[0]?.id,
      this.masterDropDownDetails.deal_master_data, this.dealCrafterForm.value.banner.bannerRebates)
    let noOfContractYears = 0
    let paintlineName = ''
    noOfContractYears = this.convertToInteger(this.dealCrafterForm.value.general.contract?.id)
    paintlineName = this.dealCrafterForm.value.general.paint?.name

    if (this.dealCrafterForm.value.custInformation.country?.id == 1) {
      this.dealCrafterForm.get('jobber.sub_jobber')?.reset()
      this.dealCrafterForm.get('rebate.sub_jobber_rebate')?.reset()
    }

    let locationType = 0
    if (this.dealCrafterForm.value.additional.locationType == 'No')
      locationType = 2
    else if (this.dealCrafterForm.value.additional.locationType == 'Yes')
      locationType = 1

    let payLoad = {
      "id": this.editFormId,
      "country_id": this.convertToInteger(this.dealCrafterForm.value.custInformation.country?.id),
      "country_name": this.dealCrafterForm.value.custInformation.country?.name,
      "account_status": this.convertToInteger(this.dealCrafterForm.value.custInformation.accountStatus?.id),
      "account_status_name": this.dealCrafterForm.value.custInformation.accountStatus?.name,
      "customer_information": this.dealCrafterForm.value.custInformation.customerInformation,
      "non_standard_growth": this.dealCrafterForm.value.general.nonStandardGrowth?.name,
      "target_total_mpr": this.convertToInteger(this.masterDropDownDetails.deal_master_data.filter((x: any) => x.name == this.dealCrafterForm.value.additional.targetTotalMpr)[0]?.id),
      "mpr_structure": this.convertToInteger(this.dealCrafterForm.value.additional.mprStructure?.id),
      "mpr_structure_name": this.dealCrafterForm.value.additional.mprStructure?.name,
      "banner_rebates": this.convertToInteger(this.masterDropDownDetails.deal_master_data.filter((x: any) => x.name == this.dealCrafterForm.value.banner.bannerRebates)[0]?.id),
      "banner_name_id": this.convertToInteger(this.dealCrafterForm.value.banner.bannerName?.id),
      "banner_name": this.dealCrafterForm.value.banner.bannerName?.name,
      "banner_marketing_rebate": this.convertToInteger(this.dealCrafterForm.value.banner.bannerMarketingRebate?.id),
      "banner_marketing_rebate_percentage": this.dealCrafterForm.value.banner.bannerMarketingRebate?.name,
      "existing_deal_total_mpr": this.dealCrafterForm.value.existing?.total,
      "existing_deal_actual_mpr_fullfilled": this.dealCrafterForm.value.existing?.actual,
      "existing_deal_effectivedate": this.dealCrafterForm.value.existing?.efdate,
      "existing_deal_fullfilled_throughdate": this.dealCrafterForm.value.existing?.tdate,
      "basf_upfront_invetement": this.dealCrafterForm.value.general.upfront,
      "paintline_to_be_installed": this.convertToInteger(this.dealCrafterForm.value.general.paint?.id),
      "paintline_to_be_installed_name": paintlineName,
      "number_of_contract_years": noOfContractYears,
      "number_of_contract_years_name": this.dealCrafterForm.value.general.contract?.name,
      "equipment_provided_jobber": this.dealCrafterForm.value.jobber.equipment,
      "jobber_id": this.convertToInteger(this.dealCrafterForm.value.jobber.name?.id),
      "jobber_name": this.dealCrafterForm.value.jobber.name?.name,
      "is_basf_variable_rebate_jobber_pos_entered": this.convertToInteger(this.masterDropDownDetails.deal_master_data.filter((x: any) => x.name == this.dealCrafterForm.value.rebate.pos)[0]?.id),
      "jobber_upfront_invetement": this.dealCrafterForm.value.jobber.upfront,
      "distributor_sub_jobber": this.convertToInteger(this.dealCrafterForm.value.jobber.sub_jobber?.distributor_subjobber_id),
      "distributor_sub_jobber_name": this.dealCrafterForm.value.jobber.sub_jobber?.distributor_subjobber_name,
      "jobber_pos_discount": this.dealCrafterForm.value.jobber.discount,
      "fixed_rebate": this.dealCrafterForm.value.rebate.fixed,
      "basf_variable_rebate": this.dealCrafterForm.value.rebate.variable,
      "basf_variable_rebate_to_sub_jobber": this.dealCrafterForm.value.rebate.sub_jobber_rebate || "",
      "contract_year_list": contractYearList,
      "mpr_year_purchase_finish": mprYearPurchaseFinish,
      "banner_list_mapping": bannerListMapping,
      "final_mpr_reduction_adjustment": -1 * Math.abs(this.convertToInteger(this.dealCrafterForm.value.general.mprReduction)),
      "location_type": locationType,
      "previous_unearned_market_investment": this.dealCrafterForm.value.general.previous_unearned_market_investment,
      "equipment_cost_paid_by_basf": this.dealCrafterForm.value.general.equipment_cost_paid_by_basf,
      "total_target_mpr_value": this.convertToInteger(this.dealCrafterForm.value.additional.targetTotalMprValue),
      "basf_ap_sales_as_a_per_of_net_paint_sales": this.convertToInteger(this.dealCrafterForm.value.additional.basfApSales),
      "distributor_prompt_pay_to_subjobber": this.convertToInteger(this.dealCrafterForm.value.jobber.distributorToSubJobber),
      "distributor_prompt_pay_discount_to_shop": this.convertToInteger(this.dealCrafterForm.value.jobber.distributorToShop),
      "original_market_investment": this.convertToInteger(this.dealCrafterForm.value.existing?.marketInvestment),
      "actual_mpr_purchased_at_refinish": this.convertToInteger(this.dealCrafterForm.value.existing?.actualMprPurchased),
      "contractual_mpr_at_refinish_pricing": this.convertToInteger(this.dealCrafterForm.value.existing?.contractualMpr),
      "term_existing": this.convertToInteger(this.dealCrafterForm.value.existing?.teamExisting),
      "actual_mpr_fulfilled": this.convertToInteger(this.dealCrafterForm.value.existing?.actualMprFulfilled),
      "opportunity_Id": this.convertToInteger(this.dealCrafterForm.value.custInformation.opportunity?.opportunity_id),
      "dealref_id": this.dealRef_id,
      "is_active": false,
      "created_by": this.createdBy,

    }

    if (contractYearList == 1 || mprYearPurchaseFinish == 1)
      return 1
    return payLoad
  }

  submit(value: string) {
    console.log(this.dealCrafterForm.value)
    if (this.dealCrafterForm.invalid) {
      this.showValidationErrorMsg();
      return;
    }

    let payLoad = this.generatePayLoad()   
    if (payLoad == 1)
      return
    console.log(JSON.stringify(payLoad))
    if (value == 'calculate') {
      this.calculateApi(payLoad)
    }
    else if (value == 'save') {
      if (!this.calculated) {
        this.toastr.warning(this.trnaslateFunction('CALL_CALCULATE'))
        return
      }
      this.submitApi(payLoad)
    }
    else if(value == 'submit'){
      this.sendtoSalesforce(payLoad);
    }
    console.log("no error")
  }

  submitApi(payLoad: any) {
    this._service.SaveDealFormFieldDetails(payLoad)
      .subscribe((response) => {
        this.spinner.hide();
        if (!response['isError']) {
          this.toastr.success(this._service.trnaslateFunction('DATA_ADDED_SUCCESSFULLY'))
          this.router.navigate(['display-deal-form'])
        }
      });
  }
  
  sendtoSalesforce(payLoad: any) {
    this._service.SendtoSalesforce(payLoad)
      .subscribe((response) => {
        this.spinner.hide();
        if (!response['isError']) {
          this.toastr.success(this._service.trnaslateFunction('DATA_SAVED_TO_SALESFORCE'))
          this.router.navigate(['display-deal-form'])
        }
      });
  }

  calculated = false
  roleId = "1";
  tableData: any = []
  calculateApi(payLoad: any) {
    this.chartLoading = true
    this.calculated = false
    console.log(this.chartLoading, 1)
    this._service.CalculateDeals(payLoad)
      .subscribe((response: any) => {

        this.chartLoading = false
        if (!response['isError']) {
          this.roleId = atob(sessionStorage.getItem('roleId') + '')
          this.calculated = true
          this.toastr.success(this._service.trnaslateFunction('DEALS_CALCULATED'))
          this.calculate.emit(true)
          console.log(response)
          this.tableData = response.response.table
          this.dealRef_id = response.response?.table[0]?.dealRef_id;
          this.kpiData.emit(response.response)
        }
      });
  }

  showValidationErrorMsg() {
    console.log('Invalid form: ', this.dealCrafterForm);
    const error = GetFormValidationErrors(this.dealCrafterForm.controls).shift();
    if (error) {
      let text;
      let fieldNameClass: GetFieldName = new GetFieldName(this.translate)
      let fieldName = fieldNameClass.GetFieldNameMethod(error.control_name)
      console.log("fieldName", fieldName)
      switch (error.error_name) {
        case 'pattern': text = `${fieldName} : ` + this.trnaslateFunction('WRONG_PATTERN'); break;
        case 'email': text = ` ${fieldName} : ` + this.trnaslateFunction('INVALID_EMAIL'); break;
        case 'date-minimum': text = `${fieldName} : ` + this.trnaslateFunction('INVALID_DATE');; break;
        case 'max': text = `${fieldName} : ` + this.trnaslateFunction('MAX_VALUE_EXCEEDED');; break;
        case 'required': text = `${fieldName} : ` + this.trnaslateFunction('REQUIRED_MISSING'); break;
        default: text = `${error.control_name}: ${error.error_name}: ${error.error_value}`;
      }
      this.toastr.warning(text);
    }

  }

  trnaslateFunction(name: string) {
    let text = name
    this.translate.get(name).subscribe(async (res: any) => {
      console.log(res)
      text = res
    })
    return text
  }


}
