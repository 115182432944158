import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-jobber',
  templateUrl: './jobber.component.html',
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class JobberComponent implements OnInit {


  @Input() subJobberOptions: any = []
  @Input() masterDropDownDetails: any
  @Input() editFormId: number = 0
  parentIntakeForm!: UntypedFormGroup;
  constructor(private parent: FormGroupDirective, private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.parentIntakeForm = this.parent.form;
    console.log(this.parentIntakeForm.value)
  }


  loadSubJobberOptions() {
    console.log(this.subJobberOptions)
    if (this.parentIntakeForm.value.general.name == undefined) {
      return []
    }
    return this.subJobberOptions
      .filter((x: any) => x.distributor_name == this.parentIntakeForm.value.general.name?.name && x.country_id == this.parentIntakeForm.value.custInformation.country.id)

  }

  jobberOptions() {
    if (this.parentIntakeForm.value.custInformation.country.id == undefined) {
      return []
    }
    else {
      return this.masterDropDownDetails?.jobber_name_collection.filter((x: any) => x.country_id == this.parentIntakeForm.value.custInformation.country.id)
    }
  }

  valueChanged(value: any, i = 0, formName = '') {
    console.log(value)
  }

  validateFloatKeyPress(event:any) {
    var charCode = event.which ? event.which : event.keyCode;
    var number = event.target.value.split('.');
    var number1 = event.target.value;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    //just one dot
    if (number.length > 1 && charCode == 46) {
      return false;
    }
    //get the carat position
    var caratPos = number1.length;
    console.log(caratPos);
    var dotPos = event.target.value.indexOf('.');
    if (caratPos > dotPos && dotPos > -1 && number[1].length > 0) {
      return false;
    }
    return true;
  }

  warningMessage(message: string) {
    if (message == 'sub-jobber') {
      if (this.parentIntakeForm.value.general.name == undefined) {
        this.translate.get('CHOOSE_JOBBER').subscribe((res: any) => {
          this.toastr.warning(res)
        })
      }

    }

  }

}
