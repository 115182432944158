import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataServices/data.service';

@Component({
  selector: 'app-general-assumptions-registration',
  templateUrl: './general-assumptions-registration.component.html',
  styleUrls: ['./general-assumptions-registration.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class GeneralAssumptionsRegistrationComponent {
  @Input() formData: any;
  @Input() formType: any;
  @Input() data: any;
  @Output() reLoadGrid = new EventEmitter();

  generalAssumptionForm = new UntypedFormGroup({
    country: new UntypedFormControl('', Validators.required),
    type: new UntypedFormControl('', Validators.required),
    generalOverHead: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\d+\.?\d{0,3}$/), Validators.max(100)]),
    freightBasfSales: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\d+\.?\d{0,3}$/), Validators.max(100)]),
    taxRate: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\d+\.?\d{0,3}$/), Validators.max(100)]),
    pvDiscountRate: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\d+\.?\d{0,3}$/), Validators.max(100)]),
    estimatedDso: new UntypedFormControl('', Validators.required),
    estimatedDiv: new UntypedFormControl('', Validators.required),
    costOfCapitalRate: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\d+\.?\d{0,3}$/), Validators.max(100)]),
    hurdleRate: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\d+\.?\d{0,3}$/), Validators.max(100)]),
    annualPriceAssumption: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\d+\.?\d{0,3}$/), Validators.max(100)]),
    marginAssumption: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\d+\.?\d{0,3}$/), Validators.max(100)]),
  });
  generalAssumptionObj = {
    generalOverHead: 0,
    freightBasfSales: 0,
    taxRate: 0,
    pvDiscountRate: 0,
    estimatedDso: 0,
    estimatedDiv: 0,
    costOfCapitalRate: 0,
    hurdleRate: 0,
    annualPriceAssumption: 0,
    marginAssumption: 0,
  };
  apiRequest = {
    id: 0,
    country_id: 0,
    country_name: '',
    type_id: 0,
    type_name: '',
    general_over_head: 0,
    frieght_basf_sales: 0,
    tax_rate: 0,
    pv_discount_rate: 0,
    estimated_dso: 0,
    estimated_div: 0,
    cost_of_capital_rate: 0,
    hurdle_rate: 0,
    annual_price_assumption: 0,
    artap_margin_assumption: 0,
    created_by: '',
  };
  country: any = [];
  type: any = [];
  selectedCountry: any = [];
  selectedType: any = [];

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }
  toastrAlert() {
    this.translate.get('ENTER_MANDATORY').subscribe((res: any) => {
      this.toastr.warning(res)
    })
  }
  ngOnChanges(): void {
    this.country = this.data.country;
    this.type = this.data.type;
    if (this.formType !== 'New') {
      //when form is of new type
      this.selectedCountry = [];
      this.selectedType = [];
      if (this.formData['country_name'] != null) {
        let tBUnits = this.formData['country_name'].split(',');
        tBUnits.forEach((t: string) => {
          let fBUnit = this.country.find((c: any) => c.value == t.trim());
          if (fBUnit) {
            this.selectedCountry = fBUnit.value;
          }
        });
        this.generalAssumptionForm.controls['country'].setValue(
          this.selectedCountry
        );
      }
      if (this.formData['type_name'] != null) {
        let tBUnits = this.formData['type_name'].split(',');
        tBUnits.forEach((t: string) => {
          let fBUnit = this.type.find((c: any) => c.value == t.trim());
          if (fBUnit) {
            this.selectedType = fBUnit.value;
          }
        });
        this.generalAssumptionForm.controls['type'].setValue(this.selectedType);
      }
      this.generalAssumptionObj = {
        generalOverHead: this.formData['general_over_head'],
        freightBasfSales: this.formData['frieght_basf_sales'],
        taxRate: this.formData['tax_rate'],
        pvDiscountRate: this.formData['pv_discount_rate'],
        estimatedDso: this.formData['estimated_dso'],
        estimatedDiv: this.formData['estimated_div'],
        costOfCapitalRate: this.formData['cost_of_capital_rate'],
        hurdleRate: this.formData['hurdle_rate'],
        annualPriceAssumption: this.formData['annual_price_assumption'],
        marginAssumption: this.formData['artap_margin_assumption'],
      };
      this.generalAssumptionForm.controls['generalOverHead'].setValue(
        this.formData['general_over_head'] //value for general_over_head
      );
      this.generalAssumptionForm.controls['freightBasfSales'].setValue(
        this.formData['frieght_basf_sales'] //value for frieght_basf_sales
      );
      this.generalAssumptionForm.controls['taxRate'].setValue(
        this.formData['tax_rate'] //value for tax_rate
      );
      this.generalAssumptionForm.controls['pvDiscountRate'].setValue(
        this.formData['pv_discount_rate'] //value for pv_discount_rate
      );
      this.generalAssumptionForm.controls['estimatedDso'].setValue(
        this.formData['estimated_dso'] //value for estimated_dso
      );
      this.generalAssumptionForm.controls['estimatedDiv'].setValue(
        this.formData['estimated_div'] //value for estimated_div
      );
      this.generalAssumptionForm.controls['costOfCapitalRate'].setValue(
        this.formData['cost_of_capital_rate'] //value for cost_of_capital_rate
      );
      this.generalAssumptionForm.controls['hurdleRate'].setValue(
        this.formData['hurdle_rate'] //value for hurdle_rate
      );
      this.generalAssumptionForm.controls['annualPriceAssumption'].setValue(
        this.formData['annual_price_assumption'] //value for annual_price_assumption
      );
      this.generalAssumptionForm.controls['marginAssumption'].setValue(
        this.formData['artap_margin_assumption'] //value for artap_margin_assumption
      );
    }
  }
  getGeneralAssumptionDetailsOnChange() {
    if (
      this.generalAssumptionForm.value.type == '' ||
      this.generalAssumptionForm.value.country == ''
    )
      return;
    let selectedCountry = this.country.find(
      (c: any) => c.value == this.generalAssumptionForm.value.country.trim()
    );
    let selectedType = this.type.find(
      (c: any) => c.value == this.generalAssumptionForm.value.type.trim()
    );
    let apiRequest = {
      countryId: selectedCountry.id,
      typeId: selectedType.id,
    };
    this.dataService
      .RetriveGeneralAssumptionDetailsByCountry(apiRequest)
      .subscribe((response) => {
        this.spinner.hide();
        if (!response['isError']) {
          if (response.response != null) {
            this.apiRequest['id'] = response.response['id'];
            this.generalAssumptionForm.controls['generalOverHead'].setValue(
              response.response['general_over_head'] //for general_over_head
            );
            this.generalAssumptionForm.controls['freightBasfSales'].setValue(
              response.response['frieght_basf_sales'] //for frieght_basf_sales
            );
            this.generalAssumptionForm.controls['taxRate'].setValue(
              response.response['tax_rate'] //for tax_rate
            );
            this.generalAssumptionForm.controls['pvDiscountRate'].setValue(
              response.response['pv_discount_rate'] //for pv_discount_rate
            );
            this.generalAssumptionForm.controls['estimatedDso'].setValue(
              response.response['estimated_dso'] //for estimated_dso
            );
            this.generalAssumptionForm.controls['estimatedDiv'].setValue(
              response.response['estimated_div'] //for estimated_div
            );
            this.generalAssumptionForm.controls['costOfCapitalRate'].setValue(
              response.response['cost_of_capital_rate'] //for cost_of_capital_rate
            );
            this.generalAssumptionForm.controls['hurdleRate'].setValue(
              response.response['hurdle_rate'] //for hurdle_rate
            );
            this.generalAssumptionForm.controls[
              'annualPriceAssumption'
            ].setValue(response.response['annual_price_assumption']); //for annual_price_assumption
            this.generalAssumptionForm.controls['marginAssumption'].setValue(
              response.response['artap_margin_assumption'] //for artap_margin_assumption
            );
          } else {
            this.generalAssumptionForm.controls['generalOverHead'].setValue(0);
            this.generalAssumptionForm.controls['freightBasfSales'].setValue(0);
            this.generalAssumptionForm.controls['taxRate'].setValue(0);
            this.generalAssumptionForm.controls['pvDiscountRate'].setValue(0);
            this.generalAssumptionForm.controls['estimatedDso'].setValue(0);
            this.generalAssumptionForm.controls['estimatedDiv'].setValue(0);
            this.generalAssumptionForm.controls['costOfCapitalRate'].setValue(
              0
            );
            this.generalAssumptionForm.controls['hurdleRate'].setValue(0);
            this.generalAssumptionForm.controls[
              'annualPriceAssumption'
            ].setValue(0);
            this.generalAssumptionForm.controls['marginAssumption'].setValue(0);
          }
        }
      });
  }
  submitForm() {
    if (this.generalAssumptionForm.invalid) {
      this.translate.get('FILL_ALL_FIELDS').subscribe((res: any) => {
        this.toastr.warning(res)
        return false;
      })

    }

    this.spinner.show();

    if (this.formType === 'New') {
      this.apiRequest['created_by'] = sessionStorage.getItem('id') || '1';
    } else {
      this.apiRequest['created_by'] = sessionStorage.getItem('id') || '1';
      this.apiRequest['id'] = Number(this.formData.id);
    }
    this.apiRequest['country_id'] = this.data.country.filter((item: any) => {
      return item.value == this.generalAssumptionForm.value.country;
    })[0]?.id;
    this.apiRequest['country_name'] = this.data.country.filter((item: any) => {
      return item.value == this.generalAssumptionForm.value.country;
    })[0]?.value;
    this.apiRequest['type_id'] = this.data.type.filter((item: any) => {
      return item.value == this.generalAssumptionForm.value.type;
    })[0]?.id;

    this.apiRequest['type_name'] = this.data.type.filter((item: any) => {
      return item.value == this.generalAssumptionForm.value.type;
    })[0]?.value;
    this.apiRequest['general_over_head'] = Number(
      this.generalAssumptionForm.value.generalOverHead
    );
    this.apiRequest['frieght_basf_sales'] = Number(
      this.generalAssumptionForm.value.freightBasfSales
    );
    this.apiRequest['tax_rate'] = Number(
      this.generalAssumptionForm.value.taxRate
    );
    this.apiRequest['pv_discount_rate'] = Number(
      this.generalAssumptionForm.value.pvDiscountRate
    );
    this.apiRequest['estimated_dso'] = Number(
      this.generalAssumptionForm.value.estimatedDso
    );
    this.apiRequest['estimated_div'] = Number(
      this.generalAssumptionForm.value.estimatedDiv
    );
    this.apiRequest['cost_of_capital_rate'] = Number(
      this.generalAssumptionForm.value.costOfCapitalRate
    );
    this.apiRequest['hurdle_rate'] = Number(
      this.generalAssumptionForm.value.hurdleRate
    );
    this.apiRequest['annual_price_assumption'] = Number(
      this.generalAssumptionForm.value.annualPriceAssumption
    );
    this.apiRequest['artap_margin_assumption'] = Number(
      this.generalAssumptionForm.value.marginAssumption
    );
    this.dataService
      .GeneralAssumptionDetails(this.apiRequest)
      .subscribe((response: any) => {
        this.spinner.hide();
        if (!response['isError']) {
          this.toastr.success(this.dataService.trnaslateFunction('DATA_ADDED_SUCCESSFULLY'));
          this.reLoadGrid.emit(true);
        }
      });
    return true;
  }

  cancel() {
    this.reLoadGrid.emit(false);
  }
}
