import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-display-calculation-grid',
  templateUrl: './display-calculation-grid.component.html'
})
export class DisplayCalculationGridComponent implements OnInit {

  @Input() tableData: any = []
  cols: any = []

  ngOnInit(): void {
    console.log(this.tableData)
    this.generateColHeader()
  }

  generateColHeader() {
    let data = this.tableData[0]
    let keysOfData:any = Object.keys(data)
    let col: any = []
    keysOfData.forEach((x: any, i: number) => {
      col.push({ is_show: true, field: keysOfData[i], header: keysOfData[i], serial_no: i })
    })
    this.cols = col
  }

}
