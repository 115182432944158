<div formGroupName="additional">    
    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'LOCATION_TYPE' | translate}}
                <span *ngIf="parentIntakeForm.get('additional.locationType')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="locationType" width="100%" fieldType="switch"
                    radioName="locationType" radioValue1="{{'NEW' | translate}}"
                    radioValue2="{{'EXISTING' | translate}}" (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    
    <div style="visibility: hidden;display: none;">
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'TARGET_TOTAL_MRP' | translate}}
                <span *ngIf="parentIntakeForm.get('additional.targetTotalMpr')|hasRequiredField"
                    class="hashcol">*</span>
            </div>
            <div class="col-md-4 ">
                <app-dynamic-form-fields formControlName="targetTotalMpr" width="100%" fieldType="switch"
                    radioName="targetTotalMpr" radioValue1="{{'YES' | translate}}" radioValue2="{{'NO' | translate}}"
                    (blur)="valueChanged($event)">
                </app-dynamic-form-fields>

            </div>



        </div>
        <div class="divider"></div>
    </div>
    <div *ngIf="parentIntakeForm.get('additional.targetTotalMpr')?.value =='Yes'">
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'TARGET_TOTAL_MRP_VALUE' | translate}}
                <span *ngIf="parentIntakeForm.get('additional.targetTotalMprValue')|hasRequiredField"
                    class="hashcol">*</span>
            </div>
            <div class="col-md-4 ">

                <app-dynamic-form-fields formControlName="targetTotalMprValue" width="100%" startAddOn="$"
                    fieldType="inputTextGroup" placeholder="{{'ENTER_TARGET_TOTAL_MPR_VALUE' | translate}}"
                    (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>


   

<div *ngIf="parentIntakeForm.value.general?.name?.name =='ART'" >
<div class="row paddingClass">
    <div class="col-md-5 widthClass">{{'BASFAP_SALES' | translate}}
        <span *ngIf="parentIntakeForm.get('additional.basfApSales')|hasRequiredField"
            class="hashcol">*</span>
    </div>
    <div class="col-md-4 ">
        <app-dynamic-form-fields formControlName="basfApSales" width="100%" startAddOn="$"
            fieldType="inputTextGroup" placeholder="{{'ENTER_BASFAP_SALES' | translate}}"
            (blur)="valueChanged($event)">
        </app-dynamic-form-fields>
    </div>
</div>
<div class="divider"></div>
</div>

    <div>
        <div class="row paddingClass">
            <div class="col-md-5 widthClass">{{'MRP_STRUCTURE' | translate}} 
                <span *ngIf="parentIntakeForm.get('additional.mprStructure')|hasRequiredField" class="hashcol">*</span>
            </div>
            <div class="col-md-4 widthClassRemaining">
                <app-dynamic-form-fields formControlName="mprStructure" width="100%" fieldType="dropdown"
                    placeholder="{{'SELECT_MRP' | translate}}" optionLabel="name"
                    [options]="masterDropDownDetails?.mpr_structure_collection" (blur)="valueChanged($event)">
                </app-dynamic-form-fields>
            </div>
        </div>
        <div class="divider"></div>
    </div>




</div>
