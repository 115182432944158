import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { CHARTDATA } from './form-data';
import { DataService } from 'src/app/services/dataServices/data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { BannerValidator } from '../common/banner-validator';
import { AdditionalComponent } from './display-deal-form/additional/additional.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-deal-form',
  templateUrl: './deal-form.component.html',
  styleUrls: ['./deal-form.component.css'],
})
export class DealFormComponent implements OnInit, OnDestroy {

  @ViewChild(AdditionalComponent) additionalComponent: any
  dealCrafterForm!: UntypedFormGroup;
  chartData: any
  createdBy = sessionStorage.getItem("id");;
  basfUserId = sessionStorage.getItem("basf_user_id")
  usserId=Number(sessionStorage.getItem("id"));
  formId = 0
  calculate = false
  kpiData: any = []
  bannerString = { corporateRebates: 'Corporate Banner Rebates', shopRebate: 'Banner Shop Rebate', ccg: 'Banner CCG set-up/annual fees' }

  constructor(private fb: UntypedFormBuilder, private translate: TranslateService, private _service: DataService, private spinner: NgxSpinnerService, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.editFormId = parseInt(atob(this.route.snapshot.paramMap.get('id') + '')) || 0;
    if (this.editFormId <= 0)
      this.editFormId = 0
    this.chartData = JSON.parse(JSON.stringify(CHARTDATA))
    this.getCountryDetails()
    this.getOpportunity()
    this.getFormId()
    this.dealCrafterForm = this.fb.group({})
    this.generateFormControls()
    this.loadData()
    this.setValidators()
  }

  tabValue = 'KPI'
  kpiTabSelection(data: any) {
    this.tabValue = data.label
  }

  generateKPIData(value: any) {
    this.kpiData = value
    let chartValues = value.table1
    let key = Object.keys(this.kpiData?.table1[0])
    console.log(this.kpiData.table1.length)
    this.chartData.labels = new Array(this.kpiData.table1.length).fill(null).map((_, i) => i + 1)
    this.chartData.datasets.forEach((x: any, i: number) => {
      x.label = key[i].charAt(0).toUpperCase() + key[i].slice(1)
      x.data = chartValues.map((y: any) => { return y[key[i]] })
    });
    console.log(this.chartData, chartValues)
    this.chartData = { ...this.chartData }
  }

  showChart(value: any) {
    console.log(value)
    this.calculate = true
  }

  getFormId() {
    this._service.RetrieveFormDetails().subscribe((response: any) => {
      if (!response['isError']) {
        this.formId = response.response.filter((x: any) => x.name == 'Deal')[0].id
        this.getDealMasterDetails()
      }
    });
  }

  subJobberOptions: any = []
  loadData() {
    this.spinner.show();
    this._service.GetDistributorDetails().subscribe((response: any) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.subJobberOptions = response.response
      }
    });
  }

  setRebateValue(data: any) {
    // corporateRebate
    let bannerCorporateRebateList = this.dealCrafterForm.get('banner.bannerCorporateRebate') as UntypedFormArray
    bannerCorporateRebateList.clear()
    while (bannerCorporateRebateList.length < 4) {
      bannerCorporateRebateList.push(this.createValue())
    }

    let bannerShopRebateList = this.dealCrafterForm.get('banner.bannerShopRebate') as UntypedFormArray
    bannerShopRebateList.clear()
    while (bannerShopRebateList.length < 3) {
      bannerShopRebateList.push(this.createValue())
    }

    let ccgList = this.dealCrafterForm.get('banner.ccg') as UntypedFormArray
    ccgList.clear()
    while (ccgList.length < 3) {
      ccgList.push(this.createValue())
    }

    let indexCo: number = 0
    let indexSh: number = 0
    let indexCcg: number = 0
    data.banner_list_mapping.forEach((x: any, i: number) => {
      if (x.deal_banner_type == this.bannerString.corporateRebates) {
        this.getRebateValue(x.deal_master_id, indexCo, bannerCorporateRebateList, x, i, data.banner_list_mapping.length - 1)
        indexCo++
      }
      else if (x.deal_banner_type == this.bannerString.shopRebate) {
        this.getRebateValue(x.deal_master_id, indexSh, bannerShopRebateList, x, i, data.banner_list_mapping.length - 1)
        indexSh++
      }
      else if (x.deal_banner_type == this.bannerString.ccg) {
        this.getRebateValue(x.deal_master_id, indexCcg, ccgList, x, i, data.banner_list_mapping.length - 1)
        indexCcg++
      }
    })
  }


  editFormId: number = 0
  displayChart = false
  setFormControls(data: any) {
    this.editFormId = data.id
    this.setRebateValue(data)
    this.dealCrafterForm.patchValue(
      {
        custInformation: {
          opportunity: this.opportunity?.filter((x: any) => x.opportunity_id == data.opportunity_Id)[0],
          country: { id: data.country_id, name: data.country_name, country_id: data.country_id },
          accountStatus: this.masterDropDownDetails.deal_active_status.filter((x: any) => x.id == data.account_status)[0],
          customerInformation: data.customer_information,
          refId: data.dealref
        },
        additional: {
          targetTotalMpr: this.masterDropDownDetails.deal_master_data.filter((x: any) => x.id == data.target_total_mpr)[0]?.name,
          mprStructure: this.masterDropDownDetails.mpr_structure_collection.filter((x: any) => x.id == data.mpr_structure)[0],
          locationType: data.location_type == 1 ? 'Yes' : 'No',
          targetTotalMprValue: data.total_target_mpr_value,

          basfApSales: data.basf_ap_sales_as_a_per_of_net_paint_sales,
        },
        banner: {
          bannerRebates: this.masterDropDownDetails.deal_master_data.filter((x: any) => x.id == data.banner_rebates)[0]?.name,
          bannerName: { id: data.banner_name_id, name: data.banner_name },
          bannerMarketingRebate: this.masterDropDownDetails.banner_percentage_collection.filter((x: any) => x.id == data.banner_marketing_rebate)[0],
        },
        existing: {
          total: data.existing_deal_total_mpr,
          actual: data.existing_deal_actual_mpr_fullfilled,
          efdate: formatDate(data.existing_deal_effectivedate, 'yyyy-MM-dd', 'en-US'),
          tdate: formatDate(data.existing_deal_fullfilled_throughdate, 'yyyy-MM-dd', 'en-US'),
          marketInvestment: data.original_market_investment,
          actualMprPurchased: data.actual_mpr_purchased_at_refinish,
          contractualMpr: data.contractual_mpr_at_refinish_pricing,
          teamExisting: data.term_existing,
          actualMprFulfilled: data.actual_mpr_fulfilled,
        },
        general: {
          nonStandardGrowth: this.masterDropDownDetails.deal_master_data.filter((x: any) => x.name == data.non_standard_growth)[0],
          contract: this.masterDropDownDetails.contract_years_collection.filter((x: any) => x.name == data.number_of_contract_years_name)[0],
          upfront: data.basf_upfront_invetement,
          paint: this.masterDropDownDetails.paint_line_collection.filter((x: any) => x.id == data.paintline_to_be_installed)[0],
          year: this.masterDropDownDetails.deal_mpr_purchase_refinish_data.filter((x: any) => x.name == data.mpr_year_purchase_finish[0]?.deal_master_name)[0],
          equipment_cost_paid_by_basf: data.equipment_cost_paid_by_basf,
          mprReduction: data.final_mpr_reduction_adjustment,
          previous_unearned_market_investment: data.previous_unearned_market_investment
        },
        jobber: {          
          name: this.masterDropDownDetails.jobber_name_collection.filter((x: any) => x.id == data.jobber_id)[0],
          equipment: data.equipment_provided_jobber,
          upfront: data.jobber_upfront_invetement,
          sub_jobber: this.subJobberOptions.filter((x: any) => x.distributor_subjobber_id == data.distributor_sub_jobber && x.country_id == data.country_id)[0],
          discount: this.convertToInteger(data.jobber_pos_discount),
          distributorToShop: data.distributor_prompt_pay_discount_to_shop,
          distributorToSubJobber: data.distributor_prompt_pay_to_subjobber,
        },
        rebate: {
          fixed: data.fixed_rebate,
          variable: this.convertToInteger(data.basf_variable_rebate),
          pos: this.masterDropDownDetails.deal_master_data.filter((x: any) => x.id == data.is_basf_variable_rebate_jobber_pos_entered)[0]?.name,
          sub_jobber_rebate: this.convertToInteger(data.basf_variable_rebate_to_sub_jobber),
        }
      }
    )
    if (data.banner_list_mapping.length == 0)
      this.displayChart = true
  }

  country: any
  getCountryDetails() {
    this.spinner.show();
    this._service.RetrieveCountryDetailsById(this.basfUserId).subscribe((response: any) => {
      this.spinner.hide();
      if (!response['isError']) {
        let country: any = response.response
        let countryBasedOnRole: any = JSON.parse(sessionStorage.getItem('country') + '')
        this.country = country?.filter((obj: any) => countryBasedOnRole?.includes(obj.country_id))
        if (!this.editFormId) {
          this.dealCrafterForm.patchValue({
            custInformation: {
              country: this.country[0]
            }
          })
        }
      }
    });
  }

  opportunity: any
  getOpportunity() {
    this.spinner.show();

    this._service.RetrieveOpportunity(this.usserId).subscribe((response: any) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.opportunity = response.response

      }
    });
  }

  convertToInteger(value: any) {
    if (value == '' || value == null || value == undefined)
      return 0
    else return parseFloat(value)
  }

  defaultValue: any = []
  contractValueChanged = 0
  yearValueChanged = 0
  getDealDefaultValue() {
    if (!this.editFormId) {
      if (this.defaultValue?.length == 0) {
        this.spinner.show();
        this._service.RetrieveDealDefaultValue(this.formId).subscribe((response: any) => {
          this.spinner.hide();
          if (!response['isError']) {
            this.defaultValue = JSON.parse(JSON.stringify(response.response))
            this.setDefaultFormValue()
          }
        });
      }
      else {

        this.setDefaultFormValue()
      }
    }
  }

  countryChange(value: any) {
    console.log("country value change", value)
    if (!this.editFormId && value) {
      console.log("response country", this.dealCrafterForm.value)
      this.setDefaultFormValue()
    }
  }

  setDefaultFormValue() {
    let response: any = JSON.parse(JSON.stringify(this.defaultValue))
    console.log(response)
    response = response?.filter((x: any) => (x.countryId == this.dealCrafterForm.value.custInformation.country?.id || x.countryId == 0))
    console.log(response)
    if (this.dealCrafterForm.value.custInformation.country?.id == 1) {//US
      this.translate.get('US_MPR_STRUCTURE').subscribe((res: any) => {
        this.dealCrafterForm.patchValue({
          'additional': {
            'mprStructure': this.masterDropDownDetails?.mpr_structure_collection?.
              filter((x: any) => x.name.toLowerCase() == res.toLowerCase())[0]
          },
        })
      })
    }
    else if (this.dealCrafterForm.value.custInformation.country?.id == 2) {//CA
      this.translate.get('CA_MPR_STRUCTURE').subscribe((res: any) => {
        console.log(res, "translate")
        this.dealCrafterForm.patchValue({
          'additional': {
            'mprStructure': this.masterDropDownDetails.mpr_structure_collection?.
              filter((x: any) => x.name.toLowerCase() == res.toLowerCase())[0]

          }
        })
      })
    }

   
this.formpatchvalue(response);
   
    console.log(this.dealCrafterForm.value)

  }
  
  formpatchvalue(response: any){
    let nonStandardGrowth: any = response.filter((x: any) => x.fieldName == "Non-Standard Growth")[0]?.value
    let noContractYears: any = 1
    if (nonStandardGrowth == 1) {
      noContractYears = '1'
    }
    else {
      noContractYears = response.filter((x: any) => x.fieldName == "Number of Contract Years")[0]?.value
    }
    let bannerRebates: any = response.filter((x: any) => x.fieldName == "Banner Rebates")[0]?.value
    let mrpYear: any = response.filter((x: any) => x.fieldName == "Historical Purchases")[0]?.value
    let jobberName: any = this.defaultValue?.filter((x: any) => x.fieldName == "Jobber Name" && x.countryId == this.dealCrafterForm.value.custInformation.country?.id)[0]?.value
    let pos: any = response.filter((x: any) => x.fieldName == "Is BASF Variable rebate included in Jobber POS entered?")[0]?.value
    let bannerMarketingRebates: any = this.defaultValue.filter((x: any) => x.fieldName == "Banner Marketing Rebate" && x.countryId == this.dealCrafterForm.value.custInformation.country?.id)[0]?.value
    let distributorSub: any = this.defaultValue.filter((x: any) => x.fieldName == "Distributor Sub-Jobber")[0]?.value
    this.contractValueChanged = noContractYears
    this.yearValueChanged = mrpYear
    this.dealCrafterForm.patchValue({
      custInformation:
      {
        accountStatus: { id: 2, name: "Conversion", country_id: 0 }
      },
      additional: {
       basfApSales: 0,
        locationType: response.filter((x: any) => x.fieldName == "Location Type")[0]?.value == 1 ? "Yes" : "No",
        targetTotalMpr: response.filter((x: any) => x.fieldName == "Target Total MPR?")[0]?.value == 1 ? "Yes" : "No",
      },
      banner: {
        bannerRebates: this.masterDropDownDetails?.deal_master_data.filter((x: any) => x.id == bannerRebates)[0]?.name,
        bannerMarketingRebate: this.masterDropDownDetails?.banner_percentage_collection.filter((x: any) => x.id == bannerMarketingRebates)[0],
      },
      jobber: {
        name: this.masterDropDownDetails?.jobber_name_collection.filter((x: any) => x.id == jobberName)[0],
        equipment: response.filter((x: any) => x.fieldName == "Equipment provided (at Jobber)")[0]?.value || '',
        upfront: response.filter((x: any) => x.fieldName == "Jobber Upfront Investment")[0]?.value || '',
        sub_jobber: this.subJobberOptions.filter((x: any) => x.distributor_subjobber_id == distributorSub &&
          distributorSub != 0 && x.distributor_name == this.dealCrafterForm.value.general.name?.name
          && x.country_id == this.dealCrafterForm.value.custInformation.country.id)[0],

        discount: this.convertToInteger(response.filter((x: any) => x.fieldName == "Jobber POS Discount (off Refinish)")[0]?.value || ''),
        distributorToShop: response.filter((x: any) => x.fieldName == "Distributor Prompt Pay Discount To Shop")[0]?.value || '',
        distributorToSubJobber: response.filter((x: any) => x.fieldName == "Distributor Prompt Pay To SubJobber")[0]?.value || '',
      },
      rebate: {
        fixed: response.filter((x: any) => x.fieldName == "Fixed Rebate (Annual Amount)")[0]?.value || '',
        variable: response.filter((x: any) => x.fieldName == "BASF Variable Rebate")[0]?.value || '',
        pos: this.masterDropDownDetails?.deal_master_data?.filter((x: any) => x.id == pos)[0]?.name,
        sub_jobber_rebate: this.convertToInteger(response.filter((x: any) => x.fieldName == "BASF Variable Rebate to Sub-Jobber (CA only)\r\n")[0]?.value || ''),
      }
      
    })
    this.generalsectionpatch(response);
    this.existingpatch(response);
  }
  generalsectionpatch(response: any){

    let nonStandardGrowth: any = response.filter((x: any) => x.fieldName == "Non-Standard Growth")[0]?.value
    let noContractYears: any = 1
    if (nonStandardGrowth == 1) {
      noContractYears = '1'
    }
    else {
      noContractYears = response.filter((x: any) => x.fieldName == "Number of Contract Years")[0]?.value
    }
    let paintLine: any = response.filter((x: any) => x.fieldName == "Paint Line to be installed")[0]?.value
    let mrpYear: any = response.filter((x: any) => x.fieldName == "Historical Purchases")[0]?.value
   
    this.dealCrafterForm.patchValue({
    
    general: {
      nonStandardGrowth: this.masterDropDownDetails?.deal_master_data?.filter((x: any) => x.id == nonStandardGrowth)[0],
      contract: this.masterDropDownDetails?.contract_years_collection.filter((x: any) => x.name == noContractYears)[0],
      upfront: response.filter((x: any) => x.fieldName == "BASF Upfront Investment")[0]?.value || '',
      paint: this.masterDropDownDetails?.paint_line_collection.filter((x: any) => x.id == paintLine)[0],
      year: this.masterDropDownDetails?.deal_mpr_purchase_refinish_data.filter((x: any) => x.name == mrpYear)[0],
      equipment_cost_paid_by_basf: response.filter((x: any) => x.fieldName == "Equipment Cost Paid By BASF")[0]?.value || '',
      mprReduction: response.filter((x: any) => x.fieldName == "MPR Reduction Adjustment")[0]?.value || '',
      previous_unearned_market_investment: response.filter((x: any) => x.fieldName == "Previously Unearned Market Investment")[0]?.value || '',
    }
  })
  }
  existingpatch(response:any){
    this.dealCrafterForm.patchValue({
        existing:
    {
      actual: response.filter((x: any) => x.fieldName == "Existing Deal Actual MPR fulfilled")[0]?.value || '',
      total: response.filter((x: any) => x.fieldName == "Existing Deal Total MPR fulfilled")[0]?.value || '',
      marketInvestment: response.filter((x: any) => x.fieldName == "BASF Market Investment (Existing)")[0]?.value || '',
      actualMprPurchased: response.filter((x: any) => x.fieldName == "Actual MPR Purchased at Refinish")[0]?.value || '',
      contractualMpr: response.filter((x: any) => x.fieldName == "Contractual MPR at Refinish Pricing")[0]?.value || '',
      teamExisting: response.filter((x: any) => x.fieldName == "Term Existing")[0]?.value || '',
      actualMprFulfilled: response.filter((x: any) => x.fieldName == "Existing Deal Actual MPR fulfilled")[0]?.value || '',
    }
  })
  }
  masterDropDownDetails: any
  getDealMasterDetails() {
    this.spinner.show();
    this._service.RetriveMasterDealDetils().subscribe((response: any) => {
      this.spinner.hide();
      if (!response['isError']) {
        this.masterDropDownDetails = response.response
        if (this.editFormId > 0) {
          this.masterDropDownDetails.deal_active_status.filter((x: any) => x.id == 1)[0].disabled = true

          this._service.RetrieveDealByDealId(this.editFormId).subscribe((res: any) => {
            if (res.response.id != undefined) {
              this._service.sendDealFormData(res.response)
              this.setFormControls(res.response)
            }
          })

        }
        else {
          this.getDealDefaultValue()
        }
      }
    });
  }

  createValue(value?: any, amount?: any, id = 0, options = [], rebateID = 0): UntypedFormGroup {
    return this.fb.group({
      value: [value],
      amount: [{ rebateID: rebateID, rebateValue: amount }],
      id: [id],
      options: [options]
    })
  }

  getRebateValue(id: number, i: number, formArrayControl: any, x: any, editLast = -1, sizeOfArray = -2) {
    this._service.GetRebateValues(id).subscribe((response: any) => {
      this.spinner.show()
      if (!response['isError']) {
        this.spinner.hide()
        let data: any = {}
        let rebates: any = []
        response.response.forEach((x: any) => {
          data = {
            rebateID: x.rebateID,
            'rebateValue': x.rebateValue.toString()
          }
          rebates.push(data)
        })
        let rebateID = rebates.filter((ele: any) => ele.rebateValue == x.value)[0]?.rebateID || 0
        formArrayControl.controls[i]?.get('options')?.setValue(rebates)
        formArrayControl.controls[i]?.get('id')?.setValue(x.id)
        formArrayControl.controls[i]?.get('amount')?.setValue({ rebateID: rebateID, rebateValue: x.value.toString() })
        formArrayControl.controls[i]?.get('value')?.setValue({ id: x.deal_master_id, name: x.deal_master_name, country_id: this.dealCrafterForm.value.custInformation.country.id })
      }
      if (editLast == sizeOfArray)
        this.displayChart = true
    });
  }

  createFormArray(size: number) {
    return Array.from({ length: size }, () => (this.createValue()))
  }

  generateFormControls() {
    this.dealCrafterForm = this.fb.group({
      custInformation: this.fb.group({
        opportunity: new UntypedFormControl('', [Validators.required,]),
        country: new UntypedFormControl('', [Validators.required,]),
        accountStatus: new UntypedFormControl('', [Validators.required,]),
        customerInformation: new UntypedFormControl('', [Validators.required,]),
        refId: new UntypedFormControl('',),
      }),
      existing: this.fb.group({
        total: new UntypedFormControl('', []),
        actual: new UntypedFormControl('', []),
        efdate: new UntypedFormControl('', []),
        tdate: new UntypedFormControl('', []),
        marketInvestment: new UntypedFormControl('', []),
        actualMprPurchased: new UntypedFormControl('', []),
        contractualMpr: new UntypedFormControl('', []),
        teamExisting: new UntypedFormControl('', []),
        actualMprFulfilled: new UntypedFormControl('', []),
      }),
      general: this.fb.group({
        nonStandardGrowth: new UntypedFormControl('', [Validators.required]),
        contract: new UntypedFormControl('', []),
        contractValue: this.fb.array([]),
        upfront: new UntypedFormControl('', [Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)]),
        paint: new UntypedFormControl('', [Validators.required]),
        year: new UntypedFormControl('', [Validators.required]),
        yearValue: this.fb.array([]),
        mprReduction: new UntypedFormControl('', [Validators.required]),
        equipment_cost_paid_by_basf: new UntypedFormControl('', [Validators.required]),
        previous_unearned_market_investment: new UntypedFormControl('', [Validators.required])
      }),
      jobber: this.fb.group({        
        name: new UntypedFormControl('', [Validators.required]),
        equipment: new UntypedFormControl('', [Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)]),
        upfront: new UntypedFormControl('', [Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)]),
        sub_jobber: new UntypedFormControl('', []),
        discount: new UntypedFormControl('', [Validators.required]),
        distributorToShop: new UntypedFormControl('', [Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)]),
        distributorToSubJobber: new UntypedFormControl('', [Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)]),

      }),
      rebate: this.fb.group({
        fixed: new UntypedFormControl('', [Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)]),
        variable: new UntypedFormControl('', [Validators.required]),
        pos: new UntypedFormControl('', [Validators.required]),
        sub_jobber_rebate: new UntypedFormControl('', []),
      }),
      banner: this.fb.group({
        bannerRebates: new UntypedFormControl('', [Validators.required]),
        bannerCorporateRebate: this.fb.array(this.createFormArray(4)),
        bannerShopRebate: this.fb.array(this.createFormArray(3)),
        bannerMarketingRebate: new UntypedFormControl(''),
        ccg: this.fb.array(this.createFormArray(3)),
      },
        {
          validators: Validators.compose([
            BannerValidator.validFieldMatch('bannerCorporateRebate', 'bannerShopRebate', 'ccg', 'bannerRebates', 'bannerMarketingRebate'),
          ]),
        }),
      additional: this.fb.group({
        
        targetTotalMpr: new UntypedFormControl('', [Validators.required]),
        targetTotalMprValue: new UntypedFormControl('',),
        basfApSales: new UntypedFormControl('', [Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)]),
        mprStructure: new UntypedFormControl('', [Validators.required]),
        locationType: new UntypedFormControl('', [Validators.required]),
      })
    })
    console.log(this.dealCrafterForm.value)
  }

  updateValidators() {
    this.dealCrafterForm.get('general.nonStandardGrowth')?.updateValueAndValidity()
    this.dealCrafterForm.get('additional.targetTotalMpr')?.updateValueAndValidity()
    this.dealCrafterForm.get('additional.mprStructure')?.updateValueAndValidity()
    this.dealCrafterForm.get('additional.locationType')?.updateValueAndValidity()
    this.dealCrafterForm.get('additional.targetTotalMprValue')?.updateValueAndValidity()
    this.dealCrafterForm.get('general.contract')?.updateValueAndValidity()
    this.dealCrafterForm.get('jobber.distributorToShop')?.updateValueAndValidity()
    this.dealCrafterForm.get('jobber.distributorToSubJobber')?.updateValueAndValidity()
    this.dealCrafterForm.get('additional.basfApSales')?.updateValueAndValidity()
    this.dealCrafterForm.get('banner.bannerRebates')?.updateValueAndValidity()
    this.dealCrafterForm.get('existing.total')?.updateValueAndValidity()
    this.dealCrafterForm.get('existing.actual')?.updateValueAndValidity()
    this.dealCrafterForm.get('existing.efdate')?.updateValueAndValidity()
    this.dealCrafterForm.get('existing.tdate')?.updateValueAndValidity()
    this.dealCrafterForm.get('general.upfront')?.updateValueAndValidity()
    this.dealCrafterForm.get('general.paint')?.updateValueAndValidity()
    this.dealCrafterForm.get('general.mprReduction')?.updateValueAndValidity()
    this.dealCrafterForm.get('general.year')?.updateValueAndValidity()
    this.dealCrafterForm.get('general.equipment_cost_paid_by_basf')?.updateValueAndValidity()
    this.dealCrafterForm.get('general.previous_unearned_market_investment')?.updateValueAndValidity()
    this.dealCrafterForm.get('jobber.equipment')?.updateValueAndValidity()
    this.dealCrafterForm.get('jobber.name')?.updateValueAndValidity()
    this.dealCrafterForm.get('jobber.upfront')?.updateValueAndValidity()
    this.dealCrafterForm.get('jobber.discount')?.updateValueAndValidity()
    this.dealCrafterForm.get('rebate.fixed')?.updateValueAndValidity()
    this.dealCrafterForm.get('rebate.variable')?.updateValueAndValidity()
    this.dealCrafterForm.get('rebate.pos')?.updateValueAndValidity()
  }

  removeValidators() {
    this.dealCrafterForm.get('general.nonStandardGrowth')?.clearValidators()
    this.dealCrafterForm.get('additional.targetTotalMpr')?.clearValidators()
    this.dealCrafterForm.get('additional.mprStructure')?.clearValidators()
    this.dealCrafterForm.get('general.contract')?.clearValidators()
    this.dealCrafterForm.get('additional.targetTotalMprValue')?.clearValidators()
    this.dealCrafterForm.get('jobber.distributorToShop')?.clearValidators()
    this.dealCrafterForm.get('jobber.distributorToSubJobber')?.clearValidators()
    this.dealCrafterForm.get('additional.basfApSales')?.clearValidators()
    this.dealCrafterForm.get('additional.locationType')?.clearValidators()
    this.dealCrafterForm.get('banner.bannerRebates')?.clearValidators()
    this.dealCrafterForm.get('existing.total')?.clearValidators()
    this.dealCrafterForm.get('existing.actual')?.clearValidators()
    this.dealCrafterForm.get('existing.efdate')?.clearValidators()
    this.dealCrafterForm.get('existing.tdate')?.clearValidators()
    this.dealCrafterForm.get('general.upfront')?.clearValidators()
    this.dealCrafterForm.get('general.paint')?.clearValidators()
    this.dealCrafterForm.get('general.mprReduction')?.clearValidators()
    this.dealCrafterForm.get('general.equipment_cost_paid_by_basf')?.clearValidators()
    this.dealCrafterForm.get('general.previous_unearned_market_investment')?.clearValidators()
    this.dealCrafterForm.get('general.year')?.clearValidators()
    this.dealCrafterForm.get('jobber.equipment')?.clearValidators()
    this.dealCrafterForm.get('jobber.name')?.clearValidators()
    this.dealCrafterForm.get('jobber.upfront')?.clearValidators()
    this.dealCrafterForm.get('jobber.discount')?.clearValidators()
    this.dealCrafterForm.get('rebate.fixed')?.clearValidators()
    this.dealCrafterForm.get('rebate.variable')?.clearValidators()
    this.dealCrafterForm.get('rebate.pos')?.clearValidators()
    this.updateValidators()
  }

  addValidators() {
    this.dealCrafterForm.get('general.nonStandardGrowth')?.setValidators([Validators.required])
    this.dealCrafterForm.get('additional.targetTotalMpr')?.setValidators([Validators.required])
    this.dealCrafterForm.get('additional.mprStructure')?.setValidators([Validators.required])
    this.dealCrafterForm.get('additional.locationType')?.setValidators([Validators.required])
    this.dealCrafterForm.get('general.contract')?.setValidators([Validators.required]);
    this.dealCrafterForm.get('jobber.distributorToShop')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)])
    this.dealCrafterForm.get('jobber.distributorToSubJobber')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)])
    this.dealCrafterForm.get('additional.basfApSales')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)])
    this.dealCrafterForm.get('banner.bannerRebates')?.setValidators([Validators.required])
    this.dealCrafterForm.get('existing.total')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)])
    this.dealCrafterForm.get('existing.actual')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)])
    this.dealCrafterForm.get('existing.efdate')?.setValidators([Validators.required])
    this.dealCrafterForm.get('existing.tdate')?.setValidators([Validators.required])
    this.dealCrafterForm.get('general.upfront')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)])
    this.dealCrafterForm.get('general.mprReduction')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)])
    this.dealCrafterForm.get('general.equipment_cost_paid_by_basf')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)])
    this.dealCrafterForm.get('general.previous_unearned_market_investment')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)])
    this.dealCrafterForm.get('general.paint')?.setValidators([Validators.required])
    this.dealCrafterForm.get('general.year')?.setValidators([Validators.required])
    this.dealCrafterForm.get('jobber.equipment')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)])
    this.dealCrafterForm.get('jobber.name')?.setValidators([Validators.required])
    this.dealCrafterForm.get('jobber.upfront')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)])
    this.dealCrafterForm.get('jobber.discount')?.setValidators([Validators.required])
    this.dealCrafterForm.get('rebate.fixed')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)])
    this.dealCrafterForm.get('rebate.variable')?.setValidators([Validators.required])
    this.dealCrafterForm.get('rebate.pos')?.setValidators([Validators.required])
    this.updateValidators()
  }

  setValidators() {
    this.dealCrafterForm.get('custInformation.country')?.valueChanges.subscribe(response => {
      if (response && this.dealCrafterForm.value.custInformation.accountStatus?.id == 2) {
        if (response.id != 2) {
          this.dealCrafterForm.get('rebate.sub_jobber_rebate')?.clearValidators();
        }
        else {
          this.dealCrafterForm.get('rebate.sub_jobber_rebate')?.setValidators([Validators.required]);
        }
      }
      this.dealCrafterForm.get('jobber.sub_jobber')?.updateValueAndValidity();
      this.dealCrafterForm.get('rebate.sub_jobber_rebate')?.updateValueAndValidity();
    })


    this.dealCrafterForm.get('additional.targetTotalMpr')?.valueChanges.subscribe(response => {
      console.log(response, "total target ")
      if (response && this.dealCrafterForm.value.custInformation.accountStatus?.id == 2) {
        if (response == 'Yes') {
          this.dealCrafterForm.get('additional.targetTotalMprValue')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)]);
        }
        else {
          this.dealCrafterForm.get('additional.targetTotalMprValue')?.clearValidators();
        }
      }
      this.dealCrafterForm.get('additional.targetTotalMprValue')?.updateValueAndValidity();
    })

    this.dealCrafterForm.get('custInformation.accountStatus')?.valueChanges.subscribe(response => {
      if (response) {
        if (response.id == 1) {
          this.removeValidators()
          this.updateValidators()
          this.dealCrafterForm.get('custInformation.refId')?.setValidators([Validators.required]);
          this.dealCrafterForm.get('existing.actual')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)]);
          this.dealCrafterForm.get('existing.efdate')?.setValidators([Validators.required]);
          this.dealCrafterForm.get('existing.tdate')?.setValidators([Validators.required]);
          this.dealCrafterForm.get('existing.total')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)]);
          this.dealCrafterForm.get('existing.marketInvestment')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)]);
          this.dealCrafterForm.get('existing.actualMprPurchased')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)]);
          this.dealCrafterForm.get('existing.contractualMpr')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)]);
          this.dealCrafterForm.get('existing.teamExisting')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)]);
          this.dealCrafterForm.get('existing.actualMprFulfilled')?.setValidators([Validators.required, Validators.pattern(/^-?\d+\.?\d{0,2}$/)]);

        }
        else {
          this.addValidators()
          this.updateValidators()
          this.dealCrafterForm.get('custInformation.refId')?.clearValidators();
          this.dealCrafterForm.get('existing.actual')?.clearValidators();
          this.dealCrafterForm.get('existing.efdate')?.clearValidators();
          this.dealCrafterForm.get('existing.tdate')?.clearValidators();
          this.dealCrafterForm.get('existing.total')?.clearValidators();
          this.dealCrafterForm.get('existing.marketInvestment')?.clearValidators()
          this.dealCrafterForm.get('existing.actualMprPurchased')?.clearValidators()
          this.dealCrafterForm.get('existing.contractualMpr')?.clearValidators()
          this.dealCrafterForm.get('existing.teamExisting')?.clearValidators()
          this.dealCrafterForm.get('existing.actualMprFulfilled')?.clearValidators()

        }
      }
      this.dealCrafterForm.get('custInformation.refId')?.updateValueAndValidity()
      this.dealCrafterForm.get('existing.actual')?.updateValueAndValidity();
      this.dealCrafterForm.get('existing.efdate')?.updateValueAndValidity();
      this.dealCrafterForm.get('existing.tdate')?.updateValueAndValidity();
      this.dealCrafterForm.get('existing.total')?.updateValueAndValidity();
      this.dealCrafterForm.get('existing.marketInvestment')?.updateValueAndValidity()
      this.dealCrafterForm.get('existing.actualMprPurchased')?.updateValueAndValidity()
      this.dealCrafterForm.get('existing.contractualMpr')?.updateValueAndValidity()
      this.dealCrafterForm.get('existing.teamExisting')?.updateValueAndValidity()
      this.dealCrafterForm.get('existing.actualMprFulfilled')?.updateValueAndValidity()
    })

  }


  scatterChartDataGraph(value: any) {
    console.log(value)
    console.log(this.kpiData)
    console.log(this.chartData)
    let data = value.table
    console.log(data)
    data.push(this.kpiData?.table1[0])
    console.log(data)
    this.scatterChartData = value.table
    this.ccmi = value.table1
  }

  scatterChartData: any = []
  ccmi: any = []
  xAxisData = 'ebit Acc $'
  yAxisData = 'basf Net Sales To Distributor'
  calculateScatterData(value: any) {
    console.log(value)
    this.xAxisData = value.x_axis
    this.yAxisData = value.y_axis
  }

  calculateChartData(value: any) {
    console.log(value, this.chartData)
    this.chartData.datasets.forEach((x: any) => {
      if (x.label.toLowerCase() == value.item.toLowerCase()) {
        x.display = !x.display
      }
    });
    this.chartData = { ...this.chartData }
  }

  ngOnDestroy(): void {
    this._service.sendDealFormData({})
  }

}
