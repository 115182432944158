import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminComponent } from './components/admin/admin.component';
import { DealFormComponent } from './components/deal-form/deal-form.component';
import { DisplayDealGridComponent } from './components/display-deal-grid/display-deal-grid.component';

import { HomeComponent } from './components/home/home.component';
import { LoginTestComponent } from './components/login-test/login-test.component';
import { LoginComponent } from './components/login/login.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthGaurd } from './_helpers/auth.guard';

const routes: Routes = [
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '', pathMatch: 'full', component: LoginComponent },
  { path: 'login', pathMatch: 'full', component: LoginComponent, data: { expectedurl: 'login' }, },
  // {
  //  path: 'login-test',
  //  pathMatch: 'full',
  //  component: LoginTestComponent,
  //  data: { expectedurl: 'login-test' },
  // },  
  {
    path: 'home',
    pathMatch: 'full',
    component: HomeComponent,
    canActivate: [AuthGaurd],
    data: { expectedurl: 'home' },
  },

  {
    path: 'admin',
    pathMatch: 'full',
    component: AdminComponent,
    canActivate: [AuthGaurd],
    data: { expectedurl: 'admin' },
  },

  {
    path: 'create-deal',
    pathMatch: 'full',
    component: DealFormComponent,
    canActivate: [AuthGaurd],
    data: { expectedurl: 'create-deal' },
  },
  {
    path: 'edit-deal/:id',
    pathMatch: 'full',
    component: DealFormComponent,
    canActivate: [AuthGaurd],
    data: { expectedurl: 'edit-deal' },
  },
  {
    path: 'display-deal-form',
    pathMatch: 'full',
    component: DisplayDealGridComponent,
    canActivate: [AuthGaurd],
    data: { expectedurl: 'display-deal-form' },
  },

  {
    path: 'emaillink/:url',
    component: HomeComponent,
    canActivate: [AuthGaurd],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
